import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/illustrations/empty_metrics.svg'


const _hoisted_1 = { class: "node-detail-metrics full-width d-flex" }
const _hoisted_2 = { class: "ml-auto" }
const _hoisted_3 = { class: "ml-n3" }
const _hoisted_4 = { class: "ml-auto" }
const _hoisted_5 = { class: "ml-n3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_DropdownMenu = _resolveComponent("DropdownMenu")!
  const _component_CustomTextInput = _resolveComponent("CustomTextInput")!
  const _component_WidgetMonitoringWrapper = _resolveComponent("WidgetMonitoringWrapper")!
  const _component_GridItem = _resolveComponent("GridItem")!
  const _component_GridLayout = _resolveComponent("GridLayout")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.initialLoaded)
      ? (_openBlock(), _createBlock(_component_Spinner, {
          key: 0,
          class: "ma-auto"
        }))
      : (!_ctx.layout.length)
        ? (_openBlock(), _createBlock(_component_v_col, {
            key: 1,
            class: "pa-0 my-auto"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { class: "ma-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_img, {
                    src: _imports_0,
                    height: "80px",
                    width: "107px",
                    class: "mx-auto mb-4"
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, { class: "ma-0 token-text-h5 token-text-color-primary mb-2 mx-auto fit-content" }, {
                default: _withCtx(() => _cache[13] || (_cache[13] = [
                  _createTextVNode(" No metrics to show ")
                ])),
                _: 1
              }),
              _createVNode(_component_v_row, { class: "ma-0 token-text-regular token-text-color-secondary mx-auto fit-content" }, {
                default: _withCtx(() => _cache[14] || (_cache[14] = [
                  _createTextVNode(" Deploy your project to start collecting metrics ")
                ])),
                _: 1
              })
            ]),
            _: 1
          }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            (_ctx.fullscreen)
              ? (_openBlock(), _createBlock(_component_Modal, {
                  key: 0,
                  visible: _ctx.fullscreen,
                  "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.fullscreen) = $event)),
                  pseudoFullScreen: true,
                  transparentExternal: true
                }, {
                  header: _withCtx(() => [
                    _createVNode(_component_v_row, { class: "ma-2" }, {
                      default: _withCtx(() => [
                        _cache[15] || (_cache[15] = _createElementVNode("div", { class: "token-text-medium my-2" }, " Metrics ", -1)),
                        _createElementVNode("div", _hoisted_2, [
                          _createVNode(_component_CustomButton, {
                            ref: "menuButton",
                            level: _ctx.$enums.ButtonLevels.Tertiary,
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dropdownOpened = true)),
                            class: "px-1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.userSelectedPeriod.text), 1),
                              _createVNode(_component_Icon, {
                                class: "ml-1 mr-n4",
                                color: "#3219B2",
                                icon: "caret-down"
                              })
                            ]),
                            _: 1
                          }, 8, ["level"]),
                          _createVNode(_component_DropdownMenu, {
                            items: _ctx.itemsDropdown,
                            show: _ctx.dropdownOpened,
                            "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dropdownOpened) = $event)),
                            modelValue: _ctx.userSelectedPeriod,
                            maxContent: true,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.onUserSelecPeriod($event))),
                            target: _ctx.$refs.menuButton
                          }, null, 8, ["items", "show", "modelValue", "target"])
                        ]),
                        _createVNode(_component_CustomButton, {
                          level: _ctx.$enums.ButtonLevels.Tertiary,
                          icon: "arrow-expand",
                          class: "my-auto ml-2 px-2",
                          onClick: _ctx.toggleFullscreen
                        }, null, 8, ["level", "onClick"])
                      ]),
                      _: 1
                    }),
                    (_ctx.userSelectedPeriod.id === 'custom')
                      ? (_openBlock(), _createBlock(_component_v_row, {
                          key: 0,
                          class: "ma-2 mb-4 fit-content ml-auto"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_CustomTextInput, {
                              modelValue: _ctx.startPicker,
                              "onUpdate:modelValue": [
                                _cache[3] || (_cache[3] = ($event: any) => ((_ctx.startPicker) = $event)),
                                _ctx.onDatePickerChange
                              ],
                              type: "datetime-local",
                              style: {"width":"200px"}
                            }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                            _createVNode(_component_Icon, {
                              icon: "arrow-right",
                              class: "mx-2"
                            }),
                            _createVNode(_component_CustomTextInput, {
                              modelValue: _ctx.endPicker,
                              "onUpdate:modelValue": [
                                _cache[4] || (_cache[4] = ($event: any) => ((_ctx.endPicker) = $event)),
                                _ctx.onDatePickerChange
                              ],
                              type: "datetime-local",
                              style: {"width":"200px"}
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_row, { class: "ma-0 border-bot" })
                  ]),
                  default: _withCtx(() => [
                    (_ctx.fullscreen)
                      ? (_openBlock(), _createBlock(_component_v_row, {
                          key: 0,
                          class: _normalizeClass(["ma-0 metrics-container", {'custom-show' : _ctx.userSelectedPeriod.id === 'custom'}])
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { class: "pa-0" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_GridLayout, {
                                  class: "grid-layout",
                                  layout: _ctx.layoutFullscreen,
                                  "onUpdate:layout": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.layoutFullscreen) = $event)),
                                  colNum: 24,
                                  rowHeight: 8,
                                  margin: [32,32],
                                  isDraggable: false,
                                  isResizable: false,
                                  verticalCompact: true,
                                  useCssTransforms: true
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layoutFullscreen, (item, index) => {
                                      return (_openBlock(), _createBlock(_component_GridItem, {
                                        key: index,
                                        static: item.static,
                                        x: item.x,
                                        y: item.y,
                                        w: item.w,
                                        h: item.h,
                                        i: item.i
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_WidgetMonitoringWrapper, {
                                            widgetAPI: _ctx.widgetsAPI[index],
                                            class: "widget-wrapper",
                                            customStart: _ctx.start,
                                            customEnd: _ctx.end,
                                            period: _ctx.userSelectedPeriod
                                          }, null, 8, ["widgetAPI", "customStart", "customEnd", "period"])
                                        ]),
                                        _: 2
                                      }, 1032, ["static", "x", "y", "w", "h", "i"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["layout"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["class"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["visible"]))
              : (_openBlock(), _createBlock(_component_v_col, {
                  key: 1,
                  class: "pa-0"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, { class: "ma-2" }, {
                      default: _withCtx(() => [
                        _cache[16] || (_cache[16] = _createElementVNode("div", { class: "token-text-medium my-2" }, " Metrics ", -1)),
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_CustomButton, {
                            ref: "menuButton",
                            level: _ctx.$enums.ButtonLevels.Tertiary,
                            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.dropdownOpened = true)),
                            class: "px-1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.userSelectedPeriod.text), 1),
                              _createVNode(_component_Icon, {
                                class: "ml-1 mr-n4",
                                color: "#3219B2",
                                icon: "caret-down"
                              })
                            ]),
                            _: 1
                          }, 8, ["level"]),
                          _createVNode(_component_DropdownMenu, {
                            items: _ctx.itemsDropdown,
                            show: _ctx.dropdownOpened,
                            "onUpdate:show": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.dropdownOpened) = $event)),
                            modelValue: _ctx.userSelectedPeriod,
                            maxContent: true,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.onUserSelecPeriod($event))),
                            target: _ctx.$refs.menuButton
                          }, null, 8, ["items", "show", "modelValue", "target"])
                        ]),
                        _createVNode(_component_CustomButton, {
                          level: _ctx.$enums.ButtonLevels.Tertiary,
                          icon: "arrow-expand",
                          class: "my-auto ml-2 px-2",
                          onClick: _ctx.toggleFullscreen
                        }, null, 8, ["level", "onClick"])
                      ]),
                      _: 1
                    }),
                    (_ctx.userSelectedPeriod.id === 'custom')
                      ? (_openBlock(), _createBlock(_component_v_row, {
                          key: 0,
                          class: "ma-2 mb-4"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_CustomTextInput, {
                              modelValue: _ctx.startPicker,
                              "onUpdate:modelValue": [
                                _cache[10] || (_cache[10] = ($event: any) => ((_ctx.startPicker) = $event)),
                                _ctx.onDatePickerChange
                              ],
                              type: "datetime-local",
                              style: {"width":"200px"}
                            }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                            _createVNode(_component_Icon, { icon: "arrow-right" }),
                            _createVNode(_component_CustomTextInput, {
                              modelValue: _ctx.endPicker,
                              "onUpdate:modelValue": [
                                _cache[11] || (_cache[11] = ($event: any) => ((_ctx.endPicker) = $event)),
                                _ctx.onDatePickerChange
                              ],
                              type: "datetime-local",
                              style: {"width":"200px"}
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_row, { class: "ma-0 border-bot" }),
                    (!_ctx.fullscreen)
                      ? (_openBlock(), _createBlock(_component_v_row, {
                          key: 1,
                          class: _normalizeClass(["ma-0 metrics-container", {'custom-show' : _ctx.userSelectedPeriod.id === 'custom'}])
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { class: "pa-0" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_GridLayout, {
                                  class: "grid-layout",
                                  layout: _ctx.layout,
                                  "onUpdate:layout": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.layout) = $event)),
                                  colNum: 24,
                                  rowHeight: 32,
                                  margin: [8,8],
                                  isDraggable: false,
                                  isResizable: false,
                                  verticalCompact: true,
                                  useCssTransforms: true
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layout, (item, index) => {
                                      return (_openBlock(), _createBlock(_component_GridItem, {
                                        key: index,
                                        static: item.static,
                                        x: item.x,
                                        y: item.y,
                                        w: item.w,
                                        h: item.h,
                                        i: item.i
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_WidgetMonitoringWrapper, {
                                            widgetAPI: _ctx.widgetsAPI[index],
                                            class: "widget-wrapper",
                                            customStart: _ctx.start,
                                            customEnd: _ctx.end,
                                            period: _ctx.userSelectedPeriod
                                          }, null, 8, ["widgetAPI", "customStart", "customEnd", "period"])
                                        ]),
                                        _: 2
                                      }, 1032, ["static", "x", "y", "w", "h", "i"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["layout"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["class"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
          ], 64))
  ]))
}