<template>
  <v-col class="flat-gauge pa-0">
    <v-row class="gauge-area ma-0" :class="{pulsating : pulsating}" :style="(height ? 'height : '+ height + 'px; ' : '')">
      <template v-for="(value, index) of values" :key="'gauge-segment-'+index">
        <v-col class="pa-0 gauge-segment"  v-if="index !== 0" :style="'max-width:' + sizes[index] + '%; min-width:' + sizes[index] + '%;' + 'background-color:' + (computedColors ? computedColors[index - 1] : 'black')">
        </v-col>
      </template>
    </v-row>
  </v-col>

</template>


<script lang="ts">
import { Vue, prop } from 'vue-class-component';

class Props {
  values: number[]  = prop({
    required: true,
  });
  colors?: string[]  = prop({
    required: false,
  });
  height?: string  = prop({
    required: false,
  });
  pulsating?: boolean  = prop({
    required: false,
  });
}

export default class FlatGauge extends Vue.with(Props) {

  flatGaugeColors = ['rgba(50, 25, 178, 1)', 'rgba(157, 145, 218, 1)', 'rgba(206, 200, 237, 1)']

  get computedColors() {
    return this.colors ? this.colors : this.flatGaugeColors
  }

  //{0-100}[]
  get sizes():number[] {
    const min = this.values[0]
    const trimValues = this.values.map((value:number) => {
      if(isNaN(value)) {
        return min
      }
      return value
    })

    const max = Math.max(...trimValues)
    const range = max - min
    const ret:number[] = []
    let lastVal = min

    trimValues.forEach((value:number, index:number) => {
      if(value > max) {
        return max
      }
      const diff = value - lastVal
      ret.push((diff/range) * 100)
      lastVal = value
    })
    return ret
  }

}
</script>


<style lang="scss" scoped>
@import '@/css/variables';
.flat-gauge {
  .gauge-area {
    border-radius: 8px;
    overflow: hidden;
    height: 8px;
    &.pulsating {
      box-shadow: 0 0 0 0 rgba(50, 25, 178, 1);
      animation: l1 2s infinite;
    }
    .gauge-segment {
      transition: min-width 0.2s ease-in-out, background-color 0.2s ease-in-out;
    }
    @keyframes l1 {
        100% {box-shadow: 0 0 0 4px rgba(50, 25, 178, 0)}
    }
  }
}


</style>
