<template>
  <div :class="'custom-button ' + (level ? level : 'primary') + ' ' + (computedDisabled ? 'disabled' : '') + ' ' + (hasText ? 'btn-text' : '') + ' ' + (loading ? 'loading' : '') + ' ' + (status ? 'state-'+status : '')" >
    <v-row class="my-auto mx-0">
      <!-- <template v-if="loading">
        <Spinner/>
      </template> -->
      <!-- <template v-else> -->
        <div v-if="computedIcon" class="pa-0 d-flex col-icon " :class="{'mx-auto' : !hasText, 'pl-4 ml-auto' : hasText}" >
          <Icon :icon="computedIcon"/>
        </div>
        <div v-if="hasText" class="custom-button-text pa-0 pr-4 d-flex col-text" :class="{'pl-2 mr-auto' : computedIcon, 'pl-4 mx-auto' : !computedIcon}">
          <v-row v-if="text" class="mx-auto">
            <span class="my-auto token-text-button">{{text}}</span>
          </v-row>
          <v-row class="ma-0 my-auto token-text-button" v-else>
              <slot></slot>
          </v-row>
        </div>
      <!-- </template> -->
    </v-row>
  </div>
</template>


<script lang="ts">
import { Vue, prop, Options } from 'vue-class-component';
import Icon from '@/components/UIElements/Icon.vue'
import Spinner from '@/components/UIElements/Spinner.vue'
import { ButtonLevels } from '@/enums'

class Props {
  level?: typeof ButtonLevels = prop({
    required: false,
  });
  text?: string = prop({
    required: false,
  });
  icon?: string = prop({
    required: false,
  });
  disabled?: boolean = prop({
    required: false,
  });
  loading?: boolean = prop({
    required: false,
  });
  status?: string = prop({
    required: false,
  });
}

@Options({
  components: {
    Icon,
    Spinner
  },
})
export default class CustomButton extends Vue.with(Props) {

  get computedIcon() {
    let ret = this.icon ? this.icon : ''
    switch(this.status) {
      case this.$enums.ButtonStates.Success:
        ret = 'check-circle'
        break;
      case this.$enums.ButtonStates.Danger:
        ret = 'cancel-circle'
        break;
      case this.$enums.ButtonStates.Warning:
        ret = 'warning'
        break;
      default:
        break;
    }
    if( this.loading ) {
      ret = 'spinner'
    }
    return ret
  }

  get hasText() {
    return this.text || this.$slots.default
  }
  get computedDisabled() {
    return this.disabled || this.loading || Object.values(this.$enums.ButtonStates).includes(this.status)
  }
}



</script>

<style lang="scss">
@import '@/css/variables';

.custom-button {
  width: fit-content;
  height: 38px;
  z-index: 1;
  transition: background 0.2s ease-in-out, width 0.2s ease-in-out, color 0.2s ease-in-out;
  .col-icon {
    
  }
  &.btn-text {
    font-size: 14px;
    line-height: 16px;
    font-style: normal;
    height: 38px;
  }
  cursor: pointer;
  display: flex;
  &.disabled {
    box-shadow: none !important;
    color: $color-neutral-grey-30 !important;
    cursor:auto !important;
    pointer-events: none;
    .icon {
      background:$color-neutral-grey-30 !important;
      mask-size:16px;
    }
  }
  &.primary {
    border-radius: 8px;
    background: $color-background-button-text-primary-normal;
    box-shadow: $shadow-button-text-primary-normal;
    font-family: Inter, sans-serif;
    line-height: 16px;
    font-weight: 400;
    border: 1px solid $color-neutral-grey-12;
    color: white;
    .icon {
      background:white;
      mask-size:16px;
    }
    &:hover {
      background: $color-background-button-text-primary-hover;
      box-shadow: $shadow-button-text-primary-hover;
    }
    &:active {
      background: $color-background-button-text-primary-pressed;
      box-shadow: $shadow-button-text-primary-pressed;
    }
    &.disabled {
      background: $color-background-button-text-primary-disabled !important;
      box-shadow: $shadow-button-text-primary-disabled;
      color: $color-neutral-grey-60 !important;
      border: 1px solid $color-neutral-grey-8
    }
    &.loading {
      background: $color-background-button-text-primary-disabled !important;
      color: $color-neutral-grey-60 !important;
      .icon {
        background: #00003a !important;
      }
    }
    &.state-success {
      background: #f1fdf8 !important;
      color:$text-success !important;
      .icon {
        background: $color-green !important;
      }
    }
    &.state-danger {
      background: rgba(225, 95, 25, 0.08) !important;
      color:$text-error !important;
      .icon {
        background: $color-orange !important;
      }
    }
    &.state-warning {
      background: rgba(235, 199, 0, 0.08) !important;
      color:$text-warning !important;
      .icon {
        background: $color-yellow !important;
      }
    }
  }

  &.secondary {
    font-weight: 500;
    border: 1px solid $color-neutral-grey-8;
    box-shadow: $shadow-light-btn;
    background: $color-background-button-text-secondary-normal;
    color: $color-brand;
    border-radius: 8px;
    .icon {
      background:$color-brand;
      mask-size:16px;
    }
    &:hover {
      box-shadow: $shadow-button-text-secondary-hover;
      background: $color-background-button-text-secondary-hover;
    }
    &:active {
      box-shadow: $shadow-button-text-secondary-pressed;
      background: $color-background-button-text-secondary-pressed;
    }
    &.disabled {
      background: $color-background-button-text-secondary-disabled !important;
      box-shadow: $shadow-button-text-secondary-disabled;
      border: 1px solid $color-neutral-grey-8
    }
    &.loading {
      background: $color-background-button-text-secondary-disabled !important;
      .icon {
        background: #00003a !important;
      }
    }
    &.check {
      background: $color-background-button-text-secondary-disabled !important;
      .icon {
        background: #49dfa5 !important;
      }
    }
    &.state-success {
      background: #f1fdf8 !important;
      color:rgba(39, 120, 89, 1) !important;
      .icon {
        background: $color-green !important;
      }
    }
    &.state-danger {
      color:$text-error !important;
      .icon {
        background: $color-orange !important;
      }
    }
    &.state-warning {
      background: rgba(235, 199, 0, 0.08) !important;
      .icon {
        background: $color-yellow !important;
      }
    }
  }

  &.tertiary {
    font-weight: 500;
    border: none;
    box-shadow: none;
    background: $color-background-button-text-tertiary-normal;
    color: $color-brand;
    border-radius:8px;
    .icon {
      background:$color-brand;
      mask-size:16px;
    }
    &:hover {
      background: $color-background-button-text-tertiary-hover;
      box-shadow: $shadow-button-text-tertiary-hover;
    }
    &:active {
      background: $color-background-button-text-tertiary-pressed;
      box-shadow: $shadow-button-text-tertiary-pressed;
    }
    &.disabled {
      background: $color-background-button-text-tertiary-disabled !important;
      box-shadow: $shadow-button-text-tertiary-disabled;
    }
    &.loading {
      background: $color-background-button-text-tertiary-disabled !important;
      .icon {
        background: #00003a !important;
      }
    }
    &.check {
      background: $color-background-button-text-tertiary-disabled !important;
      .icon {
        background: #49dfa5 !important;
      }
    }
  }

  &.destructive {
    border-radius: 8px;
    background: $color-background-button-text-destructive-normal;
    box-shadow: $shadow-button-text-destructive-normal;
    font-family: Inter, sans-serif;
    line-height: 16px;
    font-weight: 400;
    border: 1px solid $color-neutral-grey-12;
    color: white;
    .icon {
      background:white;
      mask-size:16px;
    }
    &:hover {
      background: $color-background-button-text-destructive-hover;
      box-shadow: $shadow-button-text-destructive-hover;
    }
    &:active {
      background: $color-background-button-text-destructive-pressed;
      box-shadow: $shadow-button-text-destructive-pressed;
    }
    &.disabled {
      background: $color-background-button-text-destructive-disabled !important;
      box-shadow: $shadow-button-text-destructive-disabled;
      color: $color-neutral-grey-60 !important;
      border: 1px solid $color-neutral-grey-8
    }
    &.loading {
      background: $color-background-button-text-destructive-disabled !important;
      color: $color-neutral-grey-60 !important;
      .icon {
        background: #00003a !important;
      }
    }
    &.state-success {
      background: #f1fdf8 !important;
      color:$text-success !important;
      .icon {
        background: $color-green !important;
      }
    }
    &.state-danger {
      background: rgba(225, 95, 25, 0.08) !important;
      color:$text-error !important;
      .icon {
        background: $color-orange !important;
      }
    }
    &.state-warning {
      background: rgba(235, 199, 0, 0.08) !important;
      color:$text-warning !important;
      .icon {
        background: $color-yellow !important;
      }
    }
  }
  
  &.text {
    font-weight: 500;
    border: none;
    box-shadow: none;
    background: $color-background-button-text-tertiary-normal;
    border-radius: 8px;
    .icon {
      background:$color-brand;
      mask-size:16px;
    }
    &:hover {
      background: $color-background-button-text-tertiary-hover;
    }
    &:active {
      background: $color-background-button-text-tertiary-pressed;
    }
    &.disabled {
      background: $color-background-button-text-tertiary-disabled !important;
    }
    &.loading {
      background: $color-background-button-text-tertiary-disabled !important;
      .icon {
        background: #00003a !important;
      }
    }
    &.check {
      background: $color-background-button-text-tertiary-disabled !important;
      .icon {
        background: #49dfa5 !important;
      }
    }
  }

  &.small-icon {
    border: none;
    box-shadow: none;
    background: none;
    color: #00003A;
    border-radius:8px;
    height: 24px;
    width: 24px;
    .icon {
      background:#00003A !important;
    }
    &:hover {
      background: $color-background-button-icon-small-hover;
    }
    &:active {
      background: $color-background-button-icon-small-pressed;
      .icon {
        background: $color-brand !important;
      }
    }
    &.disabled {
      background: $color-background-button-icon-small-disabled !important;
    }
  }

  &.unit-switcher {
    border: none;
    box-shadow: none;
    background: none;
    color: $color-neutral-grey-60;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 20px !important;
    border-radius:8px;
    height: 20px;
    .custom-button-text {
      padding-left: 4px !important;
      padding-right: 0px !important;
    }
    .icon {
      background:$color-neutral-grey-60 !important;
    }
    &:hover {
      color: $color-neutral-black;
      background: $color-background-button-icon-small-hover;
    }
    &:active {
      color: $color-brand;
      background: $color-background-button-icon-small-pressed;
      .icon {
        background: $color-brand !important;
      }
    }
    &.disabled {
      background: $color-background-button-icon-small-disabled !important;
    }
  }

  &.slider-selected {
    font-weight: 500;
    border: none;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.04) 100%), #FFFFFF;
    color: black;
    border-radius:80px;
  }
  &.slider-not-selected {
    font-weight: 500;
    border: none;
    box-shadow: none;
    background: none;
    color: $color-neutral-grey-60;
    border-radius:80px;
  }
}


</style>
