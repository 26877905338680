<template>
  <div class="project-bar bar px-2 d-flex " :class="{collapsed:collapsed}" >
    <v-col class="ma-auto pa-0">
      <v-row class="pa-0 ma-0 d-flex">
        <v-col class="pa-0 my-auto title-container">
          <v-row class="ma-0 full-width">
            <CustomTextInput v-model="newName" class="mr-0 " :token="'token-text-medium'"  :plain="true" @blur="doNameChange" @keypress.enter="doNameChange" :max="30"/>
          </v-row>
        </v-col>
        <template v-if="currentClusterId">
          <Divider :vertical="true" class="mx-2"/>
          <v-col  class="pa-0 fit-content d-flex-col">
            <v-row class="ma-0 token-text-medium price token-text-color-primary ml-2" v-if="monthlyPrice">
              <PriceDisplay :price="displayPriceMin" :price2="displayPriceMax" :currency="customer?.attributes.currency"/>
            </v-row>
            <v-row class="ma-0 token-text-medium price token-text-color-primary ma-auto" v-else>
              <Spinner class="ma-auto"/>
            </v-row>
          </v-col>
        </template>
      </v-row>
    </v-col>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import Logo from '@/components/UIElements/Logo.vue'
import Badge from '@/components/UIElements/Badge.vue'
import Divider from '@/components/UIElements/Divider.vue'
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue'
import PriceDisplay from '@/components/UIElements/PriceDisplay.vue'
import Spinner from '@/components/UIElements/Spinner.vue'

import { Watch } from 'vue-property-decorator'
import API from '@/api/wrapper'
import { APIPriceRanges, APIClusterNodeTypologie, APICluster, APICustomer, APIClusterRequirement, APILoadBalancerTypologie } from '@/typesAPI'

@Options({
  components: {
    Logo,
    Badge,
    Divider,
    CustomTextInput,
    PriceDisplay,
    Spinner
  },
})
export default class ProjectBar extends Vue {

  showModalRenameProject = false
  newName = ""

  // FIRST UNBOARDING
  get collapsed () {
    return !this.$store.getters['app/getFirstUnboardingDone']
  }
  // END FIRST UNBOARDING


  get currentProject() {
    return this.$store.getters['projects/getCurrentProject']
  }

  @Watch('currentProject', {immediate: true, deep: true})
  onCurrentProjectChange() {
    if(this.currentProject) {
      this.newName = this.currentProject?.attributes?.name
    }
  }

  doNameChange() {
    if(this.newName !== this.currentProject.attributes.name) {
      API.projects.edit(this.currentProject.id, {name:this.newName})
      .then((newVal) => {
        this.$store.dispatch('projects/updateProject', newVal)
      }) 
    }
  }

  //Price 

  mounted(): void {
    if(!this.monthlyPrice) {
      API.prices.getMonthly(this.$route.params.projectId as string)
      .then ((res:APIPriceRanges) => {
        this.$store.dispatch('prices/setMonthlyPrice', res)
      })   
    }
  }

  get customer():APICustomer {
    return this.$store.getters['user/getCustomer']
  }
  get monthlyPrice():APIPriceRanges | undefined {
    return this.$store.getters['prices/getMonthlyPrice']
  }
  
  get providerRequirements ():APIClusterRequirement[] {
    if(!this.selectedCluster) {
      return []
    }
    return this.$store.getters['clusters/getClusterRequirementsByProviderAndLocation'](this.selectedCluster?.attributes.provider, this.selectedCluster.attributes.region)
  }


  get displayPriceMin():number {
    const indexTypoMin = this.clusterNodeTypologies.findIndex((typo:APIClusterNodeTypologie) => {
      return typo.id === this.selectedCluster?.attributes.requested_config.node_size_min
    })
    let ret = 0
    if(this.clusterNodeTypologies[indexTypoMin] && this.selectedCluster?.attributes?.region) {
      const priceProject = this.monthlyPrice ? parseInt(this.monthlyPrice.attributes.amount_min) : 0
      const priceWorkerNodes = this.selectedCluster?.attributes.requested_config.node_number_min * parseInt(this.clusterNodeTypologies[indexTypoMin].attributes.price.amount)
      const priceMasterNodes = this.minMasterNodes.length ? this.minMasterNodes.length * parseInt(this.minMasterNodes[0].attributes.price.amount) : 0
      ret = priceProject + priceWorkerNodes + priceMasterNodes  + this.minPriceLoadBalancer
    }
    return ret

  }

  get displayPriceMax():number {
    const indexTypoMax = this.clusterNodeTypologies.findIndex((typo:APIClusterNodeTypologie) => {
      return typo.id === this.selectedCluster?.attributes.requested_config.node_size_max
    })
    let ret = 0
    if(this.clusterNodeTypologies[indexTypoMax] && this.selectedCluster?.attributes?.region) {
      const priceProject = this.monthlyPrice ? parseInt(this.monthlyPrice.attributes.amount_max) : 0
      const priceWorkerNodes = this.selectedCluster?.attributes.requested_config.node_number_min * parseInt(this.clusterNodeTypologies[indexTypoMax].attributes.price.amount)
      const priceMasterNodes = this.maxMasterNodes.length ? this.maxMasterNodes.length * parseInt(this.maxMasterNodes[0].attributes.price.amount) : 0
      ret = priceProject + priceWorkerNodes + priceMasterNodes + this.maxPriceLoadBalancer
    }
    return ret
  }

  get minMasterNodes ():APIClusterNodeTypologie[] {
    if(this.selectedCluster && this.clusterNodeTypologies && this.providerRequirements) {
      return this.$helpers.clusters.getTypologiesFromRequirements(this.providerRequirements, this.clusterNodeTypologies, this.selectedCluster.attributes.requested_config.node_number_min ,this.selectedCluster.attributes.requested_config.node_number_min) as APIClusterNodeTypologie[]
    }
    return []
  }

  get maxMasterNodes ():APIClusterNodeTypologie[] {
    if(this.selectedCluster && this.clusterNodeTypologies && this.providerRequirements) {
      return this.$helpers.clusters.getTypologiesFromRequirements(this.providerRequirements, this.clusterNodeTypologies, this.selectedCluster.attributes.requested_config.node_number_max ,this.selectedCluster.attributes.requested_config.node_number_max) as APIClusterNodeTypologie[]
    }
    return []
  }

  //

  get clusterNodeTypologies():APIClusterNodeTypologie[] {
    if(!this.selectedCluster) {
      return []
    }
    return this.$store.getters['clusters/getClusterNodeTypologiesByProviderAndLocation'](this.selectedCluster?.attributes.provider, this.selectedCluster?.attributes?.region)
  }

  get loadBalancerTypologies():APILoadBalancerTypologie[] {
    if(!this.selectedCluster) {
      return []
    }
    return this.$store.getters['clusters/getLoadBalancerTypologiesByProviderAndLocation'](this.selectedCluster?.attributes.provider, this.selectedCluster?.attributes?.region)
  }

  get minPriceLoadBalancer():number {
    let ret = 0
    if(this.minLoadBalancer && this.selectedCluster?.attributes?.region) {
      ret = parseInt(this.minLoadBalancer.attributes.price?.amount)
    }
    return ret
  }

  get maxPriceLoadBalancer():number {
    let ret = 0
    if(this.maxLoadBalancer && this.selectedCluster?.attributes?.region) {
      ret = parseInt(this.maxLoadBalancer.attributes.price?.amount)
    }
    return ret
  }

  get minLoadBalancer ():APILoadBalancerTypologie | null {
    let typoRequirement = null
    if(this.selectedCluster) {
      typoRequirement = this.$helpers.clusters.getTypologiesFromRequirements(this.providerRequirements, this.loadBalancerTypologies, this.selectedCluster.attributes.requested_config.node_number_min, this.selectedCluster.attributes.requested_config.node_number_min) as APILoadBalancerTypologie[]
    }
    return typoRequirement?.length ? typoRequirement[0] : null
  }

  get maxLoadBalancer ():APILoadBalancerTypologie | null {
    let typoRequirement = null
    if(this.selectedCluster) {
      typoRequirement = this.$helpers.clusters.getTypologiesFromRequirements(this.providerRequirements, this.loadBalancerTypologies, this.selectedCluster.attributes.requested_config.node_number_max, this.selectedCluster.attributes.requested_config.node_number_max) as APILoadBalancerTypologie[]
    }
    return typoRequirement?.length ? typoRequirement[0] : null
  }

  //

  get clusters():APICluster[] {
    return this.$store.getters['clusters/getClusters']
  }

  get selectedCluster():APICluster | null {
    const ret = this.clusters?.length ? this.clusters.find(cluster => cluster.id === this.currentClusterId) : null
    return ret ? ret : null
  }

  get currentClusterId():string | null {
    if(this.currentProject && this.currentProject?.relationships?.kubernetes_clusters?.data?.length) {
      return this.currentProject?.relationships?.kubernetes_clusters?.data[0].id
    }
    return null
  }
}
</script>

<style lang="scss">
@import '@/css/variables';

.project-bar {
  height: 56px;
  font-size: 16px;
  font-weight: bolder;
  transition: width 0.2s ease-in-out 1.8s;

  .title-container {
    opacity: 1;
    transition: opacity 0.2s ease-in-out 2s;
    width: 142px;
    max-width: 142px;
    min-width: 142px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    :deep(.custom-text-input-container) {
      height: 38px !important;
    }
  }
  &.collapsed {
    width: 72px;
    transition: width 0.2s ease-in-out 2s;
    .title-container {
      transition: opacity 0.2s ease-in-out 1.8s;
      opacity: 0;
    }
  }
}
</style>