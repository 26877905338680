<template>
  <v-col class="pa-0 bar cluster-display pl-4 pr-2 pt-2 pb-4" :class="{selected : selected}" @click="$emit('clickDisplay', cluster)">
    <v-row class="ma-0 mb-2">
      <div class="my-auto token-text-medium mr-auto">{{ cluster.attributes.name }}</div>
      <Badge class="my-auto" :label="$helpers.states.translateDeploymentStatusToLabel(clustersStatus)" :severity="$helpers.states.translateDeploymentStatusToSeverity(clustersStatus)" :icon="clustersStatus === $enums.DeploymentStatus.DEPLOYING || clustersStatus === $enums.DeploymentStatus.SHUTTING_DOWN ? 'spinner' : ''" iconColor="rgba(0, 0, 58, 1)"/>
      <div class="ml-2 " @click.stop="">
        <CustomButton ref="menuButton" class="px-3" icon="burger" :level="$enums.ButtonLevels.Tertiary" @click="burgerOpened = !burgerOpened"/>
      </div>
      <DropdownMenu 
        :items="burgerItems"
        v-model:show="burgerOpened"
        :maxContent="true"
        @update:modelValue="onBurgerMenuItemClick($event, cluster)"
        :target="$refs.menuButton"
      />
    </v-row>
    <v-row class="ma-0 token-text-regular token-text-color-secondary">
      <v-col class="pa-0 mr-2 fit-content">
        <v-row class="ma-0 fit-content">
          <Logo :logo="cluster?.attributes?.provider" class="mr-2" />
          {{ $helpers.providers.providerToLabel(cluster?.attributes?.provider) }}
        </v-row>
      </v-col>
      <v-col class="pa-0 fit-content mr-2">
        <v-row class="ma-0" v-if="currentLocation">
          <Flag :code="currentLocation.attributes.country" class="mr-2" />
          {{ clusterDisplayName }}
        </v-row>
      </v-col>
      <v-col class="pa-0 fit-content">
        <v-row class="ma-0 ">
          <Icon class="mr-2" icon="chip" color="rgba(0, 0, 58, 0.6)"/>
          {{ cluster.attributes.requested_config.node_number_min }} 
          <Icon class="mx-1" icon="arrow-right" color="rgba(0, 0, 58, 0.6)"/>
          {{ cluster.attributes.requested_config.node_number_max }} 
          &nbsp;nodes
        </v-row>
      </v-col>
    </v-row>
    <template v-if="projects && projects.length">
      <v-row class="ma-0 my-4 separator-border-bottom">
      </v-row>
      <v-row class="ma-0 mt-4 token-text-regular" v-for="project in projects" :key="'cluster-'+cluster.id +'-project-'+project.id">
        {{ project.attributes.name }}
        <Badge class="ml-2 my-auto" :label="$helpers.states.translateDeploymentStatusToLabel(project?.attributes?.status)" :severity="$helpers.states.translateDeploymentStatusToSeverity(project?.attributes?.status)" :icon="project?.attributes?.status === $enums.DeploymentStatus.DEPLOYING || project?.attributes?.status === $enums.DeploymentStatus.SHUTTING_DOWN ? 'spinner' : ''" iconColor="rgba(0, 0, 58, 1)"/>
      </v-row>
    </template>
  </v-col>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import DropdownMenu from '@/components/UIElements/DropdownMenu.vue';
import Icon from '@/components/UIElements/Icon.vue';
import Logo from '@/components/UIElements/Logo.vue';
import Flag from '@/components/UIElements/Flag.vue';
import Badge from '@/components/UIElements/Badge.vue';
import { ListItem } from '@/components/UIElements/List.vue';
import { APICluster, APIClusterLocation, APIProject } from '@/typesAPI';
import { Watch } from 'vue-property-decorator';

class Props {
  cluster:APICluster = prop({
    required: true,
  });
  projects?:APIProject[] = prop({
    required: false,
  });
  clustersStatus:string = prop({
    required: true,
  });
  selected?:boolean = prop({
    required: false,
  });
  simpleBurger?:boolean = prop({
    required: false,
  });
}


@Options({
  components: {
    CustomButton,
    DropdownMenu,
    Icon,
    Flag,
    Badge,
    Logo
  },
})
export default class ClusterDisplay extends Vue.with(Props) {
  burgerOpened = false
  burgerItems: ListItem[] = [
    {
      id: 'edit',
      text: 'Edit config',
      icon: 'params'
    },
    {
      id: 'offline',
      text: 'Undeploy',
      icon: 'cloud-off'
    },
    {
      id: 'separator',
      separator: true,
    },
    {
      id: 'delete',
      text: 'Delete',
      icon: 'trash',
      class: 'token-text-color-orange'
    },
  ]  

  get locations ():APIClusterLocation[] {
    return this.$store.getters['clusters/getLocationsByProvider'](this.cluster.attributes.provider)
  }

  get currentLocation ():APIClusterLocation | undefined {
    return this.locations.find((location) => location.id === this.cluster.attributes.region)
  }

  get clusterDisplayName():string {
    let ret = ""
    if(this.currentLocation) {
      ret = this.$helpers.providers.locationToDisplayName(this.currentLocation)

    }
    return ret
  }

  onBurgerMenuItemClick(item:ListItem, cluster:APICluster) {
    this.$emit('clickMenu', item, cluster)
    this.burgerOpened = false
  }

  disabledItemsMenu() {
    this.burgerItems.forEach((item) => {
      if(item.id === 'offline') {
        item.disabled = this.clustersStatus === this.$enums.DeploymentStatus.OFFLINE || this.clustersStatus === this.$enums.DeploymentStatus.SHUTTING_DOWN
      } else if(item.id === 'delete') {
        item.disabled = this.clustersStatus !== this.$enums.DeploymentStatus.OFFLINE
      }
    })

    if(this.simpleBurger) {
      this.burgerItems = this.burgerItems.splice(0, 1)
    }
  }

  @Watch('cluster', {immediate: true, deep: true})
  onClusterChange() {
    if( this.cluster ) {
      this.disabledItemsMenu()
    }
  }

  @Watch('clustersStatus', {immediate: true, deep: true})
  onClustersStatusChange() {
    if( this.clustersStatus ) {
      this.disabledItemsMenu()
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

.cluster-display {
  cursor: pointer;
  &:hover {
    border: 1px solid $color-neutral-grey-60 !important;
    box-shadow: $shadow-z2 !important;
  }
  &.selected {
    padding-left: 15px !important;
    padding-right: 7px !important;
    padding-top: 7px !important;
    padding-bottom: 15px !important;
    border: 2px solid $color-brand !important;
    box-shadow: $shadow-z2 !important;
  }
}
</style>