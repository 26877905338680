import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "modal-cluster-config-content" }
const _hoisted_2 = { class: "ml-2 token-text-color-secondary token-text-regular my-auto" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomTextInput = _resolveComponent("CustomTextInput")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_CustomSelect = _resolveComponent("CustomSelect")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_Slider = _resolveComponent("Slider")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_PriceDisplay = _resolveComponent("PriceDisplay")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Modal, {
      visible: _ctx.visible,
      loading: _ctx.loading,
      onClickExternal: _ctx.onClose,
      maxHeightContent: 'calc(100vh - 315px)'
    }, {
      header: _withCtx(() => [
        _createVNode(_component_v_row, { class: "ma-n2" }, {
          default: _withCtx(() => [
            _createVNode(_component_CustomTextInput, {
              modelValue: _ctx.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
              class: "mr-0",
              style: {"width":"calc(100% - 32px)"},
              plain: true,
              max: 30
            }, null, 8, ["modelValue"]),
            _createVNode(_component_CustomButton, {
              class: "ml-auto mr-2 my-auto",
              level: _ctx.$enums.ButtonLevels.SmallIcon,
              icon: "x",
              onClick: _ctx.onClose
            }, null, 8, ["level", "onClick"])
          ]),
          _: 1
        })
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_v_col, { class: "pa-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { class: "ma-0 ml-4 my-2 token-text-h5" }, {
              default: _withCtx(() => _cache[17] || (_cache[17] = [
                _createTextVNode(" Price details ")
              ])),
              _: 1
            }),
            _createVNode(_component_v_row, { class: "ma-0 mx-4 token-text-regular" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "pa-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, { class: "ma-0" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { class: "pa-0 my-auto fit-content" }, {
                          default: _withCtx(() => _cache[18] || (_cache[18] = [
                            _createTextVNode(" Worker nodes ")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { class: "pa-0 my-auto mx-2" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Divider)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { class: "pa-0 ml-auto fit-content" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_PriceDisplay, {
                              price: _ctx.clusterNodeTypologies.length ? _ctx.minPriceWorkerNodes : null,
                              price2: _ctx.maxPriceWorkerNodes,
                              currency: _ctx.customer?.attributes.currency,
                              disableBurger: true
                            }, null, 8, ["price", "price2", "currency"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, { class: "ma-0" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { class: "pa-0 my-auto fit-content" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_row, { class: "ma-0" }, {
                              default: _withCtx(() => [
                                _cache[21] || (_cache[21] = _createElementVNode("div", null, "Control nodes", -1)),
                                _createVNode(_component_Tooltip, {
                                  class: "ml-2",
                                  docLink: "guides/cluster-configuration/#defining-the-number-of-nodes"
                                }, {
                                  header: _withCtx(() => _cache[19] || (_cache[19] = [
                                    _createTextVNode(" Control nodes are added ")
                                  ])),
                                  content: _withCtx(() => _cache[20] || (_cache[20] = [
                                    _createTextVNode(" Starting at 2 nodes, we add dedicated nodes to manage the workload on your cluster."),
                                    _createElementVNode("br", null, null, -1),
                                    _createTextVNode(" They are added to improve reliability and stability and while fully managed by Fransys, they increase the overall cost of your cluster. ")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { class: "pa-0 my-auto mx-2" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Divider)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { class: "pa-0 ml-auto fit-content" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_PriceDisplay, {
                              price: _ctx.providerRequirements.length ? _ctx.minPriceMasterNodes : null,
                              price2: _ctx.maxPriceMasterNodes,
                              currency: _ctx.customer?.attributes.currency,
                              disableBurger: true
                            }, null, 8, ["price", "price2", "currency"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, { class: "ma-0" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { class: "pa-0 my-auto fit-content" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_row, { class: "ma-0" }, {
                              default: _withCtx(() => [
                                _cache[24] || (_cache[24] = _createElementVNode("div", null, "Load balancer", -1)),
                                _createVNode(_component_Tooltip, { class: "ml-2" }, {
                                  header: _withCtx(() => _cache[22] || (_cache[22] = [
                                    _createTextVNode(" A load balancer is added ")
                                  ])),
                                  content: _withCtx(() => _cache[23] || (_cache[23] = [
                                    _createTextVNode(" Starting at 2 nodes, we add a load balancer in front of your cluster."),
                                    _createElementVNode("br", null, null, -1),
                                    _createTextVNode(" It is fully managed by Fransys and is here to better balance your traffic and improve reliability. ")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { class: "pa-0 my-auto mx-2" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Divider)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { class: "pa-0 ml-auto fit-content" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_PriceDisplay, {
                              price: _ctx.providerRequirements.length ? _ctx.minPriceLoadBalancer : null,
                              price2: _ctx.maxPriceLoadBalancer,
                              currency: _ctx.customer?.attributes.currency,
                              disableBurger: true
                            }, null, 8, ["price", "price2", "currency"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, { class: "pa-4 ma-0 flex" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "pa-0 my-auto fit-content" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, { class: "ma-0 token-text-medium token-text-color-primary" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_PriceDisplay, {
                          price: _ctx.totalPriceMin,
                          price2: _ctx.totalPriceMax,
                          currency: _ctx.customer?.attributes.currency
                        }, null, 8, ["price", "price2", "currency"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, { class: "pa-0 fit-content ml-auto" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, { class: "ma-0" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_CustomButton, {
                          level: _ctx.$enums.ButtonLevels.Tertiary,
                          text: "Cancel",
                          class: "mr-2",
                          onClick: _ctx.cancel
                        }, null, 8, ["level", "onClick"]),
                        _createVNode(_component_CustomButton, {
                          level: _ctx.$enums.ButtonLevels.Secondary,
                          text: _ctx.textAccept,
                          class: "mr-2",
                          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.accept(false))),
                          status: _ctx.buttonStateSave,
                          loading: _ctx.saving,
                          disabled: _ctx.disableBtnEdit
                        }, null, 8, ["level", "text", "status", "loading", "disabled"]),
                        _createVNode(_component_CustomButton, {
                          level: _ctx.$enums.ButtonLevels.Primary,
                          text: 'Deploy cluster',
                          icon: 'publish',
                          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.accept(true))),
                          status: _ctx.buttonStatePublish,
                          loading: _ctx.saving,
                          disabled: _ctx.disableBtnPublish
                        }, null, 8, ["level", "status", "loading", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.isTrialing)
            ? (_openBlock(), _createBlock(_component_v_row, {
                key: 0,
                class: "ma-4 pa-4 alert-trial"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { class: "pa-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { class: "ma-0 token-text-medium" }, {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createTextVNode(" Cluster are not editable with the free plan ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_row, { class: "ma-0 token-text-regular" }, {
                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                          _createTextVNode(" Upgrade your plan to edit the cluster or enable auto scaling ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_row, { class: "ma-0 mt-4" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_CustomButton, {
                            level: _ctx.$enums.ButtonLevels.Primary,
                            class: "full-width",
                            onClick: _ctx.upgradePlan,
                            loading: _ctx.upgradePlanLoading
                          }, {
                            default: _withCtx(() => _cache[9] || (_cache[9] = [
                              _createTextVNode(" Upgrade plan ")
                            ])),
                            _: 1
                          }, 8, ["level", "onClick", "loading"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_v_row, { class: "ma-0 ml-4 mt-2 mb-2 token-text-h5" }, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [
              _createTextVNode(" Provider ")
            ])),
            _: 1
          }),
          _createVNode(_component_v_row, { class: "ma-0 mx-4 mb-6" }, {
            default: _withCtx(() => [
              _createVNode(_component_CustomSelect, {
                placeholder: "Select a value",
                items: _ctx.providerListItems,
                modelValue: _ctx.providerActualValue,
                "onUpdate:modelValue": [
                  _cache[1] || (_cache[1] = ($event: any) => ((_ctx.providerActualValue) = $event)),
                  _ctx.onSelectProvider
                ],
                disabled: _ctx.clusterDeployment && _ctx.clusterDeployment?.attributes?.status !== _ctx.$enums.DeploymentStatus.OFFLINE
              }, null, 8, ["items", "modelValue", "onUpdate:modelValue", "disabled"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, { class: "ma-0 ml-4 mt-2 mb-2 token-text-h5" }, {
            default: _withCtx(() => _cache[11] || (_cache[11] = [
              _createTextVNode(" Region ")
            ])),
            _: 1
          }),
          _createVNode(_component_v_row, { class: "ma-0 mx-4 mb-6" }, {
            default: _withCtx(() => [
              _createVNode(_component_CustomSelect, {
                placeholder: "Select a value",
                items: _ctx.regionsListItems,
                modelValue: _ctx.regionsActualValue,
                "onUpdate:modelValue": [
                  _cache[2] || (_cache[2] = ($event: any) => ((_ctx.regionsActualValue) = $event)),
                  _ctx.onSelectRegion
                ],
                disabled: _ctx.clusterDeployment && _ctx.clusterDeployment?.attributes?.status !== _ctx.$enums.DeploymentStatus.OFFLINE
              }, null, 8, ["items", "modelValue", "onUpdate:modelValue", "disabled"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, { class: "ma-0 ml-4 mb-2 token-text-h5" }, {
            default: _withCtx(() => [
              _cache[14] || (_cache[14] = _createElementVNode("div", null, " Worker nodes ", -1)),
              (_ctx.minMasterNodes.length)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("div", _hoisted_2, " + " + _toDisplayString(_ctx.minMasterNodes.length) + " control node(s) ", 1),
                    _createVNode(_component_Tooltip, {
                      class: "ml-2 my-auto",
                      docLink: "guides/cluster-configuration/#defining-the-number-of-nodes"
                    }, {
                      header: _withCtx(() => _cache[12] || (_cache[12] = [
                        _createTextVNode(" Control nodes are added ")
                      ])),
                      content: _withCtx(() => _cache[13] || (_cache[13] = [
                        _createTextVNode(" Starting at 2 nodes, we add dedicated nodes to manage the workload on your cluster."),
                        _createElementVNode("br", null, null, -1),
                        _createTextVNode(" They are added to improve reliability and stability and while fully managed by Fransys, they increase the overall cost of your cluster. ")
                      ])),
                      _: 1
                    })
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, { class: "ma-0 mx-4" }, {
            default: _withCtx(() => [
              _createVNode(_component_Slider, {
                value: _ctx.nbNodesActualRange[0],
                step: 1,
                min: 1,
                max: _ctx.maxNbNodes,
                "onUpdateValue:value": _cache[3] || (_cache[3] = ($event: any) => {_ctx.nbNodesActualRange[0] = $event; _ctx.nbNodesActualRange[1] = $event}),
                disabled: _ctx.isTrialing
              }, null, 8, ["value", "max", "disabled"])
            ]),
            _: 1
          }),
          (_ctx.isAutoScalling)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode(_component_v_row, { class: "ma-0 ml-4 mb-6 token-text-h5" }, {
                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                    _createTextVNode(" Worker nodes typology ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_row, { class: "ma-0 mx-4" }, {
                  default: _withCtx(() => [
                    (_ctx.clusterNodeTypologies[_ctx.nodesPerfActualRange[0]])
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 0,
                          class: "pa-2 custom-thumb"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_row, { class: "ma-0" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.clusterNodeTypologies[_ctx.nodesPerfActualRange[0]].attributes.name), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_row, { class: "ma-0 token-text-color-secondary" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.clusterNodeTypologies[_ctx.nodesPerfActualRange[0]].attributes.cores) + " CPUs ", 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_row, { class: "ma-0 token-text-color-secondary" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.clusterNodeTypologies[_ctx.nodesPerfActualRange[0]].attributes.memory) + " RAM ", 1)
                              ]),
                              _: 1
                            }),
                            (_ctx.regionsActualValue?.value)
                              ? (_openBlock(), _createBlock(_component_v_row, {
                                  key: 0,
                                  class: "ma-0 token-text-color-secondary"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$helpers.prices.format(parseInt(_ctx.clusterNodeTypologies[_ctx.nodesPerfActualRange[0]].attributes.price.amount), _ctx.clusterNodeTypologies[_ctx.nodesPerfActualRange[0]].attributes.price.currency)), 1)
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_col, { class: "pa-0" }),
                    (_ctx.clusterNodeTypologies[_ctx.nodesPerfActualRange[0]])
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 1,
                          class: "pa-2 custom-thumb"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_row, { class: "ma-0" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.clusterNodeTypologies[_ctx.nodesPerfActualRange[1]].attributes.name), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_row, { class: "ma-0 token-text-color-secondary" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.clusterNodeTypologies[_ctx.nodesPerfActualRange[1]].attributes.cores) + " CPUs ", 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_row, { class: "ma-0 token-text-color-secondary" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.clusterNodeTypologies[_ctx.nodesPerfActualRange[1]].attributes.memory) + " RAM ", 1)
                              ]),
                              _: 1
                            }),
                            (_ctx.regionsActualValue?.value)
                              ? (_openBlock(), _createBlock(_component_v_row, {
                                  key: 0,
                                  class: "ma-0 token-text-color-secondary"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$helpers.prices.format(parseInt(_ctx.clusterNodeTypologies[_ctx.nodesPerfActualRange[1]].attributes.price.amount), _ctx.clusterNodeTypologies[_ctx.nodesPerfActualRange[1]].attributes.price.currency)), 1)
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, { class: "ma-0 mx-4" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Slider, {
                      values: _ctx.nodesPerfActualRange,
                      step: 1,
                      min: 0,
                      max: _ctx.clusterNodeTypologies.length - 1,
                      hideThumbLabels: true,
                      "onUpdateValue:values": _cache[4] || (_cache[4] = ($event: any) => (_ctx.nodesPerfActualRange = $event)),
                      range: true,
                      disabled: _ctx.isTrialing
                    }, null, 8, ["values", "max", "disabled"])
                  ]),
                  _: 1
                })
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createVNode(_component_v_row, { class: "ma-0 ml-4 mb-2 token-text-h5" }, {
                  default: _withCtx(() => _cache[16] || (_cache[16] = [
                    _createTextVNode(" Worker nodes typology ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_row, { class: "ma-0 ml-4 mb-2" }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tagList, (tag) => {
                      return (_openBlock(), _createBlock(_component_v_col, {
                        class: "pa-0 mr-2 fit-content",
                        key: 'tag-'+tag
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Badge, {
                            label: tag,
                            severity: _ctx.$enums.Severity.NEUTRAL,
                            outlined: !_ctx.selectedTags.includes(tag),
                            onClick: ($event: any) => (_ctx.onClickTag(tag)),
                            class: "tag"
                          }, null, 8, ["label", "severity", "outlined", "onClick"])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, { class: "ma-0 mx-4 mb-2" }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clusterNodeTypologies, (typology, index) => {
                      return (_openBlock(), _createBlock(_component_v_col, {
                        class: "pa-0",
                        key: 'typo-' + index,
                        cols: "6"
                      }, {
                        default: _withCtx(() => [
                          (!_ctx.isTypoAbsent(typology))
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(["card-typo", (index%2 === 0 ? ' mr-2 ' : ' ml-2 ') + (_ctx.nodesPerfActualRange[0] === index ? ' selected ' : '') + (_ctx.isTypoDisabled(typology) ? ' disabled ' : '')]),
                                onClick: ($event: any) => (_ctx.onClickTypoCard(typology, index))
                              }, [
                                _createVNode(_component_v_row, { class: "ma-0 mb-2" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, { class: "pa-0 fit-content" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(typology.attributes.name), 1)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_v_col, { class: "pa-0 ml-auto fit-content" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_PriceDisplay, {
                                          price: _ctx.currentLocation && typology.attributes.price ? typology.attributes.price.amount : null,
                                          currency: _ctx.customer?.attributes.currency,
                                          hidePeriodicity: true
                                        }, null, 8, ["price", "currency"])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_row, { class: "ma-0 token-text-color-secondary" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(typology.attributes.cores) + " " + _toDisplayString(typology.attributes.dedicated_cpu ? ' dedicated ' : ' shared ') + " CPUs ", 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_row, { class: "ma-0 token-text-color-secondary" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(typology.attributes.memory) + " RAM ", 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_row, { class: "ma-0 token-text-color-secondary" }, {
                                  default: _withCtx(() => [
                                    (typology.attributes.cpu_family)
                                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                          _createTextVNode(_toDisplayString(typology.attributes.cpu_family), 1)
                                        ], 64))
                                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                          _createTextVNode("   ")
                                        ], 64))
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_row, { class: "ma-0 mt-1" }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(typology.attributes.tags, (tag) => {
                                      return (_openBlock(), _createBlock(_component_v_col, {
                                        class: "pa-0 mr-2 fit-content",
                                        key: typology?.id+'-tag-'+tag
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_Badge, {
                                            label: tag,
                                            severity: _ctx.$enums.Severity.NEUTRAL,
                                            outlined: !_ctx.selectedTags.includes(tag),
                                            onClick: ($event: any) => (_ctx.onClickTag(tag)),
                                            class: "tag"
                                          }, null, 8, ["label", "severity", "outlined", "onClick"])
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128))
                                  ]),
                                  _: 2
                                }, 1024)
                              ], 10, _hoisted_3))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                })
              ], 64))
        ])
      ]),
      _: 1
    }, 8, ["visible", "loading", "onClickExternal"])
  ]))
}