import enums, { DeploymentState } from "@/enums"
import { APIDeploymentPlan, APIDeploymentRun, APIDeploymentSubject, APIDeploymentLog, APIDeploymentVersion, APIBaseAttributes } from "@/typesAPI"
import helpers from '@/utils/helpers'
import store from ".."

type State = {
  deploymentPlans: APIDeploymentPlan[],
  deploymentRuns: APIDeploymentRun[],
  deploymentVersions: APIDeploymentVersion[],
  deploymentLogs: {[key:string]:APIDeploymentLog[]},
  listSubjects: APIDeploymentSubject[]
  forceDeployCompletedState: boolean,
  modalIsOpen: boolean,
  modalIsReduced: boolean,
  modalIsDraftView: boolean,
  modalIsSelectClusterView: boolean
}

const state: State = {
  deploymentPlans: [],
  deploymentVersions: [],
  deploymentRuns: [],
  deploymentLogs: {},
  listSubjects: [],
  forceDeployCompletedState: false,
  modalIsOpen: false,
  modalIsReduced: false,
  modalIsDraftView: false,
  modalIsSelectClusterView: false
}


const getters = {
  getRunningDeploymentPlans: (state:State) => {
    const runningVersions = state.deploymentVersions.filter((v) => v.attributes.status === 'running')

    const ret:APIDeploymentPlan[] = []

    if(runningVersions.length) {
       state.deploymentPlans.forEach((plan:APIDeploymentPlan) => {
        if(runningVersions.find((runningVersion) => plan.relationships.version.data.id === runningVersion.id)) {
          if(plan.relationships.subject.data.type === "kubernetesClusters") {
            ret.unshift(plan)
          } else {
            ret.push(plan)
          }
        }
      })
    }
    return ret
  },
  getRunningOrCompletedDeploymentPlans: (state:State) => {
    const runningVersions = state.deploymentVersions.filter((v) => v.attributes.status === 'running')
    const completedVersions = state.deploymentVersions.filter((v) => v.attributes.status === 'completed')

    let ret:APIDeploymentPlan[] = []

    if(runningVersions.length || completedVersions.length) {
       state.deploymentPlans.forEach((plan:APIDeploymentPlan) => {
        if(runningVersions.find((runningVersion) => plan.relationships.version.data.id === runningVersion.id) || completedVersions.find((runningVersion) => plan.relationships.version.data.id === runningVersion.id)) {
          if(plan.relationships.subject.data.type === "kubernetesClusters") {
            ret.unshift(plan)
          } else {
            ret.push(plan)
          }
        }
      })
    }
    ret = ret.filter((plan:APIDeploymentPlan) => plan.attributes.origin !== 'ROLLBACK')

    return ret
  },
  getDeploymentPlans: (state:State) => {
    return state.deploymentPlans.filter((plan:APIDeploymentPlan) => plan.attributes.origin !== 'ROLLBACK')
  },
  getDeploymentPlansRollback: (state:State) => {
    return state.deploymentPlans.filter((plan:APIDeploymentPlan) => plan.attributes.origin === 'ROLLBACK')
  },
  getDraftVersions: (state:State) => {
    return state.deploymentVersions.filter((v) => v.attributes.status === 'draft')
  },
  getRunningVersions: (state:State) => {
    return state.deploymentVersions.filter((v) => v.attributes.status === 'running')
  },
  getDeploymentPlansByVersionId: (state:State) => {
    return (versionId:string) => {
      const ret:APIDeploymentPlan[] = []
      state.deploymentPlans.forEach((deploymentPlan:APIDeploymentPlan) => {
        if(deploymentPlan.relationships.version.data.id === versionId) {
          ret.push(deploymentPlan)
        }
      })
      
      return ret
    }
  },
  getDeploymentRuns: (state:State) => {
    return state.deploymentRuns
  },
  getDeploymentVersions: (state:State) => {
    return state.deploymentVersions
  },
  getDeploymentLogsByPlanId: (state:State) => {
    return (planId:string) => {
      return state.deploymentLogs[planId] ? state.deploymentLogs[planId] : []
    }
  },
  getDeployingState: (state:State) => {

    let ret:string = DeploymentState.NotStarted
    state.deploymentVersions.forEach((version:APIDeploymentVersion) => {
      if(version.attributes.status === DeploymentState.Running){
        ret = DeploymentState.Running
      }
    })
    if(state.forceDeployCompletedState && ret !== DeploymentState.Running) {
      return DeploymentState.Completed
    }
    return ret
  },
  getModalIsOpen: (state:State) => {
    return state.modalIsOpen
  },
  getModalIsReduced: (state:State) => {
    return state.modalIsReduced
  },
  getListSubjects: (state:State) => {
    return state.listSubjects
  },
  getModalIsDraftView: (state:State) => {
    return state.modalIsDraftView
  },
  getModalIsSelectClusterView: (state:State) => {
    return state.modalIsSelectClusterView
  },
}

const mutations = {
  SET_DEPLOYMENT_PLANS: (state:State, deploymentPlans:APIDeploymentPlan[]) => {
    state.deploymentPlans = deploymentPlans
  },
  ADD_DEPLOYMENT_PLANS: (state:State, deploymentPlans:APIDeploymentPlan[]) => {
    helpers.ressources.addMultipleRessourceToArray(state.deploymentPlans, deploymentPlans)
  },
  SET_DEPLOYMENT_RUNS: (state:State, deploymentRuns:APIDeploymentRun[]) => {
    state.deploymentRuns = deploymentRuns
  },

  SET_DEPLOYMENT_VERSIONS: (state:State, deploymentVersions:APIDeploymentVersion[]) => {
    state.deploymentVersions = deploymentVersions
  },
  ADD_DEPLOYMENT_VERSIONS: (state:State, deploymentVersions:APIDeploymentVersion[]) => {
    helpers.ressources.addMultipleRessourceToArray(state.deploymentVersions, deploymentVersions)
  },
  EDIT_DEPLOYMENT_VERSION: (state:State, deploymentVersion:APIDeploymentVersion) => {
    const index = state.deploymentVersions.findIndex((d:APIDeploymentVersion) => d.id === deploymentVersion.id)
    if(index >= 0) {
      if(helpers.ressources.isNewerThan(deploymentVersion, state.deploymentVersions[index])) {
        state.deploymentVersions[index] = deploymentVersion
      }
    } else {
      state.deploymentVersions.push(deploymentVersion)
    }  
  },
  DELETE_DEPLOYMENT_VERSION: (state:State, deploymentVersion:APIDeploymentVersion) => {
    const index = state.deploymentVersions.findIndex((d:APIDeploymentPlan) => d.id === deploymentVersion.id)
    if(index >= 0) {
      state.deploymentPlans.splice(index,1)
    }
  },
  EDIT_DEPLOYMENT_RUN: (state:State, deploymentRun:APIDeploymentRun) => {
    const index = state.deploymentRuns.findIndex((d:APIDeploymentRun) => d.id === deploymentRun.id)
    if(index >= 0) {
      if(helpers.ressources.isNewerThan(deploymentRun, state.deploymentRuns[index])) {
        state.deploymentRuns[index] = deploymentRun
      }
    } else {
      state.deploymentRuns.push(deploymentRun)
    }  
  },
  EDIT_DEPLOYMENT_PLAN: (state:State, deploymentPlan:APIDeploymentPlan) => {
    if(deploymentPlan?.attributes?.last_log_entry === null) {
      if(state.deploymentLogs[deploymentPlan.id]?.length) {
        const lastLog = state.deploymentLogs[deploymentPlan.id].reduce((prev:APIDeploymentLog, current:APIDeploymentLog) => {
          if(new Date(prev.attributes.time).getTime() - new Date(current.attributes.time).getTime() > 0) {
            return prev
          }
          return current
        })
        if(lastLog) {
          deploymentPlan.attributes.last_log_entry = lastLog.attributes
        }
      }
    }
    helpers.ressources.addMultipleRessourceToArray(state.deploymentPlans, [deploymentPlan])
  },
  DELETE_DEPLOYMENT_PLAN: (state:State, deploymentPlan:APIDeploymentPlan) => {
    const index = state.deploymentPlans.findIndex((d:APIDeploymentPlan) => d.id === deploymentPlan.id)
    if(index >= 0) {
      state.deploymentPlans.splice(index,1)
    }
  },
  DELETE_COMPLETED_DEPLOYMENT_VERSION_AND_PLAN: (state:State) => {
    state.deploymentPlans = state.deploymentPlans.filter((d:APIDeploymentPlan) => d.attributes.status === enums.DeploymentRunState.PENDING)
    state.deploymentVersions = state.deploymentVersions.filter((d:APIDeploymentVersion) => d.attributes.status === enums.DeploymentRunState.RUNNING)
  },
  RESET_DEPLOYMENT_LOGS: (state:State, planId:string) => {
    state.deploymentLogs[planId] = []
  },
  ADD_DEPLOYMENT_LOGS: (state:State, payload:{deploymentLogs:APIDeploymentLog[], planId:string}) => {
    if(!state.deploymentLogs[payload.planId]) {
      state.deploymentLogs[payload.planId] = []
    }

    helpers.ressources.addMultipleRessourceToArray( state.deploymentLogs[payload.planId], payload.deploymentLogs)


    //Sort by time
    state.deploymentLogs[payload.planId].sort((logA:APIDeploymentLog, logB:APIDeploymentLog) => {
      return new Date(logA.attributes.time).getTime() - new Date(logB.attributes.time).getTime()
    })

    //Edit plan last_log_entry
    if(state.deploymentLogs[payload.planId].length) {
      const lastLog = state.deploymentLogs[payload.planId].reduce((prev:APIDeploymentLog, current:APIDeploymentLog) => {
        if(new Date(prev.attributes.time).getTime() - new Date(current.attributes.time).getTime() > 0) {
          return prev
        }
        return current
      })
      const planIndex = state.deploymentPlans.findIndex((plan:APIDeploymentPlan) => plan.id === payload.planId)
      if(planIndex >= 0 && helpers.ressources.isNewerThan(lastLog, state.deploymentPlans[planIndex])) {
        state.deploymentPlans[planIndex].attributes.last_log_entry = lastLog.attributes
      }
    }
  },
  SET_MODAL_IS_OPEN: (state:State, modalIsOpen:boolean) => {
    state.modalIsOpen = modalIsOpen
    if(modalIsOpen) {
      state.modalIsReduced = false
    }
  },
  SET_MODAL_IS_REDUCED: (state:State, modalIsReduced:boolean) => {
    state.modalIsReduced = modalIsReduced
  },
  SET_DEPLOYMENT_SUBJECTS: (state:State, deploymentSubjects:APIDeploymentSubject[]) => {
    state.listSubjects = deploymentSubjects
  },
  ADD_DEPLOYMENT_SUBJECT: (state:State, deploymentSubject:APIDeploymentSubject) => {
    if(deploymentSubject) {
      helpers.ressources.addMultipleRessourceToArray(state.listSubjects, [deploymentSubject])
    }
  },
  EDIT_DEPLOYMENT_SUBJECT: (state:State, deploymentSubject:APIDeploymentSubject) => {
    if(deploymentSubject) {
      helpers.ressources.addMultipleRessourceToArray(state.listSubjects, [deploymentSubject], true)
    }
  },
  SET_FORCE_DEPLOY_COMPLETED_STATE: (state:State, newVal:boolean) => {
    state.forceDeployCompletedState = newVal
  },
  SET_MODAL_IS_DRAFT_VIEW: (state:State, newVal:boolean) => {
    state.modalIsDraftView = newVal
  },
  SET_MODAL_IS_SELECT_CLUSTER_VIEW: (state:State, newVal:boolean) => {
    state.modalIsSelectClusterView = newVal
  },
  CLEAR: (state:State) => {
    state.deploymentPlans = []
    state.deploymentVersions = []
    state.deploymentRuns = []
    state.deploymentLogs = {}
    state.listSubjects = []
    state.forceDeployCompletedState = false
    state.modalIsOpen = false
    state.modalIsReduced = false
    state.modalIsDraftView = false
  },
}

const actions = {
  setDeploymentRuns: (context:any, deploymentRuns:APIDeploymentRun[]) => {
    context.commit('SET_DEPLOYMENT_RUNS', deploymentRuns)
  },
  editDeploymentRun: (context:any, deploymentRun:APIDeploymentRun) => {
    context.commit('EDIT_DEPLOYMENT_RUN', deploymentRun)
  },
  setDeploymentVersions: (context:any, deploymentVersions:APIDeploymentVersion[]) => {
    context.commit('SET_DEPLOYMENT_VERSIONS', deploymentVersions)
  },
  addDeploymentVersions: (context:any, deploymentVersions:APIDeploymentVersion[]) => {
    context.commit('ADD_DEPLOYMENT_VERSIONS', deploymentVersions)
  },
  addDeploymentVersion: (context:any, deploymentVersion:APIDeploymentVersion) => {
    context.commit('EDIT_DEPLOYMENT_VERSION', deploymentVersion)
  },
  deleteDeploymentVersion: (context:any, deploymentVersion:APIDeploymentVersion) => {
    context.commit('DELETE_DEPLOYMENT_VERSION', deploymentVersion)
  },
  deleteCompletedDeploymentVersionAndPlan: (context:any) => {
    context.commit('DELETE_COMPLETED_DEPLOYMENT_VERSION_AND_PLAN')
  },
  setDeploymentPlans: (context:any, deploymentPlans:APIDeploymentPlan[]) => {
    context.commit('SET_DEPLOYMENT_PLANS', deploymentPlans)
  },
  addDeploymentPlans: (context:any, deploymentPlans:APIDeploymentPlan[]) => {
    context.commit('ADD_DEPLOYMENT_PLANS', deploymentPlans)
  },
  deleteDeploymentPlan: (context:any, deploymentPlan:APIDeploymentPlan) => {
    context.commit('DELETE_DEPLOYMENT_PLAN', deploymentPlan)
  },
  addDeploymentLogs: (context:any, payload:{deploymentLogs:APIDeploymentLog[], planId:string}) => {
    context.commit('ADD_DEPLOYMENT_LOGS', payload)
  },
  setDeploymentLogs: (context:any, payload:{deploymentLogs:APIDeploymentLog[], planId:string}) => {
    context.commit('RESET_DEPLOYMENT_LOGS', payload.planId)
    context.commit('ADD_DEPLOYMENT_LOGS', payload)
  },
  editDeploymentPlan: (context:any, deploymentPlan:APIDeploymentPlan) => {
    context.commit('EDIT_DEPLOYMENT_PLAN', deploymentPlan)
  },
  setModalIsOpen: (context:any, modalIsOpen:boolean) => {
    context.commit('SET_MODAL_IS_OPEN', modalIsOpen)
  },
  setModalIsReduced: (context:any, modalIsReduced:boolean) => {
    context.commit('SET_MODAL_IS_REDUCED', modalIsReduced)
  },
  setDeploymentSubjects: (context:any, deploymentSubjects:APIDeploymentSubject[]) => {
    context.commit('SET_DEPLOYMENT_SUBJECTS', deploymentSubjects)
  },
  addDeploymentSubject: (context:any, deploymentSubject:APIDeploymentSubject) => {
    context.commit('ADD_DEPLOYMENT_SUBJECT', deploymentSubject)
  },
  editDeploymentSubject: (context:any, deploymentSubject:APIDeploymentSubject) => {
    context.commit('EDIT_DEPLOYMENT_SUBJECT', deploymentSubject)
  },
  setForceDeployCompletedState: (context:any, newVal:boolean) => {
    context.commit('SET_FORCE_DEPLOY_COMPLETED_STATE', newVal)
  },
  setModalIsDraftView: (context:any, newVal:boolean) => {
    context.commit('SET_MODAL_IS_DRAFT_VIEW', newVal)
  },
  setModalIsSelectClusterView: (context:any, newVal:boolean) => {
    context.commit('SET_MODAL_IS_SELECT_CLUSTER_VIEW', newVal)
  },
  clear: (context:any) => {
    context.commit('CLEAR')
  },
}
export default {
  state,
  getters,
  mutations,
  actions
}