<template>
  <div class="block-detail-content-initiated" >
    <template v-for="box of displayedBoxes" :key="'box-container-'+box.id">
      <template v-for="(fieldValuesByBoxID, i) of [getFieldValuesByBoxID(box.id)]" :key="'def-fieldValuesByBoxID-' + i">
        <FieldBox :title="box.attributes.name" v-model:deployed="boxesOpen[box.id]" :vertical="fieldValuesByBoxID.length !== 1" :box="box" :disabled="getBoxDisabledState(box)">
          <template #value>
            <v-col class="pa-0">
              <v-row
                v-for="relationshipFieldValue in getFieldValuesByBoxID(box.id, true)"
                :key="'box-value-' +relationshipFieldValue.id"
                :id="'box-value-' +relationshipFieldValue.id"
                class="ma-0"
              >
                <template v-for="fieldDefinitionByIDValue of [getFieldsDefinitionsByIDValue(relationshipFieldValue.id)]" :key="'def-fieldDefinitionByIDValue-'+ fieldDefinitionByIDValue?.id">
                  <template v-if="fieldDefinitionByIDValue.attributes.should_show_in_recap">
                    <v-col class="pa-0 fit-content token-text-regular token-text-color-secondary pb-2" v-if="fieldValuesByBoxID.length !== 1"  cols="12">
                      {{fieldSummary[relationshipFieldValue.id]?.noTitle || fieldSummary[relationshipFieldValue.id]?.isURL ? "" : fieldDefinitionByIDValue.attributes.name }}
                    </v-col>
                    <v-col class="pa-0 mr-0 token-text-regular value-container" :class="fieldSummary[relationshipFieldValue.id] && fieldSummary[relationshipFieldValue.id].isURL ? 'ml-0' : 'ml-auto pl-2'">
                      <ListElement 
                        v-if="fieldSummary[relationshipFieldValue.id]"
                        :isURL="fieldSummary[relationshipFieldValue.id].isURL"
                        :iconRigth="fieldSummary[relationshipFieldValue.id].isURL ? 'share' : ''"
                        :logo="fieldSummary[relationshipFieldValue.id].logo"
                        :text="fieldSummary[relationshipFieldValue.id].text"
                        :badgeState="fieldSummary[relationshipFieldValue.id].badgeState"
                        :badgeLabel="fieldSummary[relationshipFieldValue.id].badgeLabel"
                        :badgeOutlined="fieldSummary[relationshipFieldValue.id].badgeOutlined"
                        :noHover="true" 
                        :alignRight="!fieldSummary[relationshipFieldValue.id].isURL"
                        :dense="true"
                      />
                      <template v-else>&nbsp;</template>
                    </v-col>
                  </template>
                </template>
              </v-row>
            </v-col>
          </template>
          <template #deployed>
            <div >
              <!-- Not grouped fields -->
              <template
                v-for="fieldValue of getNotGroupedFieldsValuesByBox(box)" 
                :key="'fv-'+fieldValue.id"
              >
                <component 
                  class="component-wrapper"
                  v-if="fieldValue && !fieldValue?.attributes.should_hide && blockAPI && fieldValue.relationships?.block.data.id === blockAPI.id && fieldValue.parent.attributes.type !== 'create-group-button'"
                  :is="myFieldComponents[fieldValue?.parent.attributes.type]"
                  :definitionAttributes="fieldValue?.parent.attributes"
                  :fieldValue="fieldValue"
                  :disabled="fieldValue?.attributes.is_read_only"
                  :solo="fieldValuesByBoxID.length === 1"
                  v-model:fieldSummary="fieldSummary[fieldValue.id]"
                  @changeValue="onChange"
                />          
              </template>
              <!-- Grouped fields -->
              <template
                v-for="(fieldValueGrouped,groupId) of getGroupedFieldsValuesByBox(box)" 
                :key="'group-'+groupId"
              >
                <FieldGroup v-model:deployed="groupsOpen[groupId]" :groupId="groupId">
                  <template #value>
                    <v-col class="pa-0">
                      <v-row v-for="fieldValue in fieldValueGrouped"
                        :key="'box-value-' +fieldValue.id"
                        :id="'box-value-' +fieldValue.id"
                        class="ma-0"
                      >
                        <template v-if="fieldValue?.parent.attributes.should_show_in_recap">
                          <v-col class="pa-0 fit-content token-text-regular token-text-color-secondary pb-2" v-if="fieldValueGrouped.length !== 1" cols="12">
                            {{ fieldValue?.parent.attributes.name }}
                          </v-col>
                          <v-col class="pa-0 mr-0 token-text-regular value-container" :class="fieldSummary[fieldValue.id] && fieldSummary[fieldValue.id].isURL ? 'ml-0' : 'ml-auto pl-2'">
                            <ListElement 
                              v-if="fieldSummary[fieldValue.id]"
                              :isURL="fieldSummary[fieldValue.id].isURL"
                              :iconRigth="fieldSummary[fieldValue.id].isURL ? 'share' : ''"
                              :logo="fieldSummary[fieldValue.id].logo"
                              :text="fieldSummary[fieldValue.id].text"
                              :badgeState="fieldSummary[fieldValue.id].badgeState"
                              :badgeLabel="fieldSummary[fieldValue.id].badgeLabel"
                              :badgeOutlined="fieldSummary[fieldValue.id].badgeOutlined"
                              :alignRight="!fieldSummary[fieldValue.id].isURL"
                              :noHover="true" 
                              :dense="true"
                            />
                            <template v-else>&nbsp;</template>
                          </v-col>
                        </template>
                      </v-row>
                    </v-col>
                  </template>
                  <template #deployed>
                    <template
                      v-for="fieldValue of fieldValueGrouped" 
                      :key="'fv-'+fieldValue.id"
                    >
                      <component 
                        class="component-wrapper"
                        v-if="fieldValue && !fieldValue?.attributes.should_hide && blockAPI && fieldValue.relationships?.block.data.id === blockAPI.id"
                        :is="myFieldComponents[fieldValue?.parent.attributes.type]"
                        :definitionAttributes="fieldValue?.parent.attributes"
                        :fieldValue="fieldValue"
                        :disabled="fieldValue?.attributes.is_read_only"
                        :solo="fieldValuesByBoxID.length === 1"
                        v-model:fieldSummary="fieldSummary[fieldValue.id]"
                        v-model:groupSummary="groupSummary[groupId]"
                        @changeValue="onChange"
                      />          
                    </template>
                  </template>
                </FieldGroup>
              </template>
              <template v-for="createButtonFieldValue in [getCreateButtonFieldValueByBox(box)]" :key="createButtonFieldValue?.id">
                  <component 
                    class="component-wrapper"
                    v-if="createButtonFieldValue && !createButtonFieldValue?.attributes.should_hide && blockAPI && createButtonFieldValue.relationships?.block.data.id === blockAPI.id "
                    :is="myFieldComponents[createButtonFieldValue?.parent.attributes.type]"
                    :definitionAttributes="createButtonFieldValue?.parent.attributes"
                    :fieldValue="createButtonFieldValue"
                    :disabled="createButtonFieldValue?.attributes.is_read_only"
                    :solo="fieldValuesByBoxID.length === 1"
                    v-model:fieldSummary="fieldSummary[createButtonFieldValue.id]"
                    @changeValue="onChange"
                  />   
              </template>
            </div>
          </template>
        </FieldBox>
      </template>
    </template>   
  </div>
</template>

<script lang="ts">
import { Vue, Options, prop } from 'vue-class-component'
import { Block, ElementNode } from '@/types'
import { APIBlock, APIFieldDefinition, APIFieldValue, APITabField, APIFieldBox, APIFieldGroup } from '@/typesAPI'
import { defineAsyncComponent } from "vue";
import ErrorLoad from "@/components/Details/Fields/ErrorLoad.vue";
import FieldBox from "@/components/Details/FieldBox.vue";
import FieldGroup from "@/components/Details/FieldGroup.vue";
import { ListItem } from '@/components/UIElements/List.vue';
import ListElement, {Props as ListElementProps} from '@/components/UIElements/ListElement.vue';
import API from "@/api/wrapper"
import { Watch } from 'vue-property-decorator';

class Props {
  block: Block = prop({
    required: true,
  });
  node: ElementNode = prop({
    required: true,
  });
  currentTab: ListItem = prop({
    required: true,
  });
  isOnlyOneTab: boolean = prop({
    required: true,
  });
}

type FieldSummaryElement <T> = Partial<T> & { noTitle: boolean }
type GroupSummaryElement <T> = Partial<T> & { title: boolean }

@Options({
  components: {
    ErrorLoad,
    FieldBox,
    ListElement,
    FieldGroup
  },
})
export default class NodeDetailContent extends Vue.with(Props) {

  boxesOpen:{[key:string]: boolean } = {}
  groupsOpen:{[key:string]: boolean } = {}

  
  fieldSummary:{[key:string]: FieldSummaryElement<ListElementProps> } = {}
  groupSummary:{[key:string]: GroupSummaryElement<ListElementProps> } = {}

    
  get displayedBoxes(): APIFieldBox[] {
    const ret: APIFieldBox[] = []

    if(this.currentTab && this.blockAPI) {
      Object.values(this.tree.blocks[this.blockAPI.id].fieldValues).forEach((fieldValue:any) => {
        if(fieldValue?.parent.parent.parent.id === this.currentTab.id) {
          if(!ret.includes(fieldValue?.parent?.parent)) {
            ret.push(fieldValue?.parent?.parent)
          }
        }
      })
      ret.sort((boxA:APIFieldBox, boxB:APIFieldBox) => {
        return boxA.attributes.priority - boxB.attributes.priority 
      })
    }
    return ret

  }

  get blockAPI():APIBlock | undefined {
    return this.$store.getters['blocksAPI/getByID'](this.node.extra.blockAPIID)
  }

  // Get list from fields loaded in the store
  get fieldsDefinitions(): APIFieldDefinition[] {
    const ret: APIFieldDefinition[] = []

    if(this.currentTab.id) {
      const tab = this.tree.tabs[this.currentTab.id]
      Object.values(tab.boxes).forEach((box:any) => {
        ret.push(...Object.values(box.fieldDefinitions) as APIFieldDefinition[])
      })
    }
    return ret
  }

  // Dynamicaly load components from components/Details/Fields
  get myFieldComponents():{[key:string]:any} {
    const ret:{[key:string]:any} = {}

    this.fieldsDefinitions.forEach((field:APIFieldDefinition) => {
      if(field) {

        const componentName = this.getComponentFromAttributesType(field.attributes.type)
        ret[field.attributes.type] = defineAsyncComponent({
          // the loader function
          loader: () => import(`@/components/Details/Fields/${componentName}.vue`),
          // A component to use while the async component is loading
          // loadingComponent: LoadingComponent,
          // Delay before showing the loading component. Default: 200ms.
          // delay: 200,
          // A component to use if the load fails
          errorComponent: ErrorLoad,
          // The error component will be displayed if a timeout is
          // provided and exceeded. Default: Infinity.
          timeout: 3000 ,
          suspensible : false,
          onError(error, retry, fail, attempts) {
            if (error.message.match(/fetch/) && attempts <= 3) {
              retry();
            } else {
              fail();
            }
          }
        })
      }
    })
    return ret
  }

  mounted(): void {
   
    this.displayedBoxes.forEach((box:APIFieldBox) => {
      this.boxesOpen[box.id] = false
      if((this.$route.params.boxId && this.$route.params.boxId === box.id) || !box.attributes?.isCollapsible) {
        this.boxesOpen[box.id] = true
      }
    })
    
  }

  @Watch('$route.params.blockId')
  onBlockIdChange() {
    // Open box when there is only one box and only one tab
    if(this.$route.name === "flowBlock" && this.$route.params.blockId && this.displayedBoxes.length === 1 && this.isOnlyOneTab) {
      this.$router.replace({
        name: 'flowBlockAndBox',
        params: {
          ...this.$route.params,
          boxId: this.displayedBoxes[0].id
        }
      });
    }
  }

  get fieldsValues():APIFieldValue[] {
    return this.$store.getters['fields/getListValues']
  }

  get tree():any {
    return this.$store.getters['fields/getTree']
  }

  getGroupedFieldsValuesByBox (box:APIFieldBox):{[key:string]:APIFieldValue[]} {
    const ret:{[key:string]:APIFieldValue[]} = {}

    for(const fieldDefinition of this.tree.boxes[box.id].childsByPriority) {
      const fieldValues: APIFieldValue[] =  Object.values(this.tree.boxes[box.id].fieldDefinitions[fieldDefinition.id].fieldValues)
      for(const fieldValue of fieldValues) {
        if(fieldValue.relationships.group.data && fieldValue.relationships.block.data.id === this.blockAPI?.id ) {
          if(!ret[fieldValue.relationships.group.data?.id]) {
            ret[fieldValue.relationships.group.data?.id] = [fieldValue]
          } else {
            if(!ret[fieldValue.relationships.group.data?.id].includes(fieldValue)) {
              ret[fieldValue.relationships.group.data?.id].push(fieldValue)
            }
          }
        }
      }
    }
    
    return ret
  }

  get listGroup():APIFieldGroup[] {
    return this.$store.getters['fields/getListGroups']
  }

  getCreateButtonFieldValueByBox (box:APIFieldBox):APIFieldValue | undefined {
    const values = this.getNotGroupedFieldsValuesByBox(box)
    return values.find((v:any) => v.parent.attributes.type === 'create-group-button')
  }


  getNotGroupedFieldsValuesByBox (box:APIFieldBox):APIFieldValue[] {
    const ret:APIFieldValue[] = []
    for(const fieldDefinition of this.tree.boxes[box.id].childsByPriority) {
      const fieldValues: APIFieldValue[] =  Object.values(this.tree.boxes[box.id].fieldDefinitions[fieldDefinition.id].fieldValues)
      for(const fieldValue of fieldValues) {
        if(!fieldValue.relationships.group.data) {
          if(!ret.includes(fieldValue)) {
            ret.push(fieldValue)
          }
        }
      }
    }
    return ret
  }

  getBoxDisabledState (box:APIFieldBox):boolean {
    let ret = true
    for(const fieldDefinition of this.tree.boxes[box.id].childsByPriority) {
      const fieldValues: APIFieldValue[] =  Object.values(this.tree.boxes[box.id].fieldDefinitions[fieldDefinition.id].fieldValues)
      for(const fieldValue of fieldValues) {
        if(!fieldValue.attributes.is_read_only || fieldDefinition.attributes.type === "create-group-button") {
          ret = false
        }
      }
    }
    return ret
  }

  onChange(key:string, value:string, idFieldValue:string) {

    this.$emit('save:start')
    API.blocks.editFieldValue(idFieldValue, key, value)
    .then((result) => {
      result.blocks.forEach((block) => {
        this.$store.dispatch('blocksAPI/editBlock', block)
      })

      result.fieldsDefinition.forEach((val:APIFieldDefinition) => {
        this.$store.dispatch('fields/addNodeFieldDefinition',  val)
      })
      result.fieldsTabs.forEach((val:APITabField) => {
        this.$store.dispatch('fields/addNodeFieldTab',  val)
      })
      result.fieldsBoxes.forEach((val:APIFieldBox) => {
        this.$store.dispatch('fields/addNodeFieldBox',  val)
      })
      result.fieldsValue.forEach((val:APIFieldValue) => {
        this.$store.dispatch('fields/addNodeFieldValue',  val)
      })
      this.$store.dispatch('fields/addNodeFieldValue',  result.newFieldValue)



      this.$nextTick(() => {
        const tree = this.$store.getters['fields/getTree']
        const fv = tree.fieldValues[idFieldValue]
        const triggerBox = fv?.parent?.parent
        this.$emitter.emit('boxListUpdateTriggerByBox', triggerBox)
      })

    })
    .finally(() => {
      this.$emit('save:success')
    })
  }

  getFieldValueByID(id:string): APIFieldValue {
    return this.$store.getters['fields/getValueByID'](id) as APIFieldValue
  }

  getBoxByFieldID(id:string): APIFieldBox {
    const field: APIFieldDefinition = this.$store.getters['fields/getDefinitionsByID'](id) as APIFieldDefinition
    const box : APIFieldBox = this.$store.getters['fields/getBoxByID'](field?.relationships?.box.data.id) as APIFieldBox
    return box
  }
  getFieldDefinitionsByBoxID(id:string, exludeNotInSummary?:boolean): APIFieldDefinition[] {
    const ret:APIFieldDefinition[] = []
    const box = this.tree.boxes[id]
    if(box) {
      Object.values(box.fieldDefinitions).forEach((fieldDefinition:any) => {
        if(exludeNotInSummary) {
          if(fieldDefinition.attributes.should_show_in_recap) {
            ret.push(fieldDefinition as APIFieldDefinition)
          }
        } else {
          ret.push(fieldDefinition as APIFieldDefinition)
        }
      })
    }
    
    return ret 
  }
  getFieldValuesByBoxID(id:string, exludeNotInSummary?:boolean): APIFieldValue[] {

    const ret:APIFieldValue[] = []

    const fieldDefinitions:APIFieldDefinition[] = this.getFieldDefinitionsByBoxID(id, exludeNotInSummary)
    fieldDefinitions.forEach((fieldDefinition:any) => {
      if(fieldDefinition.fieldValues) {
        Object.values(fieldDefinition.fieldValues).forEach((fieldValue:any) => {
          if(this.blockAPI && fieldValue.relationships?.block.data.id === this.blockAPI.id) {
            ret.push(fieldValue)
          }
        })
      }
    })

    ret.sort((valA:APIFieldValue, valB:APIFieldValue) => {
      return valA?.parent.attributes.priority - valB?.parent.attributes.priority 
    })

    return ret
  }


  

  getTabByFieldID(id:string): APITabField {
    const box : APIFieldBox = this.getBoxByFieldID(id) as APIFieldBox
    const tab : APITabField = this.$store.getters['fields/getTabByID'](box?.relationships?.tab.data.id) as APITabField
    return tab
  }

  getFieldsDefinitionsByIDValue(id:string): APIFieldDefinition {
    return this.$store.getters['fields/getDefinitionsByIDValue'](id) as APIFieldDefinition
  }

  getFieldsValuesIDDefinition(id:string): APIFieldValue[] {
    return this.$store.getters['fields/getValuesByIDDefinition'](id) as APIFieldValue[]
  }

  // Transform a type to a component name 'my-type-of-field' => 'MyTypeOfField'
  getComponentFromAttributesType(type:string) {
    return 'DetailField' + this.$helpers.components.getNameFromAttributesType(type)
  }

 
  
}
</script>

<style lang="scss" scoped>
.block-detail-content-initiated {
  width: 100%;
  .component-wrapper:not(:last-of-type) {
    margin-bottom: 16px;
  }
}

.value-container {
  white-space: nowrap;
  overflow: hidden;
}

</style>
