<template>
  <div>
    <Modal :visible="visible" :loading="loading" @clickExternal="onClose" :maxHeightContent="'calc(100vh - 315px)'">
      <template #header>
        <v-row class="ma-n2">
          <CustomTextInput v-model="name" class="mr-0" style="width: calc(100% - 32px);" :plain="true" :max="30"/>
          <CustomButton class="ml-auto mr-2 my-auto " :level="$enums.ButtonLevels.SmallIcon" icon="x" @click="onClose"/>
        </v-row>

      </template>

      <div class="modal-cluster-config-content">

        <!-- Alert disable in trial -->
        <template v-if="isTrialing">
          <v-row class="ma-4 pa-4 alert-trial">
            <v-col class="pa-0">
              <v-row class="ma-0 token-text-medium">
                Cluster are not editable with the free plan
              </v-row>
              <v-row class="ma-0 token-text-regular">
                Upgrade your plan to edit the cluster or enable auto scaling
              </v-row>
              <v-row class="ma-0 mt-4">
                <CustomButton :level="$enums.ButtonLevels.Primary" class="full-width" @click="upgradePlan" :loading="upgradePlanLoading">
                  Upgrade plan
                </CustomButton>
              </v-row>
            </v-col>
          </v-row>
        </template>

        <!-- Auto / Manual scaling -->
        <!-- <v-row class="ma-4 mb-2">
          <SegmentedControl v-model="scallingType" :tabs="{'0' : 'Auto scalling', '1' : 'Manual'}" @update:modelValue="scallingType = $event" :disabled="isTrialing"/>
        </v-row> -->


        <!-- Provider -->
        <v-row class="ma-0 ml-4 mt-2 mb-2 token-text-h5">
          Provider
        </v-row>
        <v-row class="ma-0 mx-4 mb-6">
          <CustomSelect placeholder="Select a value" :items="providerListItems" v-model="providerActualValue" @update:modelValue="onSelectProvider" :disabled="clusterDeployment && clusterDeployment?.attributes?.status !== $enums.DeploymentStatus.OFFLINE"/>
        </v-row>

        <!-- Region -->
        <v-row class="ma-0 ml-4 mt-2 mb-2 token-text-h5">
          Region
        </v-row>
        <v-row class="ma-0 mx-4 mb-6">
          <CustomSelect placeholder="Select a value" :items="regionsListItems" v-model="regionsActualValue" @update:modelValue="onSelectRegion" :disabled="clusterDeployment && clusterDeployment?.attributes?.status !== $enums.DeploymentStatus.OFFLINE"/>
        </v-row>

        <!-- Nb Nodes -->
        <v-row class="ma-0 ml-4 mb-2 token-text-h5">
            <div>
              Worker nodes
            </div>
            <template v-if="minMasterNodes.length">
              <div class="ml-2 token-text-color-secondary token-text-regular my-auto">
                + {{ minMasterNodes.length }} control node(s)
              </div>
              <Tooltip class="ml-2 my-auto" docLink="guides/cluster-configuration/#defining-the-number-of-nodes">
                <template #header>
                  Control nodes are added
                </template>
                <template #content>
                  Starting at 2 nodes, we add dedicated nodes to manage the workload on your cluster.<br/>
                  They are added to improve reliability and stability and while fully managed by Fransys, they increase the overall cost of your cluster.
                </template>
              </Tooltip>
            </template>
          </v-row>
          <v-row class="ma-0 mx-4">
            <Slider :value="nbNodesActualRange[0]" :step="1" :min="1" :max="maxNbNodes" @updateValue:value="nbNodesActualRange[0] = $event; nbNodesActualRange[1] = $event" :disabled="isTrialing"/>
          </v-row>


        <!-- Auto Scalling -->
        <template v-if="isAutoScalling">
          <!-- Worker nodes typology -->
          <v-row class="ma-0 ml-4 mb-6 token-text-h5">
            Worker nodes typology
          </v-row>
          <v-row class="ma-0 mx-4">
            <v-col class="pa-2 custom-thumb" v-if="clusterNodeTypologies[nodesPerfActualRange[0]]">
              <v-row class="ma-0">
                {{ clusterNodeTypologies[nodesPerfActualRange[0]].attributes.name }}
              </v-row>
              <v-row class="ma-0 token-text-color-secondary">
                {{ clusterNodeTypologies[nodesPerfActualRange[0]].attributes.cores }} CPUs
              </v-row>
              <v-row class="ma-0 token-text-color-secondary">
                {{ clusterNodeTypologies[nodesPerfActualRange[0]].attributes.memory }} RAM
              </v-row>
              <v-row class="ma-0 token-text-color-secondary" v-if="regionsActualValue?.value">
                {{ $helpers.prices.format(parseInt(clusterNodeTypologies[nodesPerfActualRange[0]].attributes.price.amount), clusterNodeTypologies[nodesPerfActualRange[0]].attributes.price.currency) }}
              </v-row>
            </v-col>
            <v-col class="pa-0"></v-col>
            <v-col class="pa-2 custom-thumb" v-if="clusterNodeTypologies[nodesPerfActualRange[0]]">
              <v-row class="ma-0">
                {{ clusterNodeTypologies[nodesPerfActualRange[1]].attributes.name }}
              </v-row>
              <v-row class="ma-0 token-text-color-secondary">
                {{ clusterNodeTypologies[nodesPerfActualRange[1]].attributes.cores }} CPUs
              </v-row>
              <v-row class="ma-0 token-text-color-secondary">
                {{ clusterNodeTypologies[nodesPerfActualRange[1]].attributes.memory }} RAM
              </v-row>
              <v-row class="ma-0 token-text-color-secondary" v-if="regionsActualValue?.value">
                {{ $helpers.prices.format(parseInt(clusterNodeTypologies[nodesPerfActualRange[1]].attributes.price.amount), clusterNodeTypologies[nodesPerfActualRange[1]].attributes.price.currency) }}
              </v-row>
            </v-col>
          </v-row>
          <v-row class="ma-0 mx-4">
            <Slider :values="nodesPerfActualRange" :step="1" :min="0" :max="clusterNodeTypologies.length - 1" :hideThumbLabels="true" @updateValue:values="nodesPerfActualRange = $event" :range="true" :disabled="isTrialing"/>
          </v-row>
        </template>

        <!-- Manual -->
        <template v-else>
          <!-- Worker nodes typology -->
          <v-row class="ma-0 ml-4 mb-2 token-text-h5">
            Worker nodes typology
          </v-row>
          <v-row class="ma-0 ml-4 mb-2">
            <v-col class="pa-0 mr-2 fit-content" v-for="tag of tagList" :key="'tag-'+tag">
              <Badge :label="tag" :severity="$enums.Severity.NEUTRAL" :outlined="!selectedTags.includes(tag)" @click="onClickTag(tag)" class="tag"/>
            </v-col>
          </v-row>
          <v-row class="ma-0 mx-4 mb-2">
            <v-col class="pa-0" v-for="(typology, index) of clusterNodeTypologies" :key="'typo-' + index" cols="6" >
              <div class="card-typo" :class="(index%2 === 0 ? ' mr-2 ' : ' ml-2 ') + (nodesPerfActualRange[0] === index ? ' selected ' : '') + (isTypoDisabled(typology) ? ' disabled ' : '')" @click="onClickTypoCard(typology, index)" v-if="!isTypoAbsent(typology)">
                <v-row class="ma-0 mb-2">
                  <v-col class="pa-0 fit-content">
                    {{ typology.attributes.name }}
                  </v-col>
                  <v-col class="pa-0 ml-auto fit-content">
                    <PriceDisplay
                      :price="currentLocation && typology.attributes.price ? typology.attributes.price.amount : null"
                      :currency="customer?.attributes.currency"
                      :hidePeriodicity="true"
                    />
                  </v-col>
                </v-row>
                <v-row class="ma-0 token-text-color-secondary">
                  {{ typology.attributes.cores }} {{ typology.attributes.dedicated_cpu ? ' dedicated ' : ' shared ' }} CPUs
                </v-row>
                <v-row class="ma-0 token-text-color-secondary">
                  {{ typology.attributes.memory }} RAM
                </v-row>
                <v-row class="ma-0 token-text-color-secondary">
                  <template v-if="typology.attributes.cpu_family">
                    {{ typology.attributes.cpu_family }}
                  </template>
                  <template v-else>
                    &nbsp;
                  </template>
                </v-row>
                <v-row class="ma-0 mt-1">
                  <v-col class="pa-0 mr-2 fit-content" v-for="tag of typology.attributes.tags" :key="typology?.id+'-tag-'+tag">
                    <Badge :label="tag" :severity="$enums.Severity.NEUTRAL" :outlined="!selectedTags.includes(tag)" @click="onClickTag(tag)" class="tag"/>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </template>
      </div>
      

      <template #footer>
      <v-col class="pa-0">
        <!-- Prices -->
        <v-row class="ma-0 ml-4 my-2 token-text-h5">
          Price details
        </v-row>
        <v-row class="ma-0 mx-4 token-text-regular">
          <v-col class="pa-0">
            <v-row class="ma-0">
              <v-col class="pa-0 my-auto fit-content">
                Worker nodes
              </v-col>
              <v-col class="pa-0 my-auto mx-2">
                <Divider/>
              </v-col>
              <v-col class="pa-0 ml-auto fit-content">
                <PriceDisplay 
                  :price="clusterNodeTypologies.length ? minPriceWorkerNodes : null"
                  :price2="maxPriceWorkerNodes" 
                  :currency="customer?.attributes.currency"
                  :disableBurger="true"
                />
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="pa-0 my-auto fit-content">
                <v-row class="ma-0">
                  <div>Control nodes</div>
                  <Tooltip class="ml-2" docLink="guides/cluster-configuration/#defining-the-number-of-nodes">
                    <template #header>
                      Control nodes are added
                    </template>
                    <template #content>
                      Starting at 2 nodes, we add dedicated nodes to manage the workload on your cluster.<br/>
                      They are added to improve reliability and stability and while fully managed by Fransys, they increase the overall cost of your cluster.
                    </template>
                  </Tooltip>
                </v-row>
              </v-col>
              <v-col class="pa-0 my-auto mx-2">
                <Divider/>
              </v-col>
              <v-col class="pa-0 ml-auto fit-content">
                <PriceDisplay 
                  :price="providerRequirements.length ? minPriceMasterNodes : null"
                  :price2="maxPriceMasterNodes" 
                  :currency="customer?.attributes.currency"
                  :disableBurger="true"
                />
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="pa-0 my-auto fit-content">
                <v-row class="ma-0">
                  <div>Load balancer</div>
                  <Tooltip class="ml-2">
                    <template #header>
                      A load balancer is added
                    </template>
                    <template #content>
                      Starting at 2 nodes, we add a load balancer in front of your cluster.<br/>
                      It is fully managed by Fransys and is here to better balance your traffic and improve reliability.
                    </template>
                  </Tooltip>
                </v-row>
              </v-col>
              <v-col class="pa-0 my-auto mx-2">
                <Divider/>
              </v-col>
              <v-col class="pa-0 ml-auto fit-content">
                <PriceDisplay 
                  :price="providerRequirements.length ? minPriceLoadBalancer : null" 
                  :price2="maxPriceLoadBalancer" 
                  :currency="customer?.attributes.currency"
                  :disableBurger="true"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      
        <v-row class="pa-4 ma-0 flex">
          <v-col class="pa-0 my-auto fit-content">
            <v-row class="ma-0 token-text-medium token-text-color-primary">
              <PriceDisplay 
                :price="totalPriceMin" 
                :price2="totalPriceMax" 
                :currency="customer?.attributes.currency"
              />
            </v-row>
          </v-col>
          <v-col class="pa-0 fit-content ml-auto">
            <v-row class="ma-0">
              <CustomButton :level="$enums.ButtonLevels.Tertiary" text="Cancel" class="mr-2" @click="cancel" />
              <CustomButton :level="$enums.ButtonLevels.Secondary" :text="textAccept" class="mr-2" @click="accept(false)" :status="buttonStateSave" :loading="saving" :disabled="disableBtnEdit" />
              <CustomButton :level="$enums.ButtonLevels.Primary" :text="'Deploy cluster'" :icon="'publish'" @click="accept(true)" :status="buttonStatePublish" :loading="saving" :disabled="disableBtnPublish" />
            </v-row>
          </v-col>

        </v-row>
      </v-col>
      </template>
    </Modal>
  </div>
</template>

<script lang="ts">
import { Vue, Options, prop } from 'vue-class-component'
import Modal from '@/components/UIElements/Modal.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import Slider from '@/components/UIElements/Slider.vue'
import SegmentedControl from '@/components/UIElements/SegmentedControl.vue'
import API from '@/api/wrapper'
import CustomSelect from '@/components/UIElements/CustomSelect.vue'
import PriceDisplay from '@/components/UIElements/PriceDisplay.vue'
import Icon from '@/components/UIElements/Icon.vue'
import { ListItem } from '@/components/UIElements/List.vue';
import { APICluster, APIClusterNodeTypologie, APIPriceRanges, APICustomer, APIClusterRequirement, APIClusterLocation, APILoadBalancerTypologie, APIDeployment } from '@/typesAPI'
import { Watch } from 'vue-property-decorator'
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue'
import Tooltip from '@/components/UIElements/Tooltip.vue';
import Divider from '@/components/UIElements/Divider.vue';
import Badge from '@/components/UIElements/Badge.vue'


class Props {
  cluster: APICluster | null = prop({
    required: true,
  });
  creating?: boolean = prop({
    required: false,
  });
}

@Options({
  components: {
    Modal,
    CustomButton,
    Slider,
    SegmentedControl,
    CustomSelect,
    Icon,
    CustomTextInput,
    PriceDisplay,
    Tooltip,
    Divider,
    Badge
  },
})
export default class ModalClusterConfig extends Vue.with(Props) {
  regionsActualValue?:ListItem = {}
  providerActualValue?:ListItem = {}

  name = "New cluster"
  loading = false
  saving = false
  buttonStateSave = ""
  buttonStatePublish = ""
  defaultText = "Save config"
  textAccept = this.defaultText
  scallingType = '1'
  maxNbNodes = 20
  nbNodesActualRange = [0,0]
  nodesPerfActualRange = [0,0]
  upgradePlanLoading = false
  disableBtnEdit = false
  disableBtnPublish = false
  closeTimeout:number | undefined = undefined
  successCloseDelay = 1000
  inited = false
  defaultProvider = 'hetzner'
  provider = this.defaultProvider
  timerRefreshLocalisations = 5 * 60 * 1000 //5min
  intervalRefreshLocations = 0
  selectedTags:string[] = []
  providerListItems = [
    {
      text:'Hetzner',
      value:'hetzner',
      logo: 'hetzner'
    },
    {
      text:'Scaleway',
      value:'scaleway',
      logo: 'scaleway'
    }
  ]

  @Watch('creating', {immediate: true})
  onCreateChange() {
    this.defaultText = "Save config"
    this.textAccept = this.defaultText
  }

  onClickTypoCard(typology:APIClusterNodeTypologie, index:number) {
    if(!this.isTypoAbsent(typology) && !this.isTypoDisabled(typology)) {
      this.nodesPerfActualRange[0] = index
      this.nodesPerfActualRange[1] = index
    }
  }

  onClickTag(tag:string) {
    const index = this.selectedTags.indexOf(tag);
    if (index > -1) {
      this.selectedTags.splice(index, 1);
    } else {
      this.selectedTags.push(tag)
      this.selectedTags = [...new Set(this.selectedTags)]
    }

  }

  mounted(): void {
    this.$store.dispatch('clusters/refreshLocations', this.provider)
  }

  @Watch('visible', {immediate: true})
  onVisibleChange() {
    if(this.visible) {
      this.selectedTags = [...this.tagList]
      clearInterval(this.intervalRefreshLocations)
      this.intervalRefreshLocations = setInterval(() => {
        this.$store.dispatch('clusters/refreshLocations', this.provider)
      }, this.timerRefreshLocalisations)
    } else {
      clearInterval(this.intervalRefreshLocations)
    }
  }

  unmounted(): void {
    clearInterval(this.intervalRefreshLocations)
  }

  @Watch('locations')
  onLocationsChange() {
    if(this.locations.length) {
      if(!this.currentLocation && this.regionsListItems.length) {
        this.regionsActualValue = this.regionsListItems[0]
      }
    }
  }

  init(): void {
    this.nbNodesActualRange = [3,6] //Default sliders value
    this.nodesPerfActualRange = [0,1] //Default sliders value
    this.regionsActualValue = this.regionsListItems[0]    
    this.providerActualValue = this.providerListItems[0]    
    this.inited = true
  }

  get regionsListItems ():ListItem[] {
    const ret:ListItem[] = []



    this.locations.forEach((location:APIClusterLocation) => {
      const newItem:ListItem = {
        text: this.$helpers.providers.locationToDisplayName(location),
        value: location.id,
        flag: location.attributes.country,
      }
      if(location === this.currentLocation) {
        newItem.selected = true
      }
      ret.push(newItem)
    })

    return ret.sort((a,b) => {
      if(a.text && b.text) {
        return a.text.localeCompare(b.text)
      }
      return 0
    })
  }

  get currentLocation ():APIClusterLocation | undefined {
    return this.locations.find((location) => location.id === this.regionsActualValue?.value)
  }

  upgradePlan() {
    this.$helpers.subscription.checkout();
    this.upgradePlanLoading = true
  }

  isTypoAbsent(typology:APIClusterNodeTypologie) {
    const isTaged = this.selectedTags.some((tag:string) => {
      if(typology?.attributes.tags.includes(tag)) {
        return true
      }
      return false
    })
    return !isTaged || !(this.regionsActualValue?.value && typology?.attributes.price)
  }
  isTypoDisabled(typology:APIClusterNodeTypologie) {
    if(this.regionsActualValue?.value && this.currentLocation) {
      if(this.currentLocation.attributes.available_server_typologies.find((typoName) => typoName === typology.id)) {
        return false
      }
    }
    return true
  }
  onClose() {
    this.$store.dispatch('modals/setVisibility', {modal:this.$enums.ModalsName.Cluster, newState: false})

    // Reset modal state after fading out
    setTimeout(() => {
      this.onclusterChange(true)
    }, 300);
  }

  cancel () {
    this.onClose()
  }

  accept (publishNow:boolean) {
    this.$store.dispatch('clusters/refreshLocations', this.provider)
    if(this.cluster && this.regionsActualValue?.value) {
      this.saving = true

      const body = this.$helpers.ressources.getOnlyDifferentFrom(
        {
          name: this.name,
          deploy_now: publishNow ? true : false,
          region: this.regionsActualValue?.value,
          provider:this.provider,
          requested_config:{
            node_number_max: this.nbNodesActualRange[1],
            node_number_min: this.nbNodesActualRange[0],
            node_size_max:this.clusterNodeTypologies[this.nodesPerfActualRange[1]].id,
            node_size_min:this.clusterNodeTypologies[this.nodesPerfActualRange[0]].id,
          }, 
        },
        this.cluster.attributes)

      API.clusters.editCluster(this.cluster.id, body)
      .then((newCluster:APICluster) => {
        this.$store.dispatch('clusters/editCluster', newCluster)

        if(publishNow) {
          this.buttonStatePublish = this.$enums.ButtonStates.Success
          this.buttonStateSave = this.$enums.ButtonStates.Neutral
        } else {
          this.buttonStatePublish = this.$enums.ButtonStates.Neutral
          this.buttonStateSave = this.$enums.ButtonStates.Success
        }

        this.textAccept = "Cluster updated"
        this.disableBtnEdit = true
        this.disableBtnPublish = true
        this.closeTimeout = setTimeout(() => {
          this.onClose()
        }, this.successCloseDelay);
      })
      .finally(() => {
        this.saving = false
      })
    } else if(this.creating && this.customer && this.regionsActualValue?.value) {
      this.saving = true
      API.clusters.create(this.customer.id, 
        {
          name: this.name,
          deploy_now: publishNow ? true : false,
          region: this.regionsActualValue?.value,
          provider:this.provider,
          requested_config:{
            node_number_max: this.nbNodesActualRange[1],
            node_number_min: this.nbNodesActualRange[0],
            node_size_max:this.clusterNodeTypologies[this.nodesPerfActualRange[1]].id,
            node_size_min:this.clusterNodeTypologies[this.nodesPerfActualRange[0]].id,
          }
      })
      .then((newCluster:APICluster) => {
        this.$store.dispatch('clusters/editCluster', newCluster)

        if(publishNow) {
          this.buttonStatePublish = this.$enums.ButtonStates.Success
          this.buttonStateSave = this.$enums.ButtonStates.Neutral
        } else {
          this.buttonStatePublish = this.$enums.ButtonStates.Neutral
          this.buttonStateSave = this.$enums.ButtonStates.Success
        }

        this.textAccept = "Cluster created"
        this.disableBtnEdit = true
        this.disableBtnPublish = true
        this.closeTimeout = setTimeout(() => {
          this.onClose()
        }, this.successCloseDelay);
      })
      .finally(() => {
        this.saving = false
      })
    }
  }


  onSelectRegion(newVal:ListItem) {
    this.regionsActualValue = newVal
  }

  onSelectProvider(newVal:ListItem) {
    this.providerActualValue = newVal
    if(newVal.value) {
      this.provider = newVal.value
      this.$store.dispatch('clusters/refreshLocations', this.provider)
      this.regionsActualValue = {}
    }
  }

  get clusterDeployment():APIDeployment | undefined {
    return this.$store.getters['clusters/getDeployments']?.find((deployment:APIDeployment) => {
      return deployment.relationships.subject.data.id === this.cluster?.id
    })
  }

  get tagList():string[] {
    const ret:string[] = []
    this.clusterNodeTypologies?.forEach((clusterNodeTypologie:APIClusterNodeTypologie) => {
      ret.push(...clusterNodeTypologie.attributes.tags)
    })
    return [...new Set(ret)]
  }

  get clusters():APICluster[] {
    return this.$store.getters['clusters/getClusters']
  }

  get customer():APICustomer {
    return this.$store.getters['user/getCustomer']
  }

  get visible():boolean {
    return this.$store.getters['modals/getVisibilityCluster']
  }

  get isAutoScalling():boolean {
    return this.scallingType === '0'
  }

  get clusterNodeTypologies():APIClusterNodeTypologie[] {
    if(!this.provider) {
      return []
    }
    return this.$store.getters['clusters/getClusterNodeTypologiesByProviderAndLocation'](this.provider, this.regionsActualValue?.value)
  }

  get loadBalancerTypologies():APILoadBalancerTypologie[] {
    if(!this.provider) {
      return []
    }
    return this.$store.getters['clusters/getLoadBalancerTypologiesByProviderAndLocation'](this.provider, this.regionsActualValue?.value)
  }

  get minPriceLoadBalancer():number {
    let ret = 0
    if(this.minLoadBalancer && this.regionsActualValue?.value) {
      ret = parseInt(this.minLoadBalancer.attributes.price?.amount)
    }
    return ret
  }

  get minPriceMasterNodes():number {
    let ret = 0
    if(this.clusterNodeTypologies[this.nodesPerfActualRange[0]] && this.regionsActualValue?.value) {
      ret = this.minMasterNodes.length ? this.minMasterNodes.length * parseInt(this.minMasterNodes[0].attributes.price?.amount) : 0
    }
    return ret
  }

  get minPriceWorkerNodes():number {
    let ret = 0
    if(this.clusterNodeTypologies[this.nodesPerfActualRange[0]] && this.regionsActualValue?.value) {
      ret = this.nbNodesActualRange[0] * parseInt(this.clusterNodeTypologies[this.nodesPerfActualRange[0]].attributes.price?.amount)
    }
    return ret
  }

  get maxPriceLoadBalancer():number {
    if(!this.isAutoScalling) {
      return this.minPriceLoadBalancer
    }
    let ret = 0
    if(this.maxLoadBalancer && this.regionsActualValue?.value) {
      ret = parseInt(this.maxLoadBalancer.attributes.price?.amount)
    }
    return ret
  }

  get maxPriceMasterNodes():number {
    if(!this.isAutoScalling) {
      return this.minPriceMasterNodes
    }
    let ret = 0
    if(this.clusterNodeTypologies[this.nodesPerfActualRange[1]] && this.regionsActualValue?.value) {
      ret = this.maxMasterNodes.length ? this.maxMasterNodes.length * parseInt(this.maxMasterNodes[0].attributes.price?.amount) : 0
    }
    return ret
  }

  get maxPriceWorkerNodes():number {
    if(!this.isAutoScalling) {
      return this.minPriceWorkerNodes
    }
    let ret = 0
    if(this.clusterNodeTypologies[this.nodesPerfActualRange[1]] && this.regionsActualValue?.value) {
      ret = this.nbNodesActualRange[1] * parseInt(this.clusterNodeTypologies[this.nodesPerfActualRange[1]].attributes.price?.amount)
    }
    return ret
  }

  get totalPriceMin():number {
    return this.minPriceWorkerNodes + this.minPriceMasterNodes + this.minPriceLoadBalancer
  }

  get totalPriceMax():number {
    return this.maxPriceWorkerNodes + this.maxPriceMasterNodes + this.maxPriceLoadBalancer
  }

  get subscriptionState ():string {
    return this.$store.getters['user/getSubscriptionState']
  }

  get isSubscribed () {
    return this.subscriptionState !== this.$enums.SubscriptionState.FRESH && this.subscriptionState !== this.$enums.SubscriptionState.TRIAL_EXPIRED && this.subscriptionState !== this.$enums.SubscriptionState.EXPIRED
  }

  get isTrialing () {
    return this.subscriptionState === this.$enums.SubscriptionState.TRIALING
  }

  get providerRequirements ():APIClusterRequirement[] {
    if(!this.currentLocation) {
      return []
    }
    return this.$store.getters['clusters/getClusterRequirementsByProviderAndLocation'](this.provider, this.currentLocation.id)
  }

  get locations ():APIClusterLocation[] {
    return this.$store.getters['clusters/getLocationsByProvider'](this.provider)
  }

  get minMasterNodes ():APIClusterNodeTypologie[] {
    return this.$helpers.clusters.getTypologiesFromRequirements(this.providerRequirements, this.clusterNodeTypologies, this.nbNodesActualRange[0], this.nbNodesActualRange[0]) as APIClusterNodeTypologie[]
  }

  get maxMasterNodes ():APIClusterNodeTypologie[] {
    return this.$helpers.clusters.getTypologiesFromRequirements(this.providerRequirements, this.clusterNodeTypologies, this.nbNodesActualRange[1], this.nbNodesActualRange[1]) as APIClusterNodeTypologie[]
  }

  get minLoadBalancer ():APILoadBalancerTypologie | null {
    const typoRequirement = this.$helpers.clusters.getTypologiesFromRequirements(this.providerRequirements, this.loadBalancerTypologies, this.nbNodesActualRange[0], this.nbNodesActualRange[0]) as APILoadBalancerTypologie[]
    return typoRequirement.length ? typoRequirement[0] : null
  }

  get maxLoadBalancer ():APILoadBalancerTypologie | null {
    const typoRequirement = this.$helpers.clusters.getTypologiesFromRequirements(this.providerRequirements, this.loadBalancerTypologies, this.nbNodesActualRange[1], this.nbNodesActualRange[1]) as APILoadBalancerTypologie[]
    return typoRequirement.length ? typoRequirement[0] : null
  }


  


  @Watch("cluster", {immediate:true})
  onclusterChange(resetProvider:boolean) {
      if(!this.inited) {
        this.init()
      }
      clearInterval(this.closeTimeout)
      this.buttonStatePublish = this.$enums.ButtonStates.Neutral
      this.buttonStateSave = this.$enums.ButtonStates.Neutral
      this.disableBtnEdit = false
      this.disableBtnPublish = false
      this.textAccept = this.defaultText

      if(this.cluster) {
        // if(this.cluster?.attributes.requested_config.node_number_min !== this.cluster?.attributes.requested_config.node_number_max) {
        //   this.scallingType = '0'
        // }
        this.nbNodesActualRange = [this.cluster?.attributes.requested_config.node_number_min, this.cluster?.attributes.requested_config.node_number_max]
        const indexPerfMin = this.clusterNodeTypologies.findIndex((typo:APIClusterNodeTypologie) => {
          return typo.id === this.cluster?.attributes.requested_config.node_size_min
        })
        const indexPerfMax = this.clusterNodeTypologies.findIndex((typo:APIClusterNodeTypologie) => {
          return typo.id === this.cluster?.attributes.requested_config.node_size_max
        })
        this.nodesPerfActualRange = [indexPerfMin, indexPerfMax]


        this.name = this.cluster?.attributes?.name

       

        if(resetProvider !== false) {
          this.provider = this.cluster?.attributes?.provider ? this.cluster?.attributes?.provider : this.defaultProvider
          this.providerActualValue = this.providerListItems.find((p:ListItem) => p.value === this.provider)

          this.regionsActualValue = this.regionsListItems[0]    
          if(this.cluster?.attributes.region) {
            this.regionsActualValue = this.regionsListItems.find((region:ListItem) => {
              return region.value === this.cluster?.attributes.region
            })
            if(!this.regionsActualValue) {
              this.regionsActualValue = this.regionsListItems[0] 
            }
          }
        }

      } else {
        this.name = "New cluster"
        this.nbNodesActualRange = [3,3] //Default sliders value, if autoscalling available => [3,6]
        this.nodesPerfActualRange = [1,1] //Default sliders value, if autoscalling available => [0,1]
        this.scallingType = "1"
        if(resetProvider !== false) {
          this.provider = this.defaultProvider
          this.providerActualValue = this.providerListItems.find((p:ListItem) => p.value === this.provider)
          this.regionsActualValue = this.regionsListItems[0]    
        }
      }
  }

  @Watch("clusterNodeTypologies", {immediate:true, deep:true})
  onTypologiesChange() {
    if(this.clusterNodeTypologies?.length) {
      this.onclusterChange(false)
    }
  }

  @Watch("regionsActualValue")
  onRegionChange() {
    if(this.isTypoAbsent(this.clusterNodeTypologies[this.nodesPerfActualRange[0]])) {
      this.nodesPerfActualRange = [0,0]
    }
  }

  @Watch("regionsListItems")
  onRegionsListItemsChange(newVal:APIClusterLocation[], oldVal:APIClusterLocation[]) {
    if(oldVal.length === 0 && newVal.length > 0) {
      this.regionsActualValue = newVal[0]
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

.custom-thumb {
  width: 128px;
  max-width: 128px;
  border:1px solid $color-neutral-grey-12;
  border-radius: 8px;
}
.card-typo {
  border:1px solid $color-neutral-grey-12;
  border-radius: 8px;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  padding: 12px;
  margin-bottom: 16px;
  cursor: pointer;
  &:hover {
    border: 1px solid $color-neutral-grey-24;
    box-shadow: none;
  }
  
  &.disabled {
    border:1px solid $color-neutral-grey-12;
    background: $color-neutral-grey-12;
    cursor: default !important;
  }
  &.selected {
    padding-top: 11px;
    padding-left: 11px;
    margin-bottom: 15px;
    border: 2px solid $color-brand;
    box-shadow: 0px 0px 0px 2px rgba(50, 25, 178, 0.24);
  }
}
.alert-trial {
  border: 1px solid $color-orange;
  color: $text-error;
  background: $bg-error;
  border-radius: 8px;
}
.master-nodes-info {
  background-color: $color-neutral-grey-8 !important;
  width: auto !important;

}

.tag {
  cursor: pointer;
}



</style>
