import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tooltip fit-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_Popover = _resolveComponent("Popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "iconContainer",
      ref: "iconContainer",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickShow && _ctx.onClickShow(...args))),
      onMouseenter: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onStartHoverButton && _ctx.onStartHoverButton(...args))),
      onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onEndHoverButton && _ctx.onEndHoverButton(...args)))
    }, [
      _renderSlot(_ctx.$slots, "target", {}, undefined, true),
      (!_ctx.$slots['target'])
        ? (_openBlock(), _createBlock(_component_CustomButton, {
            key: 0,
            class: "",
            level: _ctx.$enums.ButtonLevels.SmallIcon,
            icon: "question-circle"
          }, null, 8, ["level"]))
        : _createCommentVNode("", true)
    ], 544),
    (_ctx.target)
      ? (_openBlock(), _createBlock(_component_Popover, {
          key: 0,
          maxWidth: 512,
          visible: _ctx.show,
          "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ($event ? '' : _ctx.onClickHide())),
          target: _ctx.target,
          closeOnExternalClick: _ctx.clicked,
          isHovered: _ctx.hoverPopover,
          "onUpdate:isHovered": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.hoverPopover) = $event))
        }, _createSlots({ _: 2 }, [
          (!_ctx.hoverOnly || _ctx.$slots['header'])
            ? {
                name: "header",
                fn: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _renderSlot(_ctx.$slots, "header", {}, undefined, true)
                  ]),
                  (!_ctx.hoverOnly)
                    ? (_openBlock(), _createBlock(_component_CustomButton, {
                        key: 0,
                        class: "ml-auto mr-2 my-auto",
                        level: _ctx.$enums.ButtonLevels.SmallIcon,
                        icon: "x",
                        onClick: _ctx.onClickHide
                      }, null, 8, ["level", "onClick"]))
                    : _createCommentVNode("", true)
                ]),
                key: "0"
              }
            : undefined,
          (_ctx.$slots['content'] || _ctx.docLink)
            ? {
                name: "content",
                fn: _withCtx(() => [
                  _createVNode(_component_v_col, { class: "pa-0" }, {
                    default: _withCtx(() => [
                      (_ctx.$slots['content'])
                        ? (_openBlock(), _createBlock(_component_v_row, {
                            key: 0,
                            class: "ma-0"
                          }, {
                            default: _withCtx(() => [
                              _renderSlot(_ctx.$slots, "content", {}, undefined, true)
                            ]),
                            _: 3
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.docLink)
                        ? (_openBlock(), _createBlock(_component_v_row, {
                            key: 1,
                            class: "ma-0"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_CustomButton, {
                                class: "ml-auto mt-4",
                                level: _ctx.$enums.ButtonLevels.Tertiary,
                                onClick: _ctx.onDocLinkClick
                              }, {
                                default: _withCtx(() => [
                                  _cache[5] || (_cache[5] = _createTextVNode(" Learn more ")),
                                  _createVNode(_component_Icon, {
                                    icon: "share",
                                    class: "ml-2"
                                  })
                                ]),
                                _: 1
                              }, 8, ["level", "onClick"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 3
                  })
                ]),
                key: "1"
              }
            : undefined,
          (_ctx.$slots['footer'])
            ? {
                name: "footer",
                fn: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
                  ])
                ]),
                key: "2"
              }
            : undefined
        ]), 1032, ["visible", "target", "closeOnExternalClick", "isHovered"]))
      : _createCommentVNode("", true)
  ]))
}