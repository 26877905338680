<template>
  <div class="clustersView">
    <!-- Modal Rename -->
    <ModalRenameRessource :target="actionTarget" v-model:visible="showModalRename"/>
    <!-- Modal Edit -->
    <ModalClusterConfig :cluster="actionTarget" :creating="creating"/>
    <!-- Modal Put Offline -->
    <ModalClusterPutOffline :cluster="actionTarget" v-model:visible="showModalPutOffline" :projects="actionTarget ? projects[actionTarget.id] : []"/>
    <!-- Modal Delete -->
    <ModalClusterDelete :cluster="actionTarget" v-model:visible="showModalDelete" />

    <template v-if="clusters?.length === 0">
      <v-col class="pa-0 ">
        <v-row class="ma-0">
          <v-col class="pa-0 token-text-h4">
            Clusters
          </v-col>
        </v-row>
        <v-row class="ma-0 pt-4">
          <v-col class="pa-4 empty-box">
            <v-row class="ma-0 d-flex-row">
              <v-col class="pa-0 ma-auto fit-content fit-content-height d-flex-col">
                <v-row class="ma-0 mx-auto fit-content-height">
                  <v-img src="@/assets/illustrations/no_cluster.svg" height="56px" width="72px"/>
                </v-row>
                <v-row class="ma-0 token-text-h5 my-4 mx-auto fit-content-height">
                  No cluster
                </v-row>
                <v-row class="ma-0 mx-auto fit-content-height">
                  <CustomButton text="Create cluster" @click="createCluster"/>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </template>
    <template v-else>
      <v-col class="pa-0">
        <v-row class="ma-0 mb-4">
          <div class="token-text-h4">
            Clusters
          </div>
          <div class="ml-auto">
            <CustomButton icon="add" @click="createCluster">
              Create cluster
            </CustomButton>
          </div>
        </v-row>
        <v-row class="ma-0">
          <v-col class="pa-0 mr-4" >
            <v-row class="ma-0" v-for="(cluster, index) of clusters" :key="'col1-'+index">
              <template v-if="index%2 === 0">
                <ClusterDisplay 
                  class="mb-8" 
                  :cluster="cluster"
                  :projects="projects[cluster.id]"
                  :clustersStatus="clustersStatus[cluster.id]"
                  @clickMenu="onBurgerMenuItemClick"
                  @clickDisplay="onClusterClick"
                />
              </template>
            </v-row>
          </v-col>
          <v-col class="pa-0 ml-4">
            <v-row class="ma-0 " v-for="(cluster, index) of clusters" :key="'col2-'+index">
              <template v-if="index%2 !== 0">
                <ClusterDisplay
                  class="mb-8" 
                  :cluster="cluster"
                  :projects="projects[cluster.id]"
                  :clustersStatus="clustersStatus[cluster.id]"
                  @clickMenu="onBurgerMenuItemClick"
                  @clickDisplay="onClusterClick"
                />
              </template>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </template>
    
  </div>
</template>

<script lang="ts">
import Icon from '@/components/UIElements/Icon.vue';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import DropdownMenu from '@/components/UIElements/DropdownMenu.vue';
import Indicator from '@/components/UIElements/Indicator.vue';
import ClusterDisplay from '@/components/UIElements/ClusterDisplay.vue';
import ModalRenameRessource from '@/components/Modals/ModalRenameRessource.vue';
import ModalClusterConfig from '@/components/Modals/ModalClusterConfig.vue';
import ModalClusterPutOffline from '@/components/Modals/ModalClusterPutOffline.vue';
import ModalClusterDelete from '@/components/Modals/ModalClusterDelete.vue';

import { APICluster, APICustomer, APIDeployment, APIProject } from '@/typesAPI';
import { Vue, Options } from 'vue-class-component';
import Logo from '@/components/UIElements/Logo.vue';
import { ListItem } from '@/components/UIElements/List.vue';

@Options({
  components: {
    CustomButton,
    DropdownMenu,
    Icon,
    Indicator,
    ModalRenameRessource,
    Logo,
    ModalClusterConfig,
    ModalClusterPutOffline,
    ModalClusterDelete,
    ClusterDisplay
  },
})
export default class ClustersView extends Vue {

  loadingProjects = false
  creating = false
  showModalRename = false
  showModalPutOffline = false 
  showModalDelete = false

  get clusters():APICluster[] {
    return this.$store.getters['clusters/getClusters']
  }

  get actionTarget():APICluster | null {
    return this.$store.getters['modals/getClusterSelected']
  }

  get customer():APICustomer {
    return this.$store.getters['user/getCustomer']
  }

  get projectsList():APIProject[] {
    return this.$store.getters['projects/getProjectList']
  }

  get projects():{[key:string]:APIProject[]} {
    const ret:{[key:string]:APIProject[]} = {}
    this.projectsList.forEach((project) => {
      project?.relationships?.kubernetes_clusters?.data?.forEach((kc) => {
        if(!ret[kc.id]) {
          ret[kc.id] = []
        }
        ret[kc.id].push(project)
      })
    })
    return ret
  }
  get clusterDeployments():APIDeployment[] {
    return this.$store.getters['clusters/getDeployments']
  }

  // Key: clusterId
  // Value: status
  get clustersStatus():{[key:string]:string} {
    const ret:{[key:string]:string} = {}

    this.clusterDeployments.forEach((deployment:APIDeployment) => {
      const foundCluster = this.clusters.find((cluster:APICluster) => {
        return cluster.id === deployment?.relationships?.subject?.data?.id
      })
      if(foundCluster) {
        ret[foundCluster.id] = deployment ? deployment.attributes.status : this.$enums.DeploymentStatus.OFFLINE
      }
    })

    return ret
  }

  createCluster() {
    this.$store.dispatch('modals/setClusterSelected', null)
    this.creating = true
    this.$store.dispatch('modals/setVisibility', {modal:this.$enums.ModalsName.Cluster, newState: true})
  }
  
  onBurgerMenuItemClick(item:ListItem, cluster:APICluster) {
    this.$store.dispatch('modals/setClusterSelected', cluster)

    this.creating = false
    item.selected = false

    switch(item.id) {
      case 'rename':
        this.showModalRename = true
      break;
      case 'edit':
        this.$store.dispatch('modals/setVisibility', {modal:this.$enums.ModalsName.Cluster, newState: true})
      break;
      case 'offline':
        this.showModalPutOffline = true
      break;
      case 'delete':
        this.showModalDelete = true
      break;
    }
  }

  onClusterClick(cluster:APICluster) {
    this.$router.push({
      name: 'clusterMonitoring',
      params: {
        clusterId: cluster.id
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/css/variables';
:deep(.delete) {
  color : $text-error;
}
</style>
