<template>
  <div class="node-detail-metrics full-width d-flex" >
    <template v-if="!initialLoaded">
      <Spinner class="ma-auto"/>
    </template>
    <template v-else-if="!layout.length">
      <v-col class="pa-0 my-auto">
        <v-row class="ma-0">
          <v-img src="@/assets/illustrations/empty_metrics.svg" height="80px" width="107px" class="mx-auto mb-4"/>
        </v-row>
        <v-row class="ma-0 token-text-h5 token-text-color-primary mb-2 mx-auto fit-content">
          No metrics to show
        </v-row>
        <v-row class="ma-0 token-text-regular token-text-color-secondary mx-auto fit-content">
          Deploy your project to start collecting metrics
        </v-row>
      </v-col>
    </template>
    <template v-else>
        <template v-if="fullscreen">
          <!-- Fullscreen -->
          <Modal v-model:visible="fullscreen" :pseudoFullScreen="true" :transparentExternal="true">
            <template #header>
              <v-row class="ma-2 ">
                <div class="token-text-medium my-2">
                  Metrics
                </div>
                <div class="ml-auto">
                  <CustomButton ref="menuButton" :level="$enums.ButtonLevels.Tertiary" @click="dropdownOpened = true" class="px-1">
                    <span class="ml-n3">{{ userSelectedPeriod.text }}</span>
                    <Icon  class="ml-1 mr-n4" color="#3219B2" icon="caret-down"/>
                  </CustomButton>
                  <DropdownMenu 
                    :items="itemsDropdown"
                    v-model:show="dropdownOpened"
                    :modelValue="userSelectedPeriod"
                    :maxContent="true"
                    @update:modelValue="onUserSelecPeriod($event)"
                    :target="$refs.menuButton"
                  />
                </div>
                <CustomButton :level="$enums.ButtonLevels.Tertiary" icon="arrow-expand" class="my-auto ml-2 px-2" @click="toggleFullscreen"/>
              </v-row>
              <v-row class="ma-2 mb-4 fit-content ml-auto" v-if="userSelectedPeriod.id === 'custom'">
                <CustomTextInput v-model="startPicker" type="datetime-local" style="width: 200px;" @update:modelValue="onDatePickerChange"/>
                <Icon icon="arrow-right" class="mx-2"/>
                <CustomTextInput v-model="endPicker" type="datetime-local" style="width: 200px;" @update:modelValue="onDatePickerChange"/>
              </v-row>
              <v-row class="ma-0 border-bot">
              </v-row>
            </template>
            <v-row class="ma-0 metrics-container" :class="{'custom-show' : userSelectedPeriod.id === 'custom'}" v-if="fullscreen">
              <v-col class="pa-0 ">
                <GridLayout 
                  class=" grid-layout"
                  v-model:layout="layoutFullscreen"
                  :colNum="24"
                  :rowHeight="8"
                  :margin="[32,32]"
                  :isDraggable="false"
                  :isResizable="false"
                  :verticalCompact="true"
                  :useCssTransforms="true"
                >
                  <GridItem
                      v-for="(item, index) in layoutFullscreen"
                      :key="index"
                      :static="item.static"
                      :x="item.x"
                      :y="item.y"
                      :w="item.w"
                      :h="item.h"
                      :i="item.i"
                  >
                    <WidgetMonitoringWrapper :widgetAPI="widgetsAPI[index]" class="widget-wrapper" :customStart="start" :customEnd="end" :period="userSelectedPeriod"/>
                  </GridItem>
                </GridLayout>
              </v-col>
            </v-row>
          </Modal>
        </template>
        <template v-else>
          <!-- Not Fullscreen -->
          <v-col class="pa-0 " >
            <v-row class="ma-2 ">
              <div class="token-text-medium my-2">
                Metrics
              </div>
              <div class="ml-auto">
                <CustomButton ref="menuButton" :level="$enums.ButtonLevels.Tertiary" @click="dropdownOpened = true" class="px-1">
                  <span class="ml-n3">{{ userSelectedPeriod.text }}</span>
                  <Icon  class="ml-1 mr-n4" color="#3219B2" icon="caret-down"/>
                </CustomButton>
                <DropdownMenu 
                  :items="itemsDropdown"
                  v-model:show="dropdownOpened"
                  :modelValue="userSelectedPeriod"
                  :maxContent="true"
                  @update:modelValue="onUserSelecPeriod($event)"
                  :target="$refs.menuButton"
                />
              </div>
              <CustomButton :level="$enums.ButtonLevels.Tertiary" icon="arrow-expand" class="my-auto ml-2 px-2" @click="toggleFullscreen"/>
            </v-row>
              <v-row class="ma-2 mb-4" v-if="userSelectedPeriod.id === 'custom'">
                <CustomTextInput v-model="startPicker" type="datetime-local" style="width: 200px;" @update:modelValue="onDatePickerChange"/>
                <Icon icon="arrow-right"/>
                <CustomTextInput v-model="endPicker" type="datetime-local" style="width: 200px;" @update:modelValue="onDatePickerChange"/>
              </v-row>
              <v-row class="ma-0 border-bot">
              </v-row>
              <v-row class="ma-0 metrics-container" :class="{'custom-show' : userSelectedPeriod.id === 'custom'}" v-if="!fullscreen">
                <v-col class="pa-0">
                  <GridLayout 
                    class=" grid-layout"
                    v-model:layout="layout"
                    :colNum="24"
                    :rowHeight="32"
                    :margin="[8,8]"
                    :isDraggable="false"
                    :isResizable="false"
                    :verticalCompact="true"
                    :useCssTransforms="true"
                  >
                    <GridItem
                        v-for="(item, index) in layout"
                        :key="index"
                        :static="item.static"
                        :x="item.x"
                        :y="item.y"
                        :w="item.w"
                        :h="item.h"
                        :i="item.i"
                    >
                      <WidgetMonitoringWrapper :widgetAPI="widgetsAPI[index]" class="widget-wrapper" :customStart="start" :customEnd="end" :period="userSelectedPeriod"/>
                    </GridItem>
                  </GridLayout>
                </v-col>
                
              </v-row>
            </v-col>

        </template>
        

      
      
    </template>
     
  </div>
</template>

<script lang="ts">
import { Vue, prop, Options } from 'vue-class-component'
import { APIBlock, APIDeployment, APIWidgetMonitoring } from '@/typesAPI'
import API from '@/api/wrapper'
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue';
import Icon from '@/components/UIElements/Icon.vue';
import DropdownMenu from '@/components/UIElements/DropdownMenu.vue';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import { ListItem } from '@/components/UIElements/List.vue';
import Spinner from '@/components/UIElements/Spinner.vue';
import WidgetMonitoringWrapper from '@/components/UIElements/WidgetsMonitoring/WidgetMonitoringWrapper.vue';
import { Watch } from 'vue-property-decorator';
import Modal from '@/components/UIElements/Modal.vue';

class Props {
  blockAPI: APIBlock = prop({
    required: true,
  });
}

@Options({
  components: {
    CustomTextInput,
    Icon,
    DropdownMenu,
    CustomButton,
    Spinner,
    WidgetMonitoringWrapper,
    Modal
  },
})
export default class NodeDetailMetrics extends Vue.with(Props) {
  widgetsAPI:APIWidgetMonitoring[] = []
  layoutFullscreen:{[key:string]:any}[] = []
  layout:{[key:string]:any}[] = []

  itemsDropdown:ListItem[] = [
    {
      text: 'Last 5 minutes',
      id: '5m'
    },
    {
      text: 'Last 15 minutes',
      id: '15m'
    },
    {
      text: 'Last hour',
      id: '1h'
    },
    {
      text: 'Last 24 hours',
      id: '1d'
    },
    {
      text: 'Last 7 days',
      id: '7d'
    },
    {
      text: 'Last 30 days',
      id: '30d'
    },
    {
      text: 'Last year',
      id: '1y'
    },
    {
      text: 'Custom dates',
      id: 'custom'
    },
  ]
  dropdownOpened = false
  userSelectedPeriod:ListItem = {}
  end = new Date()
  start = new Date()
  endPicker = ""
  startPicker = ""
  timeoutUpdateCustomDate = 0

  initialLoaded = false
  fullscreen = false

  get myDeployment(): APIDeployment | undefined {
    const deployments:APIDeployment[] = this.$store.getters['blocksAPI/getDeployements']
    return deployments.find((deployment:APIDeployment) => deployment.relationships.subject.data.id === this.blockAPI?.id)
  }

  mounted(): void {
    this.userSelectedPeriod = this.itemsDropdown[0]
    this.start = new Date(new Date().setTime(new Date().getTime() - (5 * 60 * 1000))) // Last 5 min
    this.end = new Date()
    this.majPickers()
  }

  @Watch('myDeployment', {immediate: true})
  onMyDeploymentChange() {
    if(this.myDeployment) {
      this.loadDashboard()
    }
  }

  loadDashboard() {
    if(this.myDeployment) {
      API.monitoring.getMetricDashboard(this.myDeployment.id)
      .then((widgetsAPI:APIWidgetMonitoring[]) => {
        this.widgetsAPI = widgetsAPI

        let layout:{[key:string]:any}[] = []
        let layoutFullscreen:{[key:string]:any}[] = []
        this.widgetsAPI.forEach((widgetAPI:APIWidgetMonitoring, index:number) => {
          layout.push({
            x:0,
            y:index,
            h:widgetAPI.attributes.grid_position.height,
            w:24,
            i:widgetAPI.id,
          })
          layoutFullscreen.push({
            x:widgetAPI.attributes.grid_position.x,
            y:widgetAPI.attributes.grid_position.y,
            h:widgetAPI.attributes.grid_position.height,
            w:widgetAPI.attributes.grid_position.width,
            i:widgetAPI.id,
          })
        })
        this.layout = layout
        this.layoutFullscreen = layoutFullscreen
      })
      .finally(() => {
        this.initialLoaded = true
      })
    }
   
  }

  toggleFullscreen() {
    this.fullscreen = !this.fullscreen
  }

  majPickers() {
    this.endPicker = new Date(this.end.getTime() - this.end.getTimezoneOffset() * 60 * 1000).toISOString().split('.')[0]
    this.endPicker = this.endPicker.slice(0,this.endPicker.length - 3)
    this.startPicker = new Date(this.start.getTime() - this.start.getTimezoneOffset() * 60 * 1000).toISOString().split('.')[0]
    this.startPicker = this.startPicker.slice(0,this.startPicker.length - 3)
  }

  onDatePickerChange() {
    clearTimeout(this.timeoutUpdateCustomDate)
    this.timeoutUpdateCustomDate = setTimeout(() => {
      this.start = new Date(this.startPicker)
      this.end = new Date(this.endPicker)
    },1000)

  }

  
  onUserSelecPeriod(newPeriod:ListItem) {
    this.dropdownOpened = false
    this.userSelectedPeriod = newPeriod

    this.setDatesFromSelectedPeriod(newPeriod)
    
    this.majPickers()
  }

  setDatesFromSelectedPeriod(period:ListItem) {
    const s = new Date()
    const e = new Date()

    const offsets:{[key:string]:number} = {
      '5m' : 5 * 60 * 1000, // Last 5 min
      '15m' : 15 * 60 * 1000, // Last 15 min
      '1h' : 60 * 60 * 1000, // Last 60min
      '1d' : 24 * 60 * 60 * 1000, // Last 24h
      '7d' : 7 * 24 * 60 * 60 * 1000, // Last 7 days
      '30d' : 30 * 24 * 60 * 60 * 1000, // Last 30 days
      '1y' : 365 * 24 * 60 * 60 * 1000, // Last 365 days
    }

    if(offsets[period.id as string]) {
      this.start = new Date(s.setTime(s.getTime()- (offsets[period.id as string]))) 
      this.end = e
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';
.border-bot {
  border-bottom: 1px solid $color-neutral-grey-12 !important;
}
.node-detail-metrics {
  
  .metrics-container {
    height: calc(90vh - 186px);
    overflow: auto;

    &.custom-show {
      height: calc(90vh - 244px);
      overflow: hidden;
    }
  }
}

</style>