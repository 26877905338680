<template>
  <v-col class="widgetMonitoringWrapper pa-0 d-flex-col">
    <component 
      v-if="myWidgetComponent"
      :is="myWidgetComponent"
      :widgetAPI="widgetAPI"
      :customStart="customStart"
      :customEnd="customEnd"
      :period="period"
    />
  </v-col>
</template>

<script lang="ts">
import { Vue, Options, prop } from 'vue-class-component';
import { defineAsyncComponent } from "vue";
import { ListItem } from '@/components/UIElements/List.vue';

import { APIWidgetMonitoring } from '@/typesAPI';
import NotFoundWidgetMonitoring from '@/components/UIElements/WidgetsMonitoring/Widgets/NotFoundWidgetMonitoring.vue';

class Props {
  widgetAPI: APIWidgetMonitoring = prop({
    required: true,
  });
  period: ListItem = prop({
    required: true,
  });
  customStart: Date = prop({
    required: true,
  });
  customEnd: Date = prop({
    required: true,
  });
}

@Options({
  components: {
    NotFoundWidgetMonitoring
  },
})
export default class WidgetMonitoringWrapper extends Vue.with(Props) {

  // Dynamicaly load components from components/UIElements/Charts
  get myWidgetComponent():any {
    let ret:any = null

      if(this.widgetAPI.attributes.type) {

        ret = defineAsyncComponent({
          // the loader function
          loader: () => import(`@/components/UIElements/WidgetsMonitoring/Widgets/${this.componentNameFromType}.vue`),
          // A component to use while the async component is loading
          // loadingComponent: LoadingComponent,
          // Delay before showing the loading component. Default: 200ms.
          // delay: 200,
          // A component to use if the load fails
          errorComponent: NotFoundWidgetMonitoring,
          // The error component will be displayed if a timeout is
          // provided and exceeded. Default: Infinity.
          timeout: 3000 ,
          suspensible : false,
          onError(error, retry, fail, attempts) {
            if (error.message.match(/fetch/) && attempts <= 3) {
              retry();
            } else {
              fail();
            }
          }
        })
      }
    return ret
  }

  get componentNameFromType():string {
    if(!this.widgetAPI?.attributes?.type) return ''

    return this.$helpers.components.getNameFromAttributesType(this.widgetAPI?.attributes?.type) + 'WidgetMonitoring'
  }

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

.widgetMonitoringWrapper {
  height: 100%;
  width: 100%;
  background:white !important;
  border-radius: 8px !important;
  border: 1px solid $color-neutral-grey-12 !important;
  overflow: auto;
}
</style>
