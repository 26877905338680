<template>
  <div class="loginBar bar" @keyup.enter="doLogin">
    <div class="token-text-h4 mb-6">
      Log in / Sign up
    </div>
    <!-- Email -->
    <div class="token-text-regular">
      Email
    </div>
    <CustomTextInput class="mt-2"  v-model="login" :error="errors.login" placeholder="hello@email.com"/>
    <!-- Password -->
    <template v-if="withPassword">
      <div class="token-text-regular mt-2">
        Password
      </div>
      <CustomTextInput class="mt-2" v-model="password" type="password" :error="errors.password"/>
    </template>
    <!-- Generic Error -->
    <div class="mt-2" v-if="displayGenericError">
      <div class="token-text-button">
        Wrong email or password.
      </div>
      <div class="token-text-regular">
        During alpha, passwords are sent to you by a member of the Fransys team. If you have lost your password please <span class="link" @click="$helpers.contact.contactUs">contact us</span>.
      </div>
    </div>
    <CustomButton :level="$enums.ButtonLevels.Primary" @click="doLogin" class="login-btn mt-2" :status="buttonState" :text="btnText" :disabled="loading" :loading="loading"/>

    <TextBetweenLines class="my-4">
      <span class="token-text-color-secondary token-text-microbold token-text-transform-uppercase token-text-font-iAWriterDuoV">
        or
      </span>
    </TextBetweenLines>

    <LoginSocialButton provider="gitlab" providerName="GitLab" class="mb-2"/>
    <LoginSocialButton provider="github" providerName="GitHub"/>

  </div>
</template>

<script lang="ts">
import CustomButton from '@/components/UIElements/CustomButton.vue';
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue';
import TextBetweenLines from '@/components/UIElements/TextBetweenLines.vue';
import LoginSocialButton from '@/components/Actions/LoginSocialButton.vue';
import { Vue, Options, prop } from 'vue-class-component';
import API from '@/api/wrapper'
import { APICustomer, APISocialAccount, APIUser } from '@/typesAPI';

class Props {
  withPassword?: boolean = prop({
    required: false,
  });
}

@Options({
  components: {
    CustomButton,
    CustomTextInput,
    LoginSocialButton,
    TextBetweenLines
  },
})
export default class LoginBar extends Vue.with(Props) {
  login = ''
  password = '' 
  loading = false
  btnText = 'Log in / Sign up'
  errors = {
    login: "",
    password: ""
  }
  buttonState = ''
  displayGenericError = false

  mounted() {
    API.session.getCSRF().then(() => {
      this.getMe()
    })
  }

  getMe() {
    API.user.getMe()
    .then((res:{user:APIUser, socialAccounts:APISocialAccount[], customers:APICustomer[]}) => {
      res.socialAccounts.forEach((socialAccount:APISocialAccount) => {
        this.$store.dispatch('user/setSocialAccount', {provider: socialAccount.attributes.provider, socialAccount: socialAccount})
      })
      this.$store.dispatch('user/setUser', res.user)
      this.$store.dispatch('user/setCustomer', res.customers[0])

      this.$router.push({name:'projects'})
    })
    .catch((err) => {
      return;
    })
  }

  doLogin () {
    if(this.login) {
      if(this.withPassword && this.password) {
        this.loading = true
        this.btnText = ""
        this.displayGenericError = false
        API.session.login(this.login, this.password)
        .then((res:any) => {
          this.loading = false
          this.buttonState = this.$enums.ButtonStates.Success
          this.getMe()
        })
        .catch((err:any) => {
          if(err?.response?.data) {
            this.$store.dispatch('toastrs/removeDescriptor', err?.toastr)
            this.buttonState = this.$enums.ButtonStates.Danger
            this.loading = false
            setTimeout(() => {
              this.buttonState = ''
              this.btnText = 'Log in'
            },2000)

            this.errors.login = err?.response?.data?.meta?.login ? err.response.data.meta.login.join(' ') : ''
            this.errors.password = err?.response?.data?.meta?.password ? err.response.data.meta.password.join(' ') : ''
            if(err.response.status === 403) {
              this.displayGenericError = true
            }
          }
          
        })
      } else {
        this.$router.push({name:'register', query: {email: this.login}})
      }
    }
  }


}
</script>

<style lang="scss">
.loginBar {
  padding: 40px;
  display: flex;
  flex-direction: column;
  width: 480px !important;
  box-shadow: -4px 0px 16px 0px rgba(1, 19, 194, 0.08), 4px 0px 16px 0px rgba(127, 61, 244, 0.08), 0px -4px 16px 0px rgba(243, 83, 0, 0.08), 0px 4px 16px 0px rgba(255, 114, 202, 0.08) !important;
  .login-btn {
    width: 100%;
  }
  .link {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
