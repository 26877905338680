import API from '@/api/wrapper'
import store from '..'
import enums from '@/enums'

const bulkQueuSize = 5

function nbBulkQueueRunning(state:State, overwrite?:boolean) {
  if(overwrite) {
    return state.bulkValuesQueueStatusToOverwrite.reduce((accumulator, currentValue) => currentValue === enums.QueueStatus.RUNNING ? accumulator + 1 : accumulator, 0)
  }
  return state.bulkValuesQueueStatus.reduce((accumulator, currentValue) => currentValue === enums.QueueStatus.RUNNING ? accumulator + 1 : accumulator, 0)
}

function nbBulkQueueFinished(state:State, overwrite?:boolean) {
  if(overwrite) {
    return state.bulkValuesQueueStatusToOverwrite.reduce((accumulator, currentValue) => currentValue === enums.QueueStatus.SUCCESSED || currentValue === enums.QueueStatus.FAILED ? accumulator + 1 : accumulator, 0)
  }
  return state.bulkValuesQueueStatus.reduce((accumulator, currentValue) => currentValue === enums.QueueStatus.SUCCESSED || currentValue === enums.QueueStatus.FAILED ? accumulator + 1 : accumulator, 0)
}

function nextQueuBulkEditVariable(state:State) {

  state.bulkValues.some((bulkValue, i) => {
    if(state.bulkValuesQueueStatus[i] === enums.QueueStatus.PENDING && state.bulkingFieldId) {
      if(nbBulkQueueRunning(state) < bulkQueuSize) {
        state.bulkValuesQueueStatus[i] = enums.QueueStatus.RUNNING
        API.group.create(state.bulkingFieldId, bulkValue)
        .then(({fieldValues, group}) => {
          state.bulkValuesQueueStatus[i] = enums.QueueStatus.SUCCESSED
          store.dispatch('fields/addFieldValues', fieldValues)
          store.dispatch('fields/addFieldGroups', [group])
        })
        .catch((err) => {
          if(err.response.data?.errors['environment-variable-duplicate']) {
            store.dispatch('toastrs/removeDescriptor', err.toastr)
            state.bulkValuesDuplicated[state.bulkingFieldId +''+ i] = bulkValue
            state.bulkValuesErrors[state.bulkingFieldId +''+ i] = err.response.data
          }
          state.bulkValuesQueueStatus[i] = enums.QueueStatus.FAILED

        })
        .finally(() => {
          if(nbBulkQueueRunning(state) < bulkQueuSize) {
            setTimeout(() => {
              nextQueuBulkEditVariable(state)
            },0)
          }
          if(nbBulkQueueFinished(state) >= state.bulkValuesQueueStatus.length) {
            state.bulkValuesQueueStatus = []
            state.bulkValues = []
            store.dispatch('fields/buildTree')
          }
        })
        return false
      }
      return true
    }
    
  })
 
}

function nextQueuBulkOverwriteVariable(state:State) {

  state.bulkValuesToOverwrite.some((bulkValue, i) => {
    if(state.bulkValuesQueueStatusToOverwrite[i] === enums.QueueStatus.PENDING && state.bulkingFieldId) {
      if(nbBulkQueueRunning(state, true) < bulkQueuSize) {
        state.bulkValuesQueueStatusToOverwrite[i] = enums.QueueStatus.RUNNING
        API.group.edit(state.bulkValuesGroupIdToOverwrite[i], bulkValue)
        .then(({fieldValues, group}) => {
          state.bulkValuesQueueStatusToOverwrite[i] = enums.QueueStatus.SUCCESSED
          store.dispatch('fields/addFieldValues', fieldValues)
          store.dispatch('fields/addFieldGroups', [group])
        })
        .catch((err) => {
          state.bulkValuesQueueStatusToOverwrite[i] = enums.QueueStatus.FAILED
        })
        .finally(() => {
          if(nbBulkQueueRunning(state, true) < bulkQueuSize) {
            setTimeout(() => {
              nextQueuBulkOverwriteVariable(state)
            },0)
          }
          if(nbBulkQueueFinished(state, true) >= state.bulkValuesQueueStatusToOverwrite.length) {
            state.bulkValuesQueueStatusToOverwrite = []
            state.bulkValuesToOverwrite = []
            state.bulkValuesGroupIdToOverwrite = []
            store.dispatch('fields/buildTree')
          }
        })
        return false
      }
      return true
    }
    
  })
 
}


type State = {
  bulkValuesQueueStatus: string[],
  bulkValuesQueueStatusToOverwrite: string[],
  bulkValuesDuplicated: {[key:string]:{[key:string]:any}},
  bulkValuesErrors: {[key:string]:{[key:string]:any}},
  bulkValues:{[key:string]:any}[],
  bulkValuesToOverwrite:{[key:string]:any}[],
  bulkValuesGroupIdToOverwrite: string[],
  bulkingFieldId:string | undefined
}

const state: State = {
  bulkValuesQueueStatus: [],
  bulkValuesQueueStatusToOverwrite: [],
  bulkValuesDuplicated: {},
  bulkValuesErrors: {},
  bulkValues: [],
  bulkValuesToOverwrite: [],
  bulkValuesGroupIdToOverwrite: [],
  bulkingFieldId: undefined
}


const getters = {
  getBulkValuesQueueStatus: (state:State) => {
    return state.bulkValuesQueueStatus
  },
  getBulkValuesQueueStatusToOverwrite: (state:State) => {
    return state.bulkValuesQueueStatusToOverwrite
  },
  getBulkValuesDuplicated: (state:State) => {
    return state.bulkValuesDuplicated
  },
  getBulkValuesErrors: (state:State) => {
    return state.bulkValuesErrors
  },
  getBulkingFieldId: (state:State) => {
    return state.bulkingFieldId
  },
}

const mutations = {
  BULK_EDIT_VARIABLES: (state:State, payload:{bulkValues:{[key:string]:any}[], fieldId:string}) => {
    state.bulkValuesQueueStatus = payload.bulkValues.map(() => enums.QueueStatus.PENDING)
    state.bulkingFieldId = payload.fieldId
    state.bulkValues = payload.bulkValues
    nextQueuBulkEditVariable(state)
  },
  INTERUPT_BULK_EDIT_VARIABLES: (state:State, fieldId:string) => {
    state.bulkingFieldId = undefined
    state.bulkValuesQueueStatus = []
    state.bulkValues = []
    store.dispatch('fields/buildTree')
  },
  SKIP_DUPLICATE(state:State, id:string) {
    delete state.bulkValuesDuplicated[id]
    delete state.bulkValuesErrors[id]
  },
  RENAME_DUPLICATE(state:State, id:string) {
    const newVal = {
      ...state.bulkValuesDuplicated[id]
    }
    const nameKey = state.bulkValuesErrors[id].meta.field
    newVal[nameKey] = state.bulkValuesDuplicated[id][nameKey] + '_2'

    state.bulkValues.push(newVal)
    state.bulkValuesQueueStatus.push(enums.QueueStatus.PENDING)

    if(state.bulkValues.length === 1) {
      nextQueuBulkEditVariable(state)
    }

    delete state.bulkValuesDuplicated[id]
    delete state.bulkValuesErrors[id]
  },
  OVERWRITE_DUPLICATE(state:State, id:string) {

    state.bulkValuesQueueStatusToOverwrite.push(enums.QueueStatus.PENDING)
    state.bulkValuesToOverwrite.push(state.bulkValuesDuplicated[id])
    state.bulkValuesGroupIdToOverwrite.push(state.bulkValuesErrors[id].meta.existing_group)

    if(state.bulkValuesToOverwrite.length === 1) {
      nextQueuBulkOverwriteVariable(state)
    }

    delete state.bulkValuesDuplicated[id]
    delete state.bulkValuesErrors[id]
  },
}

const actions = {
  bulkEditVariable: (context:any,payload:{bulkValues:{[key:string]:any}[], fieldId:string}) => {
    context.commit('BULK_EDIT_VARIABLES', payload)
  },
  skipDuplicate: (context:any,id:string) => {
    context.commit('SKIP_DUPLICATE', id)
  },
  renameDuplicate: (context:any,id:string) => {
    context.commit('RENAME_DUPLICATE', id)
  },
  overwriteDuplicate: (context:any,id:string) => {
    context.commit('OVERWRITE_DUPLICATE', id)
  },
  interuptBulkEditVariable: (context:any,fieldId:string) => {
    context.commit('INTERUPT_BULK_EDIT_VARIABLES', fieldId)
  },
}
export default {
  state,
  getters,
  mutations,
  actions
}