import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, { class: "ma-0" }, {
    default: _withCtx(() => [
      (!_ctx.hideData)
        ? (_openBlock(), _createBlock(_component_v_col, {
            key: 0,
            class: "pa-0 token-text-font-iAWriterDuoV token-text-regular token-text-color-secondary mr-2 fit-content"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$helpers.dates.formatTime(_ctx.log.attributes.time)), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.hideData)
        ? (_openBlock(), _createBlock(_component_v_col, {
            key: 1,
            class: "pa-0 token-text-font-iAWriterDuoV token-text-regular token-text-color-secondary mr-2 fit-content"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.log.attributes.container), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.hideMessage)
        ? (_openBlock(), _createBlock(_component_v_col, {
            key: 2,
            class: "pa-0 token-text-font-iAWriterDuoV token-text-regular token-text-color-primary"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.log.attributes.message), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}