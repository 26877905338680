<template>
  <div class="clusterMonitoringView full-view ">

      <!-- Modal Edit -->
      <ModalClusterConfig :cluster="cluster" :creating="false"/>
      <!-- Modal Put Offline -->
      <ModalClusterPutOffline :cluster="cluster" v-model:visible="showModalPutOffline" :projects="cluster ? projects[cluster.id] : []"/>
      <!-- Modal Delete -->
      <ModalClusterDelete :cluster="cluster" v-model:visible="showModalDelete" />


    <v-col class="pa-0 pa-8 d-flex-col">
      <v-row class="ma-0 mb-6 no-flex-grow">
        <CustomButton :level="$enums.ButtonLevels.Secondary" @click="$router.go(-1)" icon="arrow-left">
          Back
        </CustomButton>
      </v-row>
      <v-row class="ma-0 mb-6 no-flex-grow">
        <div class="token-text-h4 token-text-color-primary mr-4">
          {{ cluster?.attributes.name }}
        </div>
        <Badge class="my-auto" :label="$helpers.states.translateDeploymentStatusToLabel(clusterStatus)" :severity="$helpers.states.translateDeploymentStatusToSeverity(clusterStatus)" :icon="clusterStatus === $enums.DeploymentStatus.DEPLOYING || clusterStatus === $enums.DeploymentStatus.SHUTTING_DOWN ? 'spinner' : ''" iconColor="rgba(0, 0, 58, 1)"/>
        <Divider :vertical="true" class="mx-4"/>
        <Logo :logo="cluster?.attributes?.provider" class="mr-2 ml-0" />
        <div class="ml-0 mr-4 my-auto">
          {{ $helpers.providers.providerToLabel(cluster?.attributes?.provider) }}
        </div>
        <template v-if="currentLocation">
          <Flag :code="currentLocation.attributes.country" class="ml-0 mr-2" />
          <div class="ml-0 mr-4 my-auto">
            {{ locationDisplayName }}
          </div>
        </template>
        <Icon class="ml-0 mr-2" icon="chip" color="rgba(0, 0, 58, 0.6)"/>
        <div class="ml-0 mr-0 my-auto">
          {{ cluster?.attributes.requested_config.node_number_min }} 
        </div>
        <Icon class="mx-1" icon="arrow-right" color="rgba(0, 0, 58, 0.6)"/>
        <div class="ml-0 mr-0 my-auto">
          {{ cluster?.attributes.requested_config.node_number_max }} 
          &nbsp;nodes
        </div>

        <CustomButton :level="$enums.ButtonLevels.Tertiary"  icon="trash" class="ml-auto mr-4 token-text-color-orange" @click="showModalDelete = true" :disabled="clusterStatus !== $enums.DeploymentStatus.OFFLINE">
          Delete
        </CustomButton>
        <CustomButton :level="$enums.ButtonLevels.Tertiary"  icon="cloud-off" class="ml-0 mr-4" @click="showModalPutOffline = true">
          Undeploy
        </CustomButton>
        <CustomButton :level="$enums.ButtonLevels.Tertiary"  icon="params" class="ml-0" @click="editCluster">
          Edit cluster
        </CustomButton>

      </v-row>
      <v-row class="ma-0 mb-6 no-flex-grow">
        <SegmentedControl v-model="selectedTab" :tabs="listTabs" class="tab-control fit-content" />

        <template v-if="selectedTab === 'history'">
          <div class="ml-auto">
            <v-row class="no-flex-grow my-auto">
              <div class="ml-auto">
                <CustomButton ref="menuButton" :level="$enums.ButtonLevels.Tertiary" @click="dropdownOpened = true" class="px-1">
                  <span class="ml-n3">{{ userSelectedPeriod.text }}</span>
                  <Icon  class="ml-1 mr-n4" color="#3219B2" icon="caret-down"/>
                </CustomButton>
                <DropdownMenu 
                  :items="itemsDropdown"
                  v-model:show="dropdownOpened"
                  :modelValue="userSelectedPeriod"
                  :maxContent="true"
                  @update:modelValue="onUserSelecPeriod($event)"
                  :target="$refs.menuButton"
                />
              </div>
            </v-row>
            <v-row class="ma-2 mb-4" v-if="userSelectedPeriod.id === 'custom'">
              <CustomTextInput v-model="startPicker" type="datetime-local" style="width: 200px;" @update:modelValue="onDatePickerChange"/>
              <Icon icon="arrow-right" class="mx-2"/>
              <CustomTextInput v-model="endPicker" type="datetime-local" style="width: 200px;" @update:modelValue="onDatePickerChange"/>
            </v-row>
          </div>
          
        </template>

        <!-- <template v-if="!editLayoutMode">
          <CustomButton :level="$enums.ButtonLevels.Tertiary" @click="editLayoutMode = true" class="ml-auto" icon="layout" >
            Edit layout
          </CustomButton>
        </template>
        <template v-else>
          <CustomButton :level="$enums.ButtonLevels.Secondary" @click="layout = initialLayout" class="ml-auto"  :disabled="layout === initialLayout">
            Cancel changes
          </CustomButton>
          <CustomButton :level="$enums.ButtonLevels.Primary" @click="initialLayout = layout;editLayoutMode = false" class="ml-4" >
            Save layout
          </CustomButton>
        </template> -->

      </v-row>
      <v-row class="ma-0" :class="{'edit-layout-mode' : editLayoutMode}">
          <!-- DASHBOARD EMPTY STATE OFFLINE -->
          <template v-if="clusterStatus === $enums.DeploymentStatus.OFFLINE ">
            <v-col class="pa-0 empty-state d-flex-col">
              <div class="my-auto ">
                <v-row class="ma-0">
                  <v-img src="@/assets/illustrations/cluster_offline.svg" height="67px" width="86px" class="mx-auto mb-4"/>
                </v-row>
                <v-row class="ma-0">
                  <div class=" mx-auto token-text-h5 token-text-color-primary">
                    Cluster offline
                  </div>
                </v-row>
                <v-row class="ma-0">
                  <div class=" token-text-regular token-text-color-secondary mx-auto">
                    Deploy cluster to start collecting metrics
                  </div>
                </v-row>
              </div>
            </v-col>
          </template>
          <!-- DASHBOARD EMPTY STATE SHUTTING_DOWN -->
          <template v-else-if="clusterStatus === $enums.DeploymentStatus.SHUTTING_DOWN">
            <v-col class="pa-0 empty-state d-flex-col">
              <div class="my-auto ">
                <v-row class="ma-0">
                  <v-img src="@/assets/illustrations/cluster_shutdown_animated.gif" height="67px" width="86px" class="mx-auto mb-4"/>
                </v-row>
                <v-row class="ma-0">
                  <div class=" mx-auto token-text-h5 token-text-color-primary">
                    Cluster is shutting down
                  </div>
                </v-row>
              </div>
            </v-col>
          </template>
          <!-- DASHBOARD EMPTY STATE DEPLOYING -->
          <template v-else-if="clusterStatus === $enums.DeploymentStatus.DEPLOYING">
            <v-col class="pa-0 empty-state d-flex-col">
              <div class="my-auto ">
                <v-row class="ma-0">
                  <v-img src="@/assets/illustrations/cluster_deploying_animated.gif" height="67px" width="86px" class="mx-auto mb-4"/>
                </v-row>
                <v-row class="ma-0">
                  <div class=" mx-auto token-text-h5 token-text-color-primary">
                    Cluster deployment in progress
                  </div>
                </v-row>
                <v-row class="ma-0">
                  <div class=" token-text-regular token-text-color-secondary mx-auto">
                    The data will be displayed once the cluster is online
                  </div>
                </v-row>
              </div>
            </v-col>
          </template>
          <!-- DASHBOARD HISTORY NO PROJECTS -->
          <template v-else-if="selectedTab === 'history' && this.nbProjectNotOffline === 0">
            <v-col class="pa-0 empty-state d-flex-col">
              <div class="my-auto ">
                <v-row class="ma-0">
                  <v-img src="@/assets/illustrations/empty_metrics.svg" height="80px" width="107px" class="mx-auto mb-4"/>
                </v-row>
                <v-row class="ma-0">
                  <div class=" mx-auto token-text-h5 token-text-color-primary">
                    No online projects in the cluster
                  </div>
                </v-row>
                <v-row class="ma-0">
                  <div class=" token-text-regular token-text-color-secondary mx-auto">
                    Those metrics are project specific, no point in showing you empty graphs, right ?
                  </div>
                </v-row>
              </div>
            </v-col>
          </template>
          <!-- DASHBOARD ONLINE-->
          <template v-else>
            <GridLayout 
              class=" grid-layout"
              v-model:layout="layout"
              :colNum="24"
              :rowHeight="8"
              :margin="[32,32]"
              :isDraggable="editLayoutMode"
              :isResizable="false"
              :verticalCompact="true"
              :useCssTransforms="true"
            >
              <GridItem
                  v-for="(item, index) in layout"
                  :key="index"
                  :static="item.static"
                  :x="item.x"
                  :y="item.y"
                  :w="item.w"
                  :h="item.h"
                  :i="item.i"
              >
                <WidgetMonitoringWrapper :widgetAPI="widgetsAPI[index]" class="widget-wrapper" :class="{'edit-mode' : editLayoutMode}" :customStart="start" :customEnd="end" :period="userSelectedPeriod"/>
              </GridItem>
            </GridLayout>
          </template>
      </v-row>
      
    </v-col>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import WidgetMonitoringWrapper from '@/components/UIElements/WidgetsMonitoring/WidgetMonitoringWrapper.vue';
import API from '@/api/wrapper';
import { APICluster, APIClusterLocation, APIDeployment, APIProject, APIWidgetMonitoring } from '@/typesAPI';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import Badge from '@/components/UIElements/Badge.vue';
import Divider from '@/components/UIElements/Divider.vue';
import Flag from '@/components/UIElements/Flag.vue';
import Logo from '@/components/UIElements/Logo.vue';
import Icon from '@/components/UIElements/Icon.vue';
import SegmentedControl from '@/components/UIElements/SegmentedControl.vue';
import WIPPanel from '@/components/UIElements/WIPPanel.vue';
import { ListItem } from '@/components/UIElements/List.vue';
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue';
import DropdownMenu from '@/components/UIElements/DropdownMenu.vue';
import Spinner from '@/components/UIElements/Spinner.vue';

import ModalClusterConfig from '@/components/Modals/ModalClusterConfig.vue';
import ModalClusterPutOffline from '@/components/Modals/ModalClusterPutOffline.vue';
import ModalClusterDelete from '@/components/Modals/ModalClusterDelete.vue';
import { Watch } from 'vue-property-decorator';

@Options({
  components: {
    WidgetMonitoringWrapper,
    CustomButton,
    Badge,
    Divider,
    Flag,
    Logo,
    Icon,
    SegmentedControl,
    ModalClusterConfig,
    ModalClusterPutOffline,
    ModalClusterDelete,
    WIPPanel,
    CustomTextInput,
    DropdownMenu,
    Spinner
  },
})
export default class ClusterMonitoringView extends Vue {

  widgetsAPI:APIWidgetMonitoring[] = []
  layout:{[key:string]:any}[] = []
  initialLayout:{[key:string]:any}[] = []
  selectedTab = 'overview'
  listTabs: {[key:string]: string} = {'overview' : 'Overview', 'history' : 'Project metrics'}

  editLayoutMode = false
  showModalPutOffline = false 
  showModalDelete = false


  itemsDropdown:ListItem[] = [
    {
      text: 'Last 5 minutes',
      id: '5m'
    },
    {
      text: 'Last 15 minutes',
      id: '15m'
    },
    {
      text: 'Last hour',
      id: '1h'
    },
    {
      text: 'Last 24 hours',
      id: '1d'
    },
    {
      text: 'Last 7 days',
      id: '7d'
    },
    {
      text: 'Last 30 days',
      id: '30d'
    },
    {
      text: 'Last year',
      id: '1y'
    },
    {
      text: 'Custom dates',
      id: 'custom'
    },
  ]
  dropdownOpened = false
  userSelectedPeriod:ListItem = {}
  end = new Date()
  start = new Date()
  endPicker = ""
  startPicker = ""
  timeoutUpdateCustomDate = 0

  mounted(): void {
    this.userSelectedPeriod = this.itemsDropdown[0]
    this.start = new Date(new Date().setTime(new Date().getTime() - (5 * 60 * 1000))) // Last 5 min
    this.end = new Date()
    this.majPickers()

  }

  majPickers() {
    this.endPicker = new Date(this.end.getTime() - this.end.getTimezoneOffset() * 60 * 1000).toISOString().split('.')[0]
    this.endPicker = this.endPicker.slice(0,this.endPicker.length - 3)
    this.startPicker = new Date(this.start.getTime() - this.start.getTimezoneOffset() * 60 * 1000).toISOString().split('.')[0]
    this.startPicker = this.startPicker.slice(0,this.startPicker.length - 3)
  }

  onDatePickerChange() {
    clearTimeout(this.timeoutUpdateCustomDate)
    this.timeoutUpdateCustomDate = setTimeout(() => {
      this.start = new Date(this.startPicker)
      this.end = new Date(this.endPicker)
    },1000)

  }

  
  onUserSelecPeriod(newPeriod:ListItem) {
    this.dropdownOpened = false
    this.userSelectedPeriod = newPeriod

    this.setDatesFromSelectedPeriod(newPeriod)
    
    this.majPickers()
  }

  setDatesFromSelectedPeriod(period:ListItem) {
    const s = new Date()
    const e = new Date()

    const offsets:{[key:string]:number} = {
      '5m' : 5 * 60 * 1000, // Last 5 min
      '15m' : 15 * 60 * 1000, // Last 15 min
      '1h' : 60 * 60 * 1000, // Last 60min
      '1d' : 24 * 60 * 60 * 1000, // Last 24h
      '7d' : 7 * 24 * 60 * 60 * 1000, // Last 7 days
      '30d' : 30 * 24 * 60 * 60 * 1000, // Last 30 days
      '1y' : 365 * 24 * 60 * 60 * 1000, // Last 365 days
    }

    if(offsets[period.id as string]) {
      this.start = new Date(s.setTime(s.getTime()- (offsets[period.id as string]))) 
      this.end = e
    }
  }

  @Watch('selectedTab', {immediate: true})
  onSelectedTabChange(newVal?:string, oldVal?:string) {
    if(newVal !== oldVal) {
      if(newVal === 'overview') {
        this.setToOverview()
      } else if(newVal === 'history') {
        this.setToHistory()
      }
    }

  }

  setToOverview() {
    API.clusters.monitoring.getOverview(this.clusterId)
    .then((widgetsAPI:APIWidgetMonitoring[]) => {
      this.widgetsAPI = widgetsAPI

      let ret:{[key:string]:any}[] = []
      this.widgetsAPI.forEach((widgetAPI:APIWidgetMonitoring) => {
        ret.push({
          x:widgetAPI.attributes.grid_position.x,
          y:widgetAPI.attributes.grid_position.y,
          h:widgetAPI.attributes.grid_position.height,
          w:widgetAPI.attributes.grid_position.width,
          i:widgetAPI.id,
        })
      })
      this.layout = ret
      this.initialLayout = ret
    })
  }

  setToHistory() {
    API.clusters.monitoring.getHistory(this.clusterId)
    .then((widgetsAPI:APIWidgetMonitoring[]) => {
      this.widgetsAPI = widgetsAPI

      let ret:{[key:string]:any}[] = []
      this.widgetsAPI.forEach((widgetAPI:APIWidgetMonitoring) => {
        ret.push({
          x:widgetAPI.attributes.grid_position.x,
          y:widgetAPI.attributes.grid_position.y,
          h:widgetAPI.attributes.grid_position.height,
          w:widgetAPI.attributes.grid_position.width,
          i:widgetAPI.id,
        })
      })
      this.layout = ret
      this.initialLayout = ret
    })
  }



  editCluster() {
    this.$store.dispatch('modals/setClusterSelected', this.cluster)
    this.$store.dispatch('modals/setVisibility', {modal:this.$enums.ModalsName.Cluster, newState: true})
  }

  get clusterId():string {
    return this.$route.params.clusterId as string
  }

  get clusters():APICluster[] {
    return this.$store.getters['clusters/getClusters']
  }

  get cluster():APICluster | undefined {
    return this.clusters?.find((cluster:APICluster) => {
      return cluster.id === this.clusterId
    })
  }

  get clusterDeployments():APIDeployment[] {
    return this.$store.getters['clusters/getDeployments']
  }

  get clusterStatus():string {
    let ret:string = this.$enums.DeploymentStatus.OFFLINE

    const myDeployment = this.clusterDeployments?.find((deployment) => {
      return deployment.relationships?.subject?.data?.id === this.cluster?.id
    })
    ret = myDeployment ? myDeployment.attributes.status : this.$enums.DeploymentStatus.OFFLINE

    
    return ret
  }

  get clustersStatus():{[key:string]:string} {
    const ret:{[key:string]:string} = {}

    this.clusterDeployments.forEach((deployment:APIDeployment) => {
      const foundCluster = this.clusters.find((cluster:APICluster) => {
        return cluster.id === deployment?.relationships?.subject?.data?.id
      })
      if(foundCluster) {
        ret[foundCluster.id] = deployment ? deployment.attributes.status : this.$enums.DeploymentStatus.OFFLINE
      }
    })

    return ret
  }

  get locations ():APIClusterLocation[] {
    if(this.cluster?.attributes.provider) {
      return this.$store.getters['clusters/getLocationsByProvider'](this.cluster?.attributes.provider)
    }
    return []
  }

  get currentLocation ():APIClusterLocation | undefined {
    return this.locations.find((location) => location.id === this.cluster?.attributes.region)
  }

  get locationDisplayName():string {
    let ret = ""
    if(this.currentLocation) {
      ret = this.$helpers.providers.locationToDisplayName(this.currentLocation)

    }
    return ret
  }

  get projectsList():APIProject[] {
    return this.$store.getters['projects/getProjectList']
  }
  get projects():{[key:string]:APIProject[]} {
    const ret:{[key:string]:APIProject[]} = {}
    this.projectsList.forEach((project) => {
      project?.relationships?.kubernetes_clusters?.data?.forEach((kc) => {
        if(!ret[kc.id]) {
          ret[kc.id] = []
        }
        ret[kc.id].push(project)
      })
    })
    return ret
  }

  get nbProjectNotOffline():number {
    let ret = 0

    Object.values(this.projects).forEach((projects:APIProject[]) => {
      projects.forEach((project:APIProject) => {
        if(project.attributes.status !== this.$enums.DeploymentStatus.OFFLINE && project.attributes.status !== this.$enums.DeploymentStatus.SHUTTING_DOWN) {
          ret++
        }
      })
    })

    return ret
  }
}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

:deep(.vue-grid-item.vue-grid-placeholder) {
    background: $color-brand !important;
}
.edit-layout-mode {
  background-color: $color-bg;
  border-radius: 16px;
}
.widget-wrapper {
  transition: box-shadow 0.2s ease-in-out, border 0.2s ease-in-out;
  &.edit-mode {
    &:hover {
      box-shadow: $shadow-z2;
      border:$color-neutral-grey-30;
    }
  }
}
.grid-layout {
  width: 100vw;
  margin : -32px;
}

.empty-state {
  border-radius: 16px;
  width: 100%;
  background-color: $color-neutral-grey-4;

}

.tab-control {
}

</style>
