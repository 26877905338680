<template>
  <div class="homeView full-view">
    <template v-if="!user || (shouldGoDirectToProject && CGUAccepted)">
      <Spinner :fullScreen="true"/>
    </template>
    <template v-else-if="CGUAccepted">
      <v-col class="pa-0 pa-12 mx-auto homeView-content ">
        <v-row class="ma-0 full-view">
          <!-- Fixed menu -->
          <v-col class="pa-0 col-menu fixed">
            <v-row class="ma-auto pb-12 pl-2">
              <FransysSignature />
            </v-row>
            <v-row class="ma-0">
              <List
                class="full-width"
                :items="list"
                @select="onSelect"
              />
            </v-row>
          </v-col>
          <v-col class="pa-0 mr-12 col-menu">
            
          </v-col>
          <v-col class="pa-0">
            <router-view />
          </v-col>
        </v-row>
      </v-col>
    </template>
    <template v-else>
      <ModalCGU v-if="!CGUAccepted"/>
    </template>
  </div>
</template>

<script lang="ts">
import API from '@/api/wrapper';
import FransysSignature from '@/components/UIElements/FransysSignature.vue';

import List, {ListItem} from '@/components/UIElements/List.vue'
import { APIUser, APISocialAccount, APICustomer } from '@/typesAPI';
import { Vue, Options } from 'vue-class-component';
import ModalCGU from '@/components/Modals/ModalCGU.vue';
import Spinner from '@/components/UIElements/Spinner.vue';
import { Watch } from 'vue-property-decorator';

@Options({
  components: {
    FransysSignature,
    List,
    ModalCGU,
    Spinner
  },
})
export default class HomeView extends Vue {

  list:ListItem[] = [
    {
      text: 'Projects',
      value: 'projects',
      icon:'projects'
    },
    {
      text: 'Clusters',
      value: 'clusters',
      icon:'cluster'
    },
    {
      separator: true,
    },
    {
      text: 'Account',
      value: 'account',
      icon:'user'
    },
    {
      text: 'Billing',
      value: 'billing',
      icon:'receipt'
    },
    {
      text: 'Usage',
      value: 'usage',
      icon:'chip'
    },
  ]

  get user() {
    return this.$store.getters['user/getUser']
  }

  get CGUAccepted(): boolean {
    return this.user ? this.user?.attributes?.terms_of_use_accepted_at !== null : false
  }

  get onboardingState() {
    return this.$store.getters['user/getOnboardingState']
  }

  get shouldGoDirectToProject() {
    return this.onboardingState === null || this.onboardingState?.directToProject
  }

  @Watch("$route", {immediate: true})
  onRouteChange() {
    this.list.forEach((item:ListItem) => {
      item.selected = false
      if(item.value === this.$route.name) {
        item.selected = true
      }
    })
  }

  @Watch("CGUAccepted", {immediate: true})
  onCGUAcceptedChange() {
    if(this.CGUAccepted) {
      this.onboardingAction()
    }
  }

  mounted(): void {
    API.session.getCSRF().then(() => {
      this.getMe()
    })
  }
  
  getMe() {
    if(!this.user) {
      API.user.getMe()
      .then((res:{user:APIUser, socialAccounts:APISocialAccount[], customers:APICustomer[]}) => {
        this.$store.dispatch('user/setUser', res.user)
        this.$store.dispatch('user/setCustomer', res.customers[0])

        res.socialAccounts.forEach((socialAccount:APISocialAccount) => {
          this.$store.dispatch('user/setSocialAccount', {provider: socialAccount.attributes.provider, socialAccount: socialAccount})
        })
      })
    }
    
  }

  onboardingAction() {
    if(this.shouldGoDirectToProject) {
      API.projects.getList()
      .then((projectList) => {
        const newOboardingState = JSON.stringify({
          directToProject : false,
          isFirstDeployment: true,
          hasStartedFirstDeployment: false,
        })
        API.user.edit(this.user.id, {onboarding_state : newOboardingState})
        .then((newUser) => {
          this.$store.dispatch('user/setUser', newUser)
          if(projectList.length) {
            this.$router.push({name:'flow', params: {projectId: projectList[0].id}})
          }
        })
      })
    }
  }

  onSelect(item:ListItem) {
    this.$router.push({
      name: item.value ? item.value : ''
    })
  }
  

}
</script>

<style lang="scss" scoped>
.homeView {
  position: relative;
}
.homeView-content {
  max-width: 1311px;
  z-index: 2;
}
.col-menu {
  min-width: 238px;
  max-width: 238px;
}
</style>
