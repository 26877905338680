<template>
  <div class="WIPPanel">
    <v-row class="ma-auto">
      <v-col class="pa-0">
        <v-row class="ma-0">
          <div class="contruction-icon mx-auto">🚧</div>
        </v-row>
        <v-row class="ma-0">
          <div class="token-text-h4 mt-4 mx-auto">This section is under construction</div>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';
export default class WIPPanel extends Vue {

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';
.WIPPanel {
  background: $bg-warning;
  display: flex;
  min-height: 100%;
  min-width: 100%;
  border-radius: 8px;
  border: 1px $color-neutral-grey-60 solid;
  color: $text-warning;
  .contruction-icon {
    font-size: 48px;
  }
}

</style>
