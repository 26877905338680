import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "homeView full-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_FransysSignature = _resolveComponent("FransysSignature")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_List = _resolveComponent("List")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ModalCGU = _resolveComponent("ModalCGU")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.user || (_ctx.shouldGoDirectToProject && _ctx.CGUAccepted))
      ? (_openBlock(), _createBlock(_component_Spinner, {
          key: 0,
          fullScreen: true
        }))
      : (_ctx.CGUAccepted)
        ? (_openBlock(), _createBlock(_component_v_col, {
            key: 1,
            class: "pa-0 pa-12 mx-auto homeView-content"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { class: "ma-0 full-view" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { class: "pa-0 col-menu fixed" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { class: "ma-auto pb-12 pl-2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_FransysSignature)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_row, { class: "ma-0" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_List, {
                            class: "full-width",
                            items: _ctx.list,
                            onSelect: _ctx.onSelect
                          }, null, 8, ["items", "onSelect"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { class: "pa-0 mr-12 col-menu" }),
                  _createVNode(_component_v_col, { class: "pa-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_router_view)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            (!_ctx.CGUAccepted)
              ? (_openBlock(), _createBlock(_component_ModalCGU, { key: 0 }))
              : _createCommentVNode("", true)
          ], 64))
  ]))
}