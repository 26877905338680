<template>
  <div class="list">
    <div 
      class="list-btn-deploy"
      :class="{deployable: deployable, noTitle: !title, disabled:disabled}"
      @click="deploy"
    >
      <div class="list-btn-deploy-text" v-if="title">
        {{title}}
      </div>
      <div v-if="deployable" class="list-btn-deploy-icon" :class="{deployed : deployed}">
        <Icon icon="caret-down"/>
      </div>
    </div>
    <HeightDeployer v-model="deployed">
      <div class="list-items" :class="{deployed : deployed}" ref="listItems">
        <template v-if="loading">
          <ListElement
            :skeleton="true"
            v-for="i in 3" :key="'ListElementSkeleton-'+i"
          />
        </template>
        <template v-else>

          <template v-if="!internalItems.length">
            <div class="no-result ma-auto token-text-medium">
              No result found
            </div>
          </template>

          <template v-else>
            <ListElement
              v-for="(item,index) of internalItems"
              :key="('list-item-' + title + '-' + index)"
              v-model:selected="item.selected"
              :text="item.text"
              @click="selectItem(item)"
              :icon="item.icon"
              :logo="item.logo"
              :flag="item.flag"
              :subtext="item.subtext"
              :iconSubtext="item.iconSubtext"
              :badgeLabel="item.label"
              :iconRigth="item.showArrow ? 'arrow-right' : ''"
              :class="item.class"
              :separator="item.separator"
              :disabled="disabled || item.disabled"
            />
          </template>
        </template>
        
    </div>
    </HeightDeployer>
  </div>
</template>


<script lang="ts">
import { Vue, prop, Options } from 'vue-class-component';
import ListElement from '@/components/UIElements/ListElement.vue'
import Icon from '@/components/UIElements/Icon.vue'
import HeightDeployer from '@/components/UIElements/HeightDeployer.vue'
import { Watch } from 'vue-property-decorator';

export interface ListItem {
  text?:string;
  subtext?:string;
  label?:string;
  showArrow?:boolean;
  selected?:boolean;
  id?:string;
  icon?:string;
  logo?:string;
  flag?:string;
  value?:string | null;
  iconSubtext?:string;
  disabled?:boolean;
  separator?:boolean;
  class?:string;
  meta?:any;
}

class Props {
  items: ListItem[] = prop({
    required: true,
  });
  title?: string = prop({
    required: false,
  });
  deployable?: boolean = prop({
    required: false,
  });
  disabled?: boolean = prop({
    required: false,
  });
  loading?: boolean = prop({
    required: false,
  });
}
@Options({
  components: {
    ListElement,
    Icon,
    HeightDeployer
  },
})
export default class List extends Vue.with(Props) {
  deployed = this.deployable ? false : true
  internalItems = JSON.parse(JSON.stringify(this.items))

  selectItem(item:ListItem) {
    if(!this.disabled && !item.disabled) {
      this.internalItems.forEach((i:ListItem) => i.selected = false)
      item.selected = true
      this.$emit('select', item)
    }
  }

  deploy() {
    if(this.deployable) {
      if(this.deployed) {
        this.collapse()
      } else {
        this.expand()
      }
    }
  }

  collapse() {
    this.deployed = false
  }

  expand() {
    this.deployed = true
  }

  @Watch('items', {deep:true})
  onItemsChange () {
    this.internalItems = JSON.parse(JSON.stringify(this.items))
  }
}



</script>

<style lang="scss" scoped>
@import '@/css/variables';
.list {
  min-height: 40px;
  .list-btn-deploy {
    width: 100%;
    overflow: hidden;
    padding: 0;
    justify-content: flex-start;
    display: flex;
    height: 40px;
    font-weight: 450;
    font-size: 12px;
    line-height: 20px;

    &.noTitle {
      height: 0;
    }
    &.deployable {
      cursor: pointer;
    }

    &:hover {
      .list-btn-deploy-icon {
        background: $color-background-button-text-tertiary-hover;
      }
    }
    &:active {
      .list-btn-deploy-icon {
        background: $color-background-button-text-tertiary-pressed;
      }
    }
    .list-btn-deploy-text {
      margin-top:auto;
      margin-bottom:auto;
      margin-left: 16px;
    }
    .list-btn-deploy-icon {
      display: flex;
      border-radius: 8px;
      width: 24px;
      height: 24px;
      margin-top:auto;
      margin-bottom:auto;
      margin-left: auto;
      margin-right:12px;
      transition: transform 0.2s ease-in-out;
      &.deployed {
        transform: rotate(-180deg);
      }
    }
  }

  .list-items {
    display: flex;
    flex-direction: column;
    height: 0px;
    width: 100%;
    transition: height 0.2s ease-in-out, opacity 0.2s ease-in-out, padding 0.2s ease-in-out;
    opacity: 0;
    &.deployed {
      height: auto;
      opacity: 1;
      padding-top: 8px;
    }
  }

  .no-result {

  }
}
</style>
