<template>
  <div class="createMagicLinkBar bar">
    <template v-if="loading">
      <Spinner class="ma-auto"/>
    </template>
    <template v-else-if="tooManyRequest">
      <v-img src="@/assets/illustrations/too_many_request.png" height="64px" width="96px" class="mx-auto mb-6"/>
      <div class="token-text-h4 mb-2 mx-auto">
        Too many log in requests
      </div>
      <div class="token-text-regular token-text-color-secondary mx-auto">
        You can only make 1 log in request per minute.
      </div>
      <div class="token-text-regular token-text-color-secondary mx-auto">
        Please wait to make another one for
      </div>
      <div class="token-text-h5 mx-auto">
        {{ email }}
      </div>
      <div class="full-width pt-6">
        <CustomButton :level="$enums.ButtonLevels.Primary" class="full-width" @click="generateLink" :loading="tooManyRequestButtonLoading">
          Generate Link
        </CustomButton>
      </div>
    </template>
    <template v-else>
      <v-img src="@/assets/illustrations/mail.svg" height="64px" width="96px" class="mx-auto mb-6"/>
      <div class="token-text-h4 mb-2 mx-auto">
        Check your email to log in
      </div>
      <div class="token-text-regular token-text-color-secondary mx-auto">
        We sent you a login link on the following address
      </div>
      <div class="token-text-h5 mx-auto">
        {{ email }}
      </div>
      <div class="token-text-regular token-text-color-secondary mx-auto">
        Click on the link to log in
      </div>
      <CustomButton v-if="provider" :level="$enums.ButtonLevels.Primary" @click="openInbox" class="open-inbox-btn mx-auto mt-6" >
        <span>
          Open inbox
        </span>
        <Icon icon="share" class="ml-2" color="white"></Icon>
      </CustomButton>
    </template>
  </div>
</template>

<script lang="ts">
import API from '@/api/wrapper';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue';
import Icon from '@/components/UIElements/Icon.vue';
import Spinner from '@/components/UIElements/Spinner.vue';
import { Vue, Options, prop } from 'vue-class-component';

enum PROVIDERS {
  GMAIL = 'gmail',
  OUTLOOK = 'outlook'
}

class Props {
  email: string = prop({
    required: true,
  });
}


@Options({
  components: {
    CustomButton,
    CustomTextInput,
    Icon,
    Spinner,
  },
})
export default class CreateMagicLinkBar extends Vue.with(Props) {
  loading = false
  tooManyRequest = false
  tooManyRequestButtonLoading = false


  mounted(): void {
    this.generateLink()
  }

  generateLink () {
    this.tooManyRequest = false
    this.loading = true
    API.session.getCSRF().then(() => {
      API.user.create(this.email)
      .then()
      .catch((err) => {
        this.$store.dispatch('toastrs/removeDescriptor', err?.toastr)
        if(err.response.status === 422) {
          API.session.createMagicLink(this.email)
          .then()
          .catch((err2) => {
            this.$store.dispatch('toastrs/removeDescriptor', err2?.toastr)
            if(err2.response.status === 429) {
              this.tooManyRequest = true
              this.tooManyRequestButtonLoading = true
              setTimeout(() => {
                this.tooManyRequestButtonLoading = false
              },60000)
            }
          })
        }
      })
      .finally(() => {
        this.loading = false
      })
    })
  }

  openInbox (): void {
    switch(this.provider) {
      case PROVIDERS.GMAIL:
        window.location.href = 'https://mail.google.com/mail/u/0/#search/no-reply%40fransys.cloud'
        break;
      case PROVIDERS.OUTLOOK:
        window.location.href = 'https://outlook.live.com/mail/0/'
        break;
      default:
        break;
    }
  }

  get provider ():string | null {
    let prov:string | null = null
    if(this.email) {
      const regExGmail = /(@gmail.com)/
      const regExOutlook = /(@outlook.com)|(@hotmail.com)|(@hotmail.fr)/

      if(this.email.match(regExGmail)) {
        prov = PROVIDERS.GMAIL
      } else if(this.email.match(regExOutlook)) {
        prov = PROVIDERS.OUTLOOK
      }
    }
    return prov
  }
}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

.createMagicLinkBar {
  padding: 40px;
  display: flex;
  flex-direction: column;
  width: 480px;
  box-shadow: -4px 0px 16px 0px rgba(1, 19, 194, 0.08), 4px 0px 16px 0px rgba(127, 61, 244, 0.08), 0px -4px 16px 0px rgba(243, 83, 0, 0.08), 0px 4px 16px 0px rgba(255, 114, 202, 0.08) !important;
}
</style>
