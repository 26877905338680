import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_col, { class: "flat-gauge pa-0" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, {
        class: _normalizeClass(["gauge-area ma-0", {pulsating : _ctx.pulsating}]),
        style: _normalizeStyle((_ctx.height ? 'height : '+ _ctx.height + 'px; ' : ''))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (value, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: 'gauge-segment-'+index
            }, [
              (index !== 0)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 0,
                    class: "pa-0 gauge-segment",
                    style: _normalizeStyle('max-width:' + _ctx.sizes[index] + '%; min-width:' + _ctx.sizes[index] + '%;' + 'background-color:' + (_ctx.computedColors ? _ctx.computedColors[index - 1] : 'black'))
                  }, null, 8, ["style"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]),
        _: 1
      }, 8, ["class", "style"])
    ]),
    _: 1
  }))
}