<template>
  <div class="notFoundWidgetMonitoring">
    <div class="token-text-h5 ma-auto">
      Widget type not found : {{ widgetAPI.attributes.type }}
    </div>
  </div>
</template>

<script lang="ts">
import { APIWidgetMonitoring } from '@/typesAPI';
import { Vue, prop } from 'vue-class-component';


class Props {
  widgetAPI: APIWidgetMonitoring = prop({
    required: true,
  });
}

export default class NotFoundWidgetMonitoring extends Vue.with(Props) {}

</script>

<style lang="scss" scoped>
@import '@/css/variables';

.notFoundWidgetMonitoring {
  display: flex;
  width: 100%;
  height: 100%;
  color: $text-error;
  background-color: $bg-error;
}
</style>
