export default function LNPrefix(codeEditor:HTMLTextAreaElement, lineCounter:HTMLTextAreaElement) {
  
  let lineCountCache = 0;
  function line_counter() {
    const lineCount = codeEditor?.value.split('\n').length;
    const outarr = [];
    if (lineCountCache !== lineCount) {
        for (let x = 0; x < lineCount; x++) {
            outarr[x] = (x + 1);
        }
        lineCounter.value = outarr.join('\n');
    }
    lineCountCache = lineCount;
  }

  codeEditor.addEventListener('scroll', () => {
    lineCounter.scrollTop = codeEditor.scrollTop;
    lineCounter.scrollLeft = codeEditor.scrollLeft;
    const scrollBarWidth = 16
    lineCounter.style.paddingBottom = (scrollBarWidth + (codeEditor.scrollWidth > codeEditor.clientWidth ? scrollBarWidth : 0)) + 'px';
  });

  codeEditor.addEventListener('input', () => {
    line_counter();
  });

  codeEditor.addEventListener('keydown', (e) => {
    const { keyCode } = e;
    const { value, selectionStart, selectionEnd } = codeEditor;

    if (keyCode === 9) {  // TAB = 9
      e.preventDefault();
      codeEditor.value = value.slice(0, selectionStart) + '\t' + value.slice(selectionEnd);
      codeEditor.setSelectionRange(selectionStart+2, selectionStart+2)
    }
  });

    // codeEditor.value = htmlTemplateStr;
    line_counter();
}