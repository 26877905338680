<template>
  <div class="node-detail-logs full-width d-flex" >
    <template v-if="!initialLoaded">
      <Spinner class="ma-auto"/>
    </template>
    <template v-else-if="emptyState">
      <v-col class="pa-0 my-auto">
        <v-row class="ma-0">
          <v-img src="@/assets/illustrations/empty_logs.svg" height="80px" width="107px" class="mx-auto mb-4"/>
        </v-row>
        <v-row class="ma-0 token-text-h5 token-text-color-primary mb-2 mx-auto fit-content">
          No logs to show
        </v-row>
        <v-row class="ma-0 token-text-regular token-text-color-secondary mx-auto fit-content">
          Deploy your project to start collecting logs
        </v-row>
      </v-col>
    </template>
    <template v-else>
      <v-col class="pa-0">
        <v-row class="ma-2">
          <div class="token-text-medium my-2">
            Logs
          </div>
          <CustomTextInput class="ml-auto" :searchStyle="true" placeholder="Search logs" v-model="query" @update:modelValue="onQueryUpdate"/>
          <CustomButton :level="$enums.ButtonLevels.Tertiary" icon="arrow-expand" class="my-auto ml-2 px-2" @click="toggleFullscreen"/>
          <CustomButton :level="$enums.ButtonLevels.Secondary" icon="caret-down" class="my-auto ml-2 px-2 btn-scroll" :class="{'show-btn-scroll' : showBtnScroll}" @click="scrollToBottom(true)"/>
        </v-row>
        <v-row class="ma-0 separator-border-bottom">
        </v-row>
        <v-row class="ma-0 log-container" ref="logContainer" @scroll="onScroll">
          <v-col class="pa-0 ma-2" >
            <Icon class="lazy-load-up-anchor mb-2" icon="spinner" ref="lazyLoadSpinner" :class="{invisible: topLine === 1 || query}"/>
            <template v-for="log of logs" :key="log.id">
              <Tooltip :hoverOnly="true">
                <template #target>
                  <LogLine :log="log" :hideData="true" class="mb-2 logline"/>
                </template>
                <template #content>
                  <LogLine :log="log" :hideMessage="true" class="fit-content"/>
                </template>
              </Tooltip>
            </template>
          </v-col>
        </v-row>
      </v-col>
      <Modal v-model:visible="fullscreen" :pseudoFullScreen="true" :transparentExternal="true">
        <template #header>
          <v-row class="ma-2" v-show="fullscreen">
            <div class="token-text-medium my-2">
              Logs
            </div>
            <CustomTextInput class="ml-auto" :searchStyle="true" placeholder="Search logs" v-model="query" @update:modelValue="onQueryUpdate"/>
            <CustomButton :level="$enums.ButtonLevels.Tertiary" icon="arrow-shrink" class="my-auto ml-2 px-2" @click="toggleFullscreen"/>
            <CustomButton :level="$enums.ButtonLevels.Secondary" icon="caret-down" class="my-auto ml-2 px-2 btn-scroll-fullscreen" :class="{'show-btn-scroll' : showBtnScroll}" @click="scrollToBottom(true)"/>
          </v-row>
          <v-row class="ma-0 separator-border-bottom">
          </v-row>
        </template>
        <v-col class="pa-0" v-show="fullscreen">
          <v-row class="ma-0 log-container-fullscreen" ref="logContainerFullscreen" @scroll="onScroll">
            <v-col class="pa-0 ma-2" >
              <Icon class="lazy-load-up-anchor mb-2" icon="spinner" ref="lazyLoadSpinnerFullscreen" :class="{invisible: topLine === 1 || query}"/>
              <template v-for="log of logs" :key="log.id">
                  <LogLine :log="log" />
              </template>
            </v-col>
          </v-row>
        </v-col>
      </Modal>
    </template>

  </div>
</template>

<script lang="ts">
import { Vue, prop, Options } from 'vue-class-component'
import { APIBlock, APILiveLog } from '@/typesAPI'
import API from '@/api/wrapper'
import LogLine from '@/components/UIElements/LogLine.vue';
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue';
import Icon from '@/components/UIElements/Icon.vue';
import Tooltip from '@/components/UIElements/Tooltip.vue';
import { nextTick } from 'vue';
import { Watch } from 'vue-property-decorator';
import Modal from '@/components/UIElements/Modal.vue';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import Spinner from '@/components/UIElements/Spinner.vue';

class Props {
  blockAPI: APIBlock = prop({
    required: true,
  });
}

@Options({
  components: {
    LogLine,
    CustomTextInput,
    Icon,
    Tooltip,
    CustomButton,
    Modal,
    Spinner
  },
})
export default class NodeDetailLogs extends Vue.with(Props) {

  logs:APILiveLog[] = []
  total = 0
  topLine = 0
  interval:any = null
  query = ""
  lazyLoading = true
  needToScroll = false
  lastHeight = 0
  lazyLoadingMinTime = 500
  waitingLazyLoadUp = false
  fullscreen = false
  inited = false
  observing = false
  initialLoaded = false
  showBtnScroll = false

  @Watch('emptyState', {immediate: true})
  onEmptyStateChange() {
    if(!this.emptyState && !this.observing ) {
      setTimeout(() => {
        const observer = new IntersectionObserver(this.onLazyLoaderShow, {
          root: this.logContainerEl,   // default is the viewport
          threshold: .9 // percentage of target's visible area. Triggers "onLazyLoaderShow"
        })
        // @ts-ignore
        observer.observe(this.$refs?.lazyLoadSpinner?.$el as HTMLElement)
      }, this.lazyLoadingMinTime)

      setTimeout(() => {
        const observer = new IntersectionObserver(this.onLazyLoaderShow, {
          root: this.logContainerFullscreenEl,   // default is the viewport
          threshold: .9 // percentage of target's visible area. Triggers "onLazyLoaderShow"
        })
        // @ts-ignore
        observer.observe(this.$refs?.lazyLoadSpinnerFullscreen?.$el as HTMLElement)
      }, this.lazyLoadingMinTime)
      this.observing = true
    }
  }

  mounted(): void {
      this.init()
  }

  // ATENTION : Il peux y avoir des millier de lignes, donc mettre un max sur la taille 
  init() {
    if(!this.inited) {
      if(this.blockAPI) {
        API.monitoring.liveLogs(this.blockAPI.id, null , 100, 'desc')
        .then(({logList, total}) => {
          this.logs = logList.reverse()
          this.total = total
          this.topLine = total - this.logs.length + 1

          clearInterval(this.interval)
          this.interval = setInterval(this.poll,1000)
          this.scrollToBottom()
        })
        .catch((err) => {
          this.$store.dispatch('toastrs/removeDescriptor', err.toastr)
        })
        .finally(() => {
          this.initialLoaded = true
        })
      }

      this.inited = true
    }
  }

  onLazyLoaderShow(entries:any) {

    if(entries[0].isIntersecting) {
      if(this.lazyLoading) {
        this.waitingLazyLoadUp = true
      } else {
        this.lazyLoad('up')
      }
    }

  }

  beforeUnmount(): void {
    clearInterval(this.interval)
  }
  
  unmounted(): void {
    clearInterval(this.interval)
  }

  onScroll(event:any) {
    const elem = event.target
    this.showBtnScroll = elem?.scrollTop < (elem?.scrollHeight - elem?.clientHeight) - (this.fullscreen ? 1000 : 2000)
  }

  scrollToBottom(smouth?:boolean) {
    nextTick(() => {
      setTimeout(() => {

        const elem = this.computedLogContainerEl
        if(elem) {
          elem?.scrollTo({
            top: elem?.clientHeight + elem?.scrollHeight,
            left: 0,
            behavior: smouth ? "smooth" : undefined,
          })
        }
      })

      setTimeout(() => {
        this.lazyLoading = false
      },this.lazyLoadingMinTime)

    })
  }

  get computedLogContainerEl ():HTMLElement {
    return this.fullscreen ? this.logContainerFullscreenEl : this.logContainerEl
  }

  get logContainerEl ():HTMLElement {
    // @ts-ignore
    return this.$refs?.logContainer?.$el as HTMLElement
  }

  get logContainerFullscreenEl ():HTMLElement {
    // @ts-ignore
    return this.$refs?.logContainerFullscreen?.$el as HTMLElement
  }

  
  get emptyState ():boolean {
    return this.logs.length === 0
  }

  @Watch('lazyLoading')
  onLazyLoadingChange() {
    if(!this.lazyLoading && this.waitingLazyLoadUp) {
      this.lazyLoad('up')
    }
  }

  toggleFullscreen() {
    this.fullscreen = !this.fullscreen
    this.scrollToBottom()
  }

  lazyLoad(direction?:string) {
    if(!this.lazyLoading && this.logs.length < this.total) {
      this.lazyLoading = true
      this.waitingLazyLoadUp = false

      API.monitoring.liveLogs(this.blockAPI.id, direction === "up" ? this.logs[0]?.attributes?.time : null , 50, 'desc')
      .then(({logList, total}) => {
        this.lastHeight = this.computedLogContainerEl?.scrollHeight

        if(direction === "up") {
          this.logs.unshift(...logList.reverse())
        } else {
          this.logs.push(...logList)
        }

        nextTick(() => {
          setTimeout(() => {
            this.computedLogContainerEl?.scrollTo({
              top: this.computedLogContainerEl?.scrollHeight - this.lastHeight,
              left: 0,
            })
          },50)
          setTimeout(() => {
            this.lazyLoading = false
          }, this.lazyLoadingMinTime)
        })
        this.total = total
        this.topLine = total - this.logs.length + 1
      })
    }
   
  }

  poll(): void {
    if(!this.query) {
 
      API.monitoring.liveLogs(this.blockAPI.id, this.logs[this.logs.length - 1].attributes.time, 50, 'asc')
      .then(({logList, total}) => {
        this.logs.push(...logList)
        this.total = total
      })
      .catch((err) => {
        this.$store.dispatch('toastrs/removeDescriptor', err.toastr)
      })

      nextTick(() => {
        // @ts-ignore
        const elem = this.$refs?.logContainer?.$el as HTMLElement
        if(elem) {
          if( Math.abs(elem.scrollTop - (elem.scrollHeight - elem.clientHeight)) < 1) {
            setTimeout(() => {
              elem?.scrollTo({
                top: elem?.clientHeight + elem?.scrollHeight,
                left: 0,
              })
            },100)
          }

        }
      })
     

    }
  }

  onQueryUpdate(): void {
    API.monitoring.liveLogs(this.blockAPI.id, null, 100, this.query ? 'asc' : 'desc', this.query)
    .then(({logList, total}) => {
      if(this.query) {
        this.logs = logList
      } else {
        this.logs = logList.reverse()
        setTimeout(() => {
          this.scrollToBottom()
        },100)
      }
      this.total = total
    })
  }

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

.node-detail-logs {
  .invisible {
    display: none;
  }
  .log-container {
    height: calc(90vh - 188px);
    overflow: auto;
  }

  .btn-scroll {
    position: absolute;
    bottom:76px;
    right:24px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    &.show-btn-scroll {
      opacity: 1;
    }
  }
  
  .logline {
    &:deep(*) {
      transition: color 0.2s ease-in-out;
      &:hover {
        color: $color-brand !important
      }
    }
  }
}

</style>

<style lang="scss">
.log-container-fullscreen {
  height: calc(100vh - 94px);
  overflow: auto;
}

.btn-scroll-fullscreen {
  position: absolute;
  bottom:8px;
  right:24px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  &.show-btn-scroll {
    opacity: 1;
  }
}
</style>