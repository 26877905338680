import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/illustrations/empty_logs.svg'


const _hoisted_1 = { class: "node-detail-logs full-width d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_CustomTextInput = _resolveComponent("CustomTextInput")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_LogLine = _resolveComponent("LogLine")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.initialLoaded)
      ? (_openBlock(), _createBlock(_component_Spinner, {
          key: 0,
          class: "ma-auto"
        }))
      : (_ctx.emptyState)
        ? (_openBlock(), _createBlock(_component_v_col, {
            key: 1,
            class: "pa-0 my-auto"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { class: "ma-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_img, {
                    src: _imports_0,
                    height: "80px",
                    width: "107px",
                    class: "mx-auto mb-4"
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, { class: "ma-0 token-text-h5 token-text-color-primary mb-2 mx-auto fit-content" }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode(" No logs to show ")
                ])),
                _: 1
              }),
              _createVNode(_component_v_row, { class: "ma-0 token-text-regular token-text-color-secondary mx-auto fit-content" }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode(" Deploy your project to start collecting logs ")
                ])),
                _: 1
              })
            ]),
            _: 1
          }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            _createVNode(_component_v_col, { class: "pa-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "ma-2" }, {
                  default: _withCtx(() => [
                    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "token-text-medium my-2" }, " Logs ", -1)),
                    _createVNode(_component_CustomTextInput, {
                      class: "ml-auto",
                      searchStyle: true,
                      placeholder: "Search logs",
                      modelValue: _ctx.query,
                      "onUpdate:modelValue": [
                        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.query) = $event)),
                        _ctx.onQueryUpdate
                      ]
                    }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                    _createVNode(_component_CustomButton, {
                      level: _ctx.$enums.ButtonLevels.Tertiary,
                      icon: "arrow-expand",
                      class: "my-auto ml-2 px-2",
                      onClick: _ctx.toggleFullscreen
                    }, null, 8, ["level", "onClick"]),
                    _createVNode(_component_CustomButton, {
                      level: _ctx.$enums.ButtonLevels.Secondary,
                      icon: "caret-down",
                      class: _normalizeClass(["my-auto ml-2 px-2 btn-scroll", {'show-btn-scroll' : _ctx.showBtnScroll}]),
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.scrollToBottom(true)))
                    }, null, 8, ["level", "class"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, { class: "ma-0 separator-border-bottom" }),
                _createVNode(_component_v_row, {
                  class: "ma-0 log-container",
                  ref: "logContainer",
                  onScroll: _ctx.onScroll
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { class: "pa-0 ma-2" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Icon, {
                          class: _normalizeClass(["lazy-load-up-anchor mb-2", {invisible: _ctx.topLine === 1 || _ctx.query}]),
                          icon: "spinner",
                          ref: "lazyLoadSpinner"
                        }, null, 8, ["class"]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logs, (log) => {
                          return (_openBlock(), _createBlock(_component_Tooltip, {
                            key: log.id,
                            hoverOnly: true
                          }, {
                            target: _withCtx(() => [
                              _createVNode(_component_LogLine, {
                                log: log,
                                hideData: true,
                                class: "mb-2 logline"
                              }, null, 8, ["log"])
                            ]),
                            content: _withCtx(() => [
                              _createVNode(_component_LogLine, {
                                log: log,
                                hideMessage: true,
                                class: "fit-content"
                              }, null, 8, ["log"])
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onScroll"])
              ]),
              _: 1
            }),
            _createVNode(_component_Modal, {
              visible: _ctx.fullscreen,
              "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.fullscreen) = $event)),
              pseudoFullScreen: true,
              transparentExternal: true
            }, {
              header: _withCtx(() => [
                _withDirectives(_createVNode(_component_v_row, { class: "ma-2" }, {
                  default: _withCtx(() => [
                    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "token-text-medium my-2" }, " Logs ", -1)),
                    _createVNode(_component_CustomTextInput, {
                      class: "ml-auto",
                      searchStyle: true,
                      placeholder: "Search logs",
                      modelValue: _ctx.query,
                      "onUpdate:modelValue": [
                        _cache[2] || (_cache[2] = ($event: any) => ((_ctx.query) = $event)),
                        _ctx.onQueryUpdate
                      ]
                    }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                    _createVNode(_component_CustomButton, {
                      level: _ctx.$enums.ButtonLevels.Tertiary,
                      icon: "arrow-shrink",
                      class: "my-auto ml-2 px-2",
                      onClick: _ctx.toggleFullscreen
                    }, null, 8, ["level", "onClick"]),
                    _createVNode(_component_CustomButton, {
                      level: _ctx.$enums.ButtonLevels.Secondary,
                      icon: "caret-down",
                      class: _normalizeClass(["my-auto ml-2 px-2 btn-scroll-fullscreen", {'show-btn-scroll' : _ctx.showBtnScroll}]),
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.scrollToBottom(true)))
                    }, null, 8, ["level", "class"])
                  ]),
                  _: 1
                }, 512), [
                  [_vShow, _ctx.fullscreen]
                ]),
                _createVNode(_component_v_row, { class: "ma-0 separator-border-bottom" })
              ]),
              default: _withCtx(() => [
                _withDirectives(_createVNode(_component_v_col, { class: "pa-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, {
                      class: "ma-0 log-container-fullscreen",
                      ref: "logContainerFullscreen",
                      onScroll: _ctx.onScroll
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { class: "pa-0 ma-2" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Icon, {
                              class: _normalizeClass(["lazy-load-up-anchor mb-2", {invisible: _ctx.topLine === 1 || _ctx.query}]),
                              icon: "spinner",
                              ref: "lazyLoadSpinnerFullscreen"
                            }, null, 8, ["class"]),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logs, (log) => {
                              return (_openBlock(), _createBlock(_component_LogLine, {
                                key: log.id,
                                log: log
                              }, null, 8, ["log"]))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["onScroll"])
                  ]),
                  _: 1
                }, 512), [
                  [_vShow, _ctx.fullscreen]
                ])
              ]),
              _: 1
            }, 8, ["visible"])
          ], 64))
  ]))
}