import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["type", "placeholder", "value", "maxlength", "readonly"]
const _hoisted_2 = {
  class: "lineCounter",
  wrap: "off",
  readonly: "",
  ref: "lineCounter"
}
const _hoisted_3 = ["type", "placeholder", "value", "maxlength", "readonly"]
const _hoisted_4 = ["type", "placeholder", "value", "maxlength", "readonly"]
const _hoisted_5 = {
  key: 0,
  class: "error-display"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["custom-text-input-container d-flex", (_ctx.error ? ' has-error ': '') 
      + (false ? ' has-error-fixed ': '' )
      + (_ctx.icon ? ' has-icon ': '') 
      + (_ctx.disabled ? ' disabled ' : '' ) 
      + (_ctx.disabledBox ? ' disabledBox ' : '' ) 
      + (_ctx.searchStyle ? ' ' + _ctx.searchStyle + ' ' : '') 
      + (_ctx.plain ? ' plain ' : '') 
      + 'type-'+_ctx.computedType])
    }, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            class: "custom-text-input-icon my-auto mr-3",
            icon: _ctx.icon
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true),
      (_ctx.computedType === 'textarea')
        ? (_openBlock(), _createElementBlock("textarea", {
            key: 1,
            class: _normalizeClass(["custom-text-input",  (_ctx.token ? _ctx.token : '')]),
            type: _ctx.computedType,
            placeholder: _ctx.placeholder,
            value: _ctx.modelValue,
            autocomplete: "false",
            onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
            onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('blur', _ctx.modelValue))),
            onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('change'))),
            onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.$emit('blur', _ctx.modelValue)), ["enter"])),
            maxlength: _ctx.max ? _ctx.max : -1,
            ref: "textarea",
            readonly: _ctx.readonly
          }, null, 42, _hoisted_1))
        : (_ctx.computedType === 'code')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _createElementVNode("textarea", _hoisted_2, null, 512),
              _createElementVNode("textarea", {
                class: _normalizeClass(["custom-text-input codeEditor",  (_ctx.token ? _ctx.token : '')]),
                type: _ctx.computedType,
                placeholder: _ctx.placeholder,
                value: _ctx.modelValue,
                autocomplete: "false",
                onInput: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
                onBlur: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('blur', _ctx.modelValue))),
                onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('change'))),
                onKeyup: _cache[7] || (_cache[7] = _withKeys(($event: any) => (_ctx.$emit('blur', _ctx.modelValue)), ["enter"])),
                maxlength: _ctx.max ? _ctx.max : -1,
                ref: "codeEditor",
                readonly: _ctx.readonly
              }, null, 42, _hoisted_3)
            ], 64))
          : (_openBlock(), _createElementBlock("input", {
              key: 3,
              class: _normalizeClass(["custom-text-input", (_ctx.shareButton ? ' with-share ' :'') + (_ctx.ellipsis ? ' ellipsis ' : '') + (_ctx.token ? _ctx.token : '')]),
              type: _ctx.computedType,
              placeholder: _ctx.placeholder,
              value: _ctx.modelValue,
              autocomplete: "false",
              onInput: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
              onBlur: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('blur', _ctx.modelValue))),
              onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('change'))),
              onKeyup: _cache[11] || (_cache[11] = _withKeys(($event: any) => (_ctx.$emit('blur', _ctx.modelValue)), ["enter"])),
              maxlength: _ctx.max ? _ctx.max : -1,
              readonly: _ctx.readonly
            }, null, 42, _hoisted_4)),
      (_ctx.shareButton)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 4,
            icon: "share",
            class: "custom-text-input-share",
            onClick: _ctx.onClickShare
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (_ctx.type === 'password')
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 5,
            icon: 'eye' + (_ctx.isRevealed ? '-off' : ''),
            class: "custom-text-input-reveal",
            onClick: _ctx.onClickReveal
          }, null, 8, ["icon", "onClick"]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.error && !_ctx.hideError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
  ]))
}