import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createBlock(_component_v_col, { class: "widgetMonitoringWrapper pa-0 d-flex-col" }, {
    default: _withCtx(() => [
      (_ctx.myWidgetComponent)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.myWidgetComponent), {
            key: 0,
            widgetAPI: _ctx.widgetAPI,
            customStart: _ctx.customStart,
            customEnd: _ctx.customEnd,
            period: _ctx.period
          }, null, 8, ["widgetAPI", "customStart", "customEnd", "period"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}