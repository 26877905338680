import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "WIPPanel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_row, { class: "ma-auto" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { class: "pa-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { class: "ma-0" }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("div", { class: "contruction-icon mx-auto" }, "🚧", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_v_row, { class: "ma-0" }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createElementVNode("div", { class: "token-text-h4 mt-4 mx-auto" }, "This section is under construction", -1)
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}