<template>
    <span class="logo-container" :class="{'full-size' : fullSize}">
      <span class="logo" ref="dom" :class="'logo-' + (color ? 'recolor-' : '') +logo" :style="color ? 'background:'+color : ''"></span>
    </span>
</template>

<script lang="ts">
import { Vue, prop } from 'vue-class-component';

class Props {
  logo: string = prop({
    required: true,
  });
  color?: string = prop({
    required: false,
  });
  fullSize?: boolean = prop({
    required: false,
  });
}

export default class Logo extends Vue.with(Props) {
}
</script>

<style lang="scss">
@import '@/css/variables';

.logo-container {
  display:flex;
  margin: auto;
  &.full-size {
    width: 100%;
    height: 100%;
  }
}
.logo {
  width: 16px;
  height: 16px;
  mask-size: 16px;
  background-size: 100% 100%;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  display: flex;
  margin: auto;
  transition: 0.2s background ease-in-out;
}

// Logos
// Fransys
.logo-fransys {
  mask-image: url(@/assets/logos/fransys.svg);
  width: 18px;
  height: 30px;
  background: #00003A !important;
}
.logo-fransys-txt {
  mask-image: url(@/assets/logos/fransys-txt.svg);
  width: 106.5px;
  height: 24px;
  mask-size: 106.5px;
  background: #00003A !important;
}
// DB
.logo-mariadb {
  background-image: url(@/assets/logos/mariadb.svg);
}
.logo-mysql {
  background-image: url(@/assets/logos/mysql.svg);
}
.logo-postgresql {
  background-image: url(@/assets/logos/postgresql.svg);
}
.logo-redis {
  background-image: url(@/assets/logos/redis.svg);
}
.logo-mongodb {
  background-image: url(@/assets/logos/mongodb.svg);
}
.logo-elasticsearch {
  background-image: url(@/assets/logos/elasticsearch.svg);
}
.logo-cassandra {
  background-image: url(@/assets/logos/cassandra.svg);
}
// PHP
.logo-laravel {
  background-image: url(@/assets/logos/laravel.svg);
}
.logo-slim {
  background-image: url(@/assets/logos/slim.svg);
}
.logo-cake {
  background-image: url(@/assets/logos/cake.svg);
}
.logo-codeignighter {
  background-image: url(@/assets/logos/codeignighter.svg);
}
.logo-fuelphp {
  background-image: url(@/assets/logos/fuelphp.svg);
}
.logo-phalcon {
  background-image: url(@/assets/logos/phalcon.svg);
}
.logo-symphony {
  background-image: url(@/assets/logos/symphony.svg);
}
.logo-yii {
  background-image: url(@/assets/logos/yii.svg);
}
// Kubernetes
.logo-kubernetes {
  background-image: url(@/assets/logos/kubernetes.svg);
}

// Workers
.logo-worker {
  background-image: url(@/assets/icons/worker.svg);
}

// Sockets
.logo-socket {
  background-image: url(@/assets/icons/socket.svg);
}
.logo-soketi {
  background-image: url(@/assets/logos/soketi.svg);
}

// Git
.logo-gitlab {
  background-image: url(@/assets/logos/gitlab.svg);
}
.logo-github {
  background-image: url(@/assets/logos/github.svg);
}

// Docker
.logo-docker {
  background-image: url(@/assets/logos/docker.svg);
}

// Code
.logo-code {
  background-image: url(@/assets/icons/code.svg);
}

// Other
.logo-other {
  background-image: url(@/assets/icons/cube.svg);
}
// Discord
.logo-discord {
  background-image: url(@/assets/logos/discord.svg);
}

// Front
.logo-vue {
  background-image: url(@/assets/logos/vue.svg);
}
.logo-react {
  background-image: url(@/assets/logos/react.svg);
}
.logo-angular {
  background-image: url(@/assets/logos/angular.svg);
}

// Provider
.logo-azure {
  background-image: url(@/assets/logos/azure.svg);
}
.logo-ovh {
  background-image: url(@/assets/logos/ovh.svg);
}
.logo-gcloud {
  background-image: url(@/assets/logos/gcloud.svg);
}
.logo-aws {
  background-image: url(@/assets/logos/aws.svg);
}
.logo-hetzner {
  background-image: url(@/assets/logos/hetzner.svg);
}
.logo-scaleway {
  background-image: url(@/assets/logos/scaleway.svg);
}



//RECOLORABLE
// DB
.logo-recolor-mariadb {
  mask-image: url(@/assets/logos/mariadb.svg);
}
.logo-recolor-mysql {
  mask-image: url(@/assets/logos/mysql.svg);
}
.logo-recolor-postgresql {
  mask-image: url(@/assets/logos/postgresql.svg);
}
.logo-recolor-redis {
  mask-image: url(@/assets/logos/redis.svg);
}
.logo-recolor-mongodb {
  mask-image: url(@/assets/logos/mongodb.svg);
}
.logo-recolor-elasticsearch {
  mask-image: url(@/assets/logos/elasticsearch.svg);
}
.logo-recolor-cassandra {
  mask-image: url(@/assets/logos/cassandra.svg);
}
// PHP
.logo-recolor-laravel {
  mask-image: url(@/assets/logos/laravel.svg);
}
.logo-recolor-slim {
  mask-image: url(@/assets/logos/slim.svg);
}
.logo-recolor-cake {
  mask-image: url(@/assets/logos/cake.svg);
}
.logo-recolor-codeignighter {
  mask-image: url(@/assets/logos/codeignighter.svg);
}
.logo-recolor-fuelphp {
  mask-image: url(@/assets/logos/fuelphp.svg);
}
.logo-recolor-phalcon {
  mask-image: url(@/assets/logos/phalcon.svg);
}
.logo-recolor-symphony {
  mask-image: url(@/assets/logos/symphony.svg);
}
.logo-recolor-yii {
  mask-image: url(@/assets/logos/yii.svg);
}
// Kubernetes
.logo-recolor-kubernetes {
  mask-image: url(@/assets/logos/kubernetes.svg);
}

// Workers
.logo-recolor-worker {
  mask-image: url(@/assets/icons/worker.svg);
}

// Sockets
.logo-recolor-socket {
  mask-image: url(@/assets/icons/socket.svg);
}
.logo-recolor-soketi {
  mask-image: url(@/assets/logos/soketi.svg);
}
// Git
.logo-recolor-gitlab {
  mask-image: url(@/assets/logos/gitlab.svg);
}
.logo-recolor-github {
  mask-image: url(@/assets/logos/github.svg);
}

// Docker
.logo-recolor-docker {
  mask-image: url(@/assets/logos/docker.svg);
}

// Other
.logo-recolor-other {
  mask-image: url(@/assets/icons/cube.svg);
}

// Front
.logo-recolor-vue {
  mask-image: url(@/assets/logos/vue.svg);
}
.logo-recolor-react {
  mask-image: url(@/assets/logos/react.svg);
}
.logo-recolor-angular {
  mask-image: url(@/assets/logos/angular.svg);
}

// Provider
.logo-azure {
  mask-image: url(@/assets/logos/azure.svg);
}
.logo-ovh {
  mask-image: url(@/assets/logos/ovh.svg);
}
.logo-gcloud {
  mask-image: url(@/assets/logos/gcloud.svg);
}
.logo-aws {
  mask-image: url(@/assets/logos/aws.svg);
}
.logo-hetzner {
  mask-image: url(@/assets/logos/hetzner.svg);
}
.logo-scaleway {
  mask-image: url(@/assets/logos/scaleway.svg);
}


</style>
