<template>
    <div class="toastr" :class="'severity-' + severity + (hidden ? ' hidden ' : '') + (disapearing ? ' disapearing ' : '')" :style="disapearing ? ('margin-top:-' + height + 'px') : ('z-index:' + position)" ref="DOMElement">
      <v-row class="ma-0 top-color-band">
      </v-row>
      <v-row class="ma-3">
        <v-col class="pa-0 fit-content" :class="{'my-auto': !(title && text)}">
          <Icon v-if="severity === $enums.Severity.DANGER" icon="cancel-square" />
          <Icon v-else-if="severity === $enums.Severity.WARNING" icon="warning" />
          <Icon v-else-if="severity === $enums.Severity.SUCCESS" icon="check-square" />
          <Icon v-else-if="severity === $enums.Severity.INFO" icon="info" />
        </v-col>
        <v-col class="pa-0 mx-3" :class="{'mt-n1' : title && text}">
          <v-row class="ma-0 token-text-medium mb-1" v-if="title">
            {{ $helpers.text.capitalize(title) }}
          </v-row>
          <v-row class="ma-0 token-text-regular" v-if="text">
            {{ text }}
          </v-row>
        </v-col>
        <v-col class="pa-0 fit-content ml-auto">
          <CustomButton :level="$enums.ButtonLevels.SmallIcon" icon="x" @click="close"/>
        </v-col>
      </v-row>
    </div>
</template>

<script lang="ts">
import { Vue, Options, prop } from 'vue-class-component';
import Icon from '@/components/UIElements/Icon.vue';
import CustomButton from '@/components/UIElements/CustomButton.vue';

class Props {
  severity: string = prop({
    required: true,
  });
  title: string = prop({
    required: true,
  });
  text: string = prop({
    required: true,
  });
  position: number = prop({
    required: true,
  });
}

@Options({
  components: {
    Icon,
    CustomButton
  },
})
export default class Toastr extends Vue.with(Props) {
  hidden = true
  disapearing = false
  height = 0
  timeoutDurationForSuccess = 5000
  mounted(): void {

    if(this.severity === this.$enums.Severity.SUCCESS) {
      setTimeout(() => {
        this.close()
      }, this.timeoutDurationForSuccess)
    }

    setTimeout(() => {
      this.hidden = false
      const DOMElement = this.$refs.DOMElement as HTMLElement;
      if(DOMElement) {
        this.height = DOMElement.getBoundingClientRect().height + 12
      }
    })
  }

  close() {
    this.disapearing = true
    setTimeout(() => {
      this.$emit('close')
    }, 200)
  }

}
</script>

<style lang="scss" scoped>
@import '@/css/variables'; 

.toastr {
  position: relative;
  width: fit-content;
  min-width: 352px;
  max-width: 568px;
  border-radius: 8px;
  border: 1px solid $color-neutral-grey-12;
  box-shadow: $shadow-z3;
  margin: 0 auto 0 auto;
  overflow: hidden;
  opacity: 1;
  transition: margin 1s ease-in-out, box-shadow 0.2s ease-in-out, opacity 0.2s ease-in-out;

  &.hidden {
    margin-top: -100vh;
  }
  &.disapearing {
    opacity: 0;
    z-index:-1;
    box-shadow: none;
    transition: margin 0.2s ease-in-out, box-shadow 0.2s ease-in-out, opacity 0.2s ease-in-out;
  }
}



.top-color-band {
  height: 4px;
}

.severity-danger {
  background-color: $bg-error;
  color:$text-error;
  .top-color-band {
    background:$color-orange;
  }
  :deep(.icon) {
    background-color: $color-orange !important;
  }
  :deep(.custom-button .icon) {
    background-color: $text-error !important;
  }
}
.severity-warning {
  background-color: $bg-warning;
  color:$text-warning;
  .top-color-band {
    background:$color-yellow;
  }
  :deep(.icon) {
    background-color: $color-yellow !important;
  }
  :deep(.custom-button .icon) {
    background-color: $text-warning !important;
  }
}
.severity-success {
  background-color: $bg-success;
  color:$text-success;
  .top-color-band {
    background:$color-green;
  }
  :deep(.icon) {
    background-color: $color-green !important;
  }
  :deep(.custom-button .icon) {
    background-color: $text-success !important;
  }
}

.severity-info {
  background-color: $bg-info;
  color:$text-info;
  .top-color-band {
    background:$color-blue;
  }
  :deep(.icon) {
    background-color: $color-blue !important;
  }
  :deep(.custom-button .icon) {
    background-color: $text-info !important;
  }
}

.severity-neutral {
  background-color: $color-neutral-grey-8;
  color:$color-neutral-grey-60;
  .top-color-band {
    background:$color-neutral-grey-20;
  }
  :deep(.icon) {
    background-color: $color-neutral-grey-20 !important;
  }
  :deep(.custom-button .icon) {
    background-color: $color-neutral-grey-60 !important;
  }
}


</style>