<template>
  <div class="deployment-modal bar text-align-left" :class="{open: open || isDraftView, reduced: isReduced, finished: isDeployFinished, deploying:deploying}" >
    <template v-if="isLoading">
      <Spinner class="ma-auto"/>
    </template>
    <template v-else>
      <!-- MODALS -->
      <ModalPurchaseCredit v-model:visible="showPurchaseCreditModal" :fullScreen="true"/>
      <ModalValidatePaymentMethodList v-model:visible="showValidatePaymentMethodList" :fullScreen="true" @success="onSuccessValidatePaymentMethodList"/>
      <ModalEditLimits v-model:visible="showEditLimits" :fullScreen="true" />
      <ModalClusterConfig :cluster="selectedCluster" :creating="creatingCluster" v-if="isSelectClusterView"/>
      <!-- END MODALS -->
      <v-col class="pa-0 ">
        <!-- Reduced view -->
        <template v-if="isReduced">
          <v-row class="ma-0 pa-2 deployment-modal-header">
            <div class="d-flex my-auto "  v-if="open">
              <CustomButton class="mr-2 my-auto" :level="$enums.ButtonLevels.SmallIcon" icon="caret-left" @click="toggleReduce" v-if="deploying"/>
            </div>
            <div class="d-flex my-auto token-text-medium mr-2" v-if="!hideNbChanges">
              <CustomButton class="nb-changes" :level="$enums.ButtonLevels.Tertiary" @click="openDraftView">{{ nbChanges }} change(s)</CustomButton>
            </div>
            <div class="d-flex pr-2 mr-auto my-auto"  v-if="currentCluster && !hideCluster">
              <Divider :vertical="true" class="mr-2" v-if="!hideNbChanges"/>
              <CustomButton class="nb-changes" :level="$enums.ButtonLevels.Tertiary" @click="openSelectClusterView">
                <div class="my-auto ellipsis cluster-name" >
                  {{ currentCluster?.attributes?.name }}
                </div>
                <Badge class="ml-2 my-auto" :label="$helpers.states.translateDeploymentStatusToLabel(clustersStatus[currentCluster.id])" :severity="$helpers.states.translateDeploymentStatusToSeverity(clustersStatus[currentCluster.id])" :icon="clustersStatus[currentCluster.id] === $enums.DeploymentStatus.DEPLOYING || clustersStatus[currentCluster.id] === $enums.DeploymentStatus.SHUTTING_DOWN ? 'spinner' : ''" iconColor="rgba(0, 0, 58, 1)"/>
              </CustomButton>
            </div>
            <CustomButton v-if="!myClusters.length"  class="ml-auto" @click="openSelectClusterView">Select cluster</CustomButton>
            <DeployButton v-else class="ml-auto" :forceDisable="getShouldDisablePublish" :draftViewText="draftViewText" :versionsToDeploy="versionsToDeploy"/>
          </v-row>
        </template>
        <!-- Full view -->
        <template v-else>
          <!-- Header -->
          <v-row class="ma-0 pa-2 deployment-modal-header" >
            <div class="my-auto d-flex-row" v-if="open">
              <template v-if="planExpanded">
                <CustomButton  class="mr-4 my-auto" :level="$enums.ButtonLevels.SmallIcon" icon="arrow-left" @click="closeExpandPlan" />
                <Logo :logo="getLogoFromDeploymentPlan(planExpanded)" class="mr-2" />
                <span class="token-text-medium">
                  {{ textFromDeploymentPlan(planExpanded) }}
                </span>
              </template>
              <template v-else>
                <CustomButton class="mr-2 my-auto" :level="$enums.ButtonLevels.SmallIcon" icon="x" @click="toggleReduce" />
              </template>
            </div>
            <div class="d-flex pr-2 mr-auto my-auto token-text-medium"  v-if="!hideNbChanges">
              <CustomButton class="nb-changes" :level="$enums.ButtonLevels.Tertiary" @click="openDraftView">{{ nbChanges }} change(s)</CustomButton>
            </div>
            <div class="d-flex ml-4 mr-auto my-auto token-text-medium" v-if="isSelectClusterView">
              Select cluster
            </div>
            <div class="d-flex pr-2 mr-auto my-auto" v-if="currentCluster && !hideCluster">
              <Divider :vertical="true" class="mr-2" v-if="!hideNbChanges"/>
              <CustomButton class="nb-changes" :level="$enums.ButtonLevels.Tertiary" @click="openSelectClusterView">
                <div class="my-auto ellipsis cluster-name" >
                  {{ currentCluster?.attributes?.name }}
                </div>
                <Badge class="ml-2 my-auto" :label="$helpers.states.translateDeploymentStatusToLabel(clustersStatus[currentCluster.id])" :severity="$helpers.states.translateDeploymentStatusToSeverity(clustersStatus[currentCluster.id])" :icon="clustersStatus[currentCluster.id] === $enums.DeploymentStatus.DEPLOYING || clustersStatus[currentCluster.id] === $enums.DeploymentStatus.SHUTTING_DOWN ? 'spinner' : ''" iconColor="rgba(0, 0, 58, 1)"/>
              </CustomButton>
            </div>
            <CustomButton v-if="!myClusters.length && !isSelectClusterView" class="ml-auto" @click="openSelectClusterView">Select cluster</CustomButton>
            <CustomButton v-else-if="isSelectClusterView"  class="ml-auto" @click="saveClusterSelect" :disabled="!tempClusterSelected || currentProject?.attributes?.status !== $enums.DeploymentStatus.OFFLINE">Save</CustomButton>
            <DeployButton v-else class="ml-auto" :forceDisable="getShouldDisablePublish" :draftViewText="draftViewText" :versionsToDeploy="versionsToDeploy"/>
          </v-row>
          <template v-if="open">
             <!-- Draft View -->
             <template v-if="isDraftView" >
              <v-row 
                v-for="deploymentPlan of deploymentPlansDraft" :key="'draftrow-'+deploymentPlan.id"
                class="deployment-plan-elem ma-0 mt-2" 
              >
                <Checkbox 
                  class="ml-4"
                  v-model="planSelectedToDeploy[deploymentPlan.id]"
                  :disabled="isKubernetesCluster(deploymentPlan)"
                  @update:modelValue="updateDeploymentPlanIncludeInVersion(deploymentPlan, $event)"
                />
                <ListElement 
                  :logo="getLogoFromDeploymentPlan(deploymentPlan)"
                  :noHover="true"
                  class="ml-4 list-elem-draft"
                >
                  <span class="token-text-medium">
                    {{ textFromDeploymentPlan(deploymentPlan) }}
                  </span>
                  <template #badge>
                    <v-row class="ma-0">
                      <template v-if="deploymentPlan">
                        <Badge 
                          :severity="deploymentPlanBadgeSeverity(deploymentPlan)"
                          :label="deploymentPlanBadgeLabel(deploymentPlan)"
                          :outlined="true"
                          :icon="deploymentPlanIcon(deploymentPlan)"
                        />
                      </template>
                    </v-row>
                  </template>
                </ListElement>
              </v-row>
            </template>
            <!-- Select Cluster View -->
            <template v-else-if="isSelectClusterView" >
              <div class="select-cluster-view">
                <template v-if="!clusters?.length">
                  <v-row class="ma-0 d-flex-row">
                    <v-col class="pa-0 ma-auto fit-content fit-content-height d-flex-col">
                      <v-row class="ma-0 mx-auto fit-content-height mt-4">
                        <v-img src="@/assets/illustrations/no_cluster.svg" height="56px" width="72px"/>
                      </v-row>
                      <v-row class="ma-0 token-text-medium mt-4 mx-auto fit-content-height">
                        No clusters available
                      </v-row>
                      <v-row class="ma-0 token-text-regular token-text-color-secondary mb-4 mx-auto fit-content-height">
                        Create a cluster to start deployment
                      </v-row>
                      <v-row class="ma-0 mx-auto fit-content-height">
                        <CustomButton icon="add" text="Create cluster" @click="createCluster"/>
                      </v-row>
                    </v-col>
                  </v-row>
                </template>
                <template v-else>
                    <v-row 
                      v-for="cluster of clusters" :key="'clustertrow-'+cluster.id"
                      class="deployment-plan-elem ma-0 mt-4 mx-4" 
                    >
                      <ClusterDisplay 
                        :cluster="cluster"
                        :clustersStatus="clustersStatus[cluster.id]"
                        @clickMenu="onBurgerMenuItemClick"
                        @clickDisplay="onClusterClick"
                        :selected="tempClusterSelected?.id === cluster.id"
                        :simpleBurger="true"
                      />
                    </v-row>
                    <v-row class="ma-0 new-cluster-footer">
                      <Divider/>
                      <CustomButton icon="add" class="full-width mt-4" text="New cluster" @click="createCluster" :level="$enums.ButtonLevels.Secondary"/>
                    </v-row>                
                </template>
              </div>
            </template>
            <!-- Manage subscription  -->
            <template v-else-if="subscriptionState === $enums.SubscriptionState.FRESH">
              <SubscriptionStartTrialModal/>
            </template>
            <template v-else-if="subscriptionState === $enums.SubscriptionState.TRIAL_EXPIRED">
              <SubscriptionTrialOverModal/>
            </template>
            <template v-else-if="subscriptionState === $enums.SubscriptionState.EXPIRED">
              <SubscriptionExpiredModal/>
            </template>
            <template v-else>
              <!-- Rolling back -->
              <v-row v-if="isRollingBack && !planExpanded" class="ma-2 deployement-rollback-message">
                <v-col class="pa-0 fit-content ml-3 mt-4">
                  <Icon 
                    icon="question-circle"
                  />
                </v-col>
                <v-col class="pa-3">
                  <div class="token-text-medium mb-1">
                    Deploy failed. Canceling deployment.
                  </div>
                  <div class="token-text-regular">
                    We are canceling this deployment because one or more services failed to deploy. Your last working version will remain online.
                  </div>
                </v-col>
              </v-row>
              <!-- Log window -->
              <template v-if="planExpanded">
                <v-col class="pr-0 log-window" >
                  <template v-if="isLoadingLog">
                    <Icon 
                      icon="spinner"
                    />
                  </template>
                  <template v-else>
                    <v-row class="ma-0 mb-2" v-for="log of getPlanLogs(planExpanded.id)" :key="log.id">
                      <v-col class="pa-0 token-text-font-iAWriterDuoV token-text-regular token-text-color-secondary mr-2 fit-content">
                        {{ getTimeToDisplay(log.attributes.time) }}
                      </v-col>
                      <v-col class="pa-0 token-text-font-iAWriterDuoV token-text-regular token-text-color-primary">
                        {{ log.attributes.message }}
                      </v-col>
                    </v-row>
                    <template v-if="getRollingBackPlanFromPlan(planExpanded)">
                      <v-row class="ma-0 mb-2" v-for="log of getPlanLogs((getRollingBackPlanFromPlan(planExpanded) as APIDeploymentPlan).id)" :key="log.id">
                        <v-col class="pa-0 token-text-font-iAWriterDuoV token-text-regular token-text-color-secondary mr-2 fit-content">
                          {{ getTimeToDisplay(log.attributes.time) }}
                        </v-col>
                        <v-col class="pa-0 token-text-font-iAWriterDuoV token-text-regular token-text-color-primary">
                          {{ log.attributes.message }}
                        </v-col>
                      </v-row>
                    </template>
                    <Icon 
                      v-if="planExpanded.attributes.status !== $enums.DeploymentRunState.SUCCEEDED"
                      icon="spinner"
                    />
                    <div class="scroll-anchor"></div>
                    <!-- Init scroll empty bars -->
                    <v-row class="ma-0 init-scroll" :style="{height:'calc(100vh - ' + (129 + (getPlanLogs(planExpanded.id).length * 32)) + 'px)'}">
                    </v-row>
                  </template>
                </v-col>
              </template>
              <!-- Plan status window -->
              <template v-else >
                <div class="deployment-plan-list-elem">

                
                  <div 
                    v-for="deploymentPlan of plans" :key="'row-'+deploymentPlan.id"
                    class="deployment-plan-elem mt-2" 
                  >
                    <!-- With rollback plan -->
                    <template v-if="getRollingBackPlanFromPlan(deploymentPlan)">
                      <ListElement 
                        :logo="getLogoFromDeploymentPlan(deploymentPlan)" 
                        class="ml-4"
                        @click="toggleExpandPlan(deploymentPlan)"
                      >
                        <span class="token-text-medium">
                          {{ textFromDeploymentPlan(deploymentPlan) }}
                        </span>
                        <template #subtext v-if="getRollingBackPlanFromPlan(deploymentPlan)?.attributes?.last_log_entry?.message">
                          <span class="token-text-font-iAWriterDuoV token-text-micro token-text-color-secondary mr-2">
                            {{ getTimeToDisplay(getRollingBackPlanFromPlan(deploymentPlan)?.attributes?.last_log_entry?.time) }}
                          </span>
                          <span class="token-text-font-iAWriterDuoV token-text-micro token-text-color-primary">
                            {{ getRollingBackPlanFromPlan(deploymentPlan)?.attributes?.last_log_entry?.message }}
                          </span>
                        </template>
                        <template #badge>
                          <v-row class="ma-0">
                            <template v-if="deploymentPlan">
                              <Badge 
                                :severity="deploymentPlanBadgeSeverity(deploymentPlan)"
                                :label="deploymentPlanBadgeLabel(deploymentPlan)"
                                :outlined="true"
                                :icon="deploymentPlanIcon(deploymentPlan)"
                              />
                              <Icon icon="arrow-right" class="mx-2" />
                              <Badge  
                                :severity="deploymentPlanBadgeSeverity(getRollingBackPlanFromPlan(deploymentPlan) as APIDeploymentPlan)"
                                label="Cancel"
                                :outlined="true"
                                :icon="deploymentPlanIcon(getRollingBackPlanFromPlan(deploymentPlan) as APIDeploymentPlan)"
                              />
                            </template>
                            <Icon 
                              icon="caret-right"
                              class="ml-2 caret"
                            />
                          </v-row>
                        </template>
                      </ListElement>
                    </template>
                    <!-- Without rollback plan -->
                    <template v-else>
                      <ListElement 
                        :logo="getLogoFromDeploymentPlan(deploymentPlan)" 
                        class="ml-4"
                        @click="toggleExpandPlan(deploymentPlan)"
                      >
                        <span class="token-text-medium">
                          {{ textFromDeploymentPlan(deploymentPlan) }}
                        </span>
                        <template #subtext v-if="deploymentPlan.attributes?.last_log_entry?.message">
                          <span class="token-text-font-iAWriterDuoV token-text-micro token-text-color-secondary mr-2">
                            {{ getTimeToDisplay(deploymentPlan.attributes?.last_log_entry?.time) }}
                          </span>
                          <span class="token-text-font-iAWriterDuoV token-text-micro token-text-color-primary">
                            {{ deploymentPlan.attributes?.last_log_entry?.message }}
                          </span>
                        </template>
                        <template #badge>
                          <v-row class="ma-0">
                            <template v-if="deploymentPlan">
                              <Badge 
                                :severity="deploymentPlanBadgeSeverity(deploymentPlan)"
                                :label="deploymentPlanBadgeLabel(deploymentPlan)"
                                :outlined="true"
                                :icon="deploymentPlanIcon(deploymentPlan)"
                              />
                            </template>
                            <Icon 
                              icon="caret-right"
                              class="ml-2 caret"
                            />
                          </v-row>
                        </template>
                      </ListElement>
                    </template>
                  </div>
                </div>
                <!-- First deploy flow -->
                <template v-if="isFirstDeployment && !isSelectClusterView">
                  <v-row class="ma-4 pa-4 bar first-deployment-alert ">
                    <v-col class="pa-0">
                      <v-row class="ma-0 token-text-medium">
                        Free trial credits
                      </v-row>
                      <v-row class="ma-0 my-2 token-text-regular">
                        You have 5€ of free credits to try all of Fransys features without limits.
                        Once you are ready, you can buy more credits and/or enable auto provisioning.
                      </v-row>
                      <v-row class="ma-0">
                        <v-col class="pa-0">
                          <CustomButton text="Buy prepaid credits" :level="$enums.ButtonLevels.Secondary" class="full-width" @click="onClickPurchaseCredits"/>
                        </v-col>
                        <v-col class="pa-0 pl-2">
                          <CustomButton text="Enable auto provisioning" :level="$enums.ButtonLevels.Primary" class="full-width" @click="onClickEnableAutoProvisioning"/>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </template>
              </template>

            </template>      
          </template>    
        </template>
      </v-col>
    </template>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import CustomButton from '@/components/UIElements/CustomButton.vue'
import DeployButton from '@/components/Deployment/DeployButton.vue'
import Icon from '@/components/UIElements/Icon.vue'
import Logo from '@/components/UIElements/Logo.vue'
import Spinner from '@/components/UIElements/Spinner.vue'
import ListElement from '@/components/UIElements/ListElement.vue';
import Badge from '@/components/UIElements/Badge.vue';
import HeightDeployer from '@/components/UIElements/HeightDeployer.vue';
import Checkbox from '@/components/UIElements/Checkbox.vue';
import SubscriptionStartTrialModal from '@/components/Subscription/SubscriptionStartTrialModal.vue';
import SubscriptionTrialOverModal from '@/components/Subscription/SubscriptionTrialOverModal.vue';
import SubscriptionExpiredModal from '@/components/Subscription/SubscriptionExpiredModal.vue';
import ModalPurchaseCredit from '@/components/Modals/ModalPurchaseCredit.vue';
import ModalValidatePaymentMethodList from '@/components/Modals/ModalValidatePaymentMethodList.vue';
import ModalEditLimits from '@/components/Modals/ModalEditLimits.vue';
import ClusterDisplay from '@/components/UIElements/ClusterDisplay.vue';
import Divider from '@/components/UIElements/Divider.vue';
import ModalClusterConfig from '@/components/Modals/ModalClusterConfig.vue';


import { APIBlock, APIDeploymentPlan, APIDeploymentVersion, APIDeploymentLog, APIDeploymentSubject, APICustomer, APIDeployment, APIProject, APICluster, APIBaseAttributes } from '@/typesAPI'
import API from '@/api/wrapper'
import { Watch } from 'vue-property-decorator';
import { ListItem } from '@/components/UIElements/List.vue';


@Options({
  components: {
    CustomButton,
    DeployButton,
    Icon,
    Logo,
    Spinner,
    ListElement,
    SubscriptionStartTrialModal,
    SubscriptionTrialOverModal,
    SubscriptionExpiredModal,
    Badge,
    HeightDeployer,
    Checkbox,
    ModalPurchaseCredit,
    ModalValidatePaymentMethodList,
    ModalEditLimits,
    ClusterDisplay,
    Divider,
    ModalClusterConfig
  },
})
export default class ValidationErrorsModal extends Vue {
  
  isLoading = false
  isLoadingLog = false
  hideClusterSubtext = false
  showPurchaseCreditModal = false
  showValidatePaymentMethodList = false
  showEditLimits = false
  myClusters:APICluster[] = []
  creatingCluster = false

  planExpanded:APIDeploymentPlan | null = null
  planSelectedToDeploy:{[key:string]:boolean} = {}
  versionsToDeploy:APIDeploymentVersion[] = []
  draftViewText = ""
  tempClusterSelected:APICluster | null = null

  get isFirstDeployment () {
    return this.onboardingState?.isFirstDeployment
  }

  get onboardingState() {
    return this.$store.getters['user/getOnboardingState']
  }

  get nbChanges() {
    return this.deploymentPlansDraft?.length ? this.deploymentPlansDraft?.length : 0
  }

  get hideNbChanges() {
    return this.planExpanded || this.nbChanges === 0 || this.deploying || this.isSelectClusterView
  }

  get hideCluster() {
    return this.planExpanded || this.isSelectClusterView || this.isDraftView
  }

  get selectedCluster():APICluster | null {
    return this.$store.getters['modals/getClusterSelected']
  }

  get currentClusterId():string | null {
    if(this.currentProject && this.currentProject?.relationships?.kubernetes_clusters?.data?.length) {
      return this.currentProject?.relationships?.kubernetes_clusters?.data[0].id
    }
    return null
  }

  get currentCluster():APICluster | null {
    const ret = this.clusters?.length ? this.clusters.find(cluster => cluster.id === this.currentClusterId) : null
    return ret ? ret : null
  }

  // Key: clusterId
  // Value: status
  get clustersStatus():{[key:string]:string} {
    const ret:{[key:string]:string} = {}

    if(this.clusters) {
      this.clusters.forEach((cluster) => {
        const myDeployment = this.clusterDeployments?.find((deployment) => {
          return deployment.relationships.subject.data.id === cluster?.id
        })
        ret[cluster.id] = myDeployment ? myDeployment.attributes.status : this.$enums.DeploymentStatus.OFFLINE
      })
    }

    return ret
  }

  createCluster() {
    this.creatingCluster = true
    this.$store.dispatch('modals/setClusterSelected', null)
    this.$store.dispatch('modals/setVisibility', {modal:this.$enums.ModalsName.Cluster, newState: true})
  }

  onBurgerMenuItemClick(item:ListItem, cluster:APICluster) {
    this.$store.dispatch('modals/setClusterSelected', cluster)

    item.selected = false

    switch(item.id) {
      case 'edit':
        this.creatingCluster = false
        this.$store.dispatch('modals/setVisibility', {modal:this.$enums.ModalsName.Cluster, newState: true})
      break;
    }
  }

  onClusterClick(cluster:APICluster) {
    this.tempClusterSelected = cluster
  }

  @Watch('currentProject', {immediate: true, deep:true})
  onCurrentProjectChange() {
    if(this.currentProject) {
      this.getMyClusters()
    }
  }

  @Watch('deploymentPlansDraft', {immediate: true})
  onDraftVersionChange(newVal:APIDeploymentPlan[], oldVal:APIDeploymentPlan[]) {
    if(this.deploymentPlansDraft) {
      if(oldVal && newVal) {
        newVal.forEach((val) => {
          if(!oldVal.find((val2) => val2.id === val.id)) {
            this.planSelectedToDeploy[val.id] = true
          }
        })
      }
      this.checkDraftViewText()
      this.calculateVersionsToDeploy()
    }
  }
  
  @Watch('blockDeployments', {immediate: true})
  onBlockDeploymentsChange() {
    if(this.blockDeployments) {
      this.getVersionsByProject()
    }
  }

  @Watch('clusterDeployments', {immediate: true})
  onClusterDeploymentsChange() {
    if(this.clusterDeployments && this.myClusters.length) {
      this.clusterDeployments?.forEach((deployment) => {
        this.myClusters.forEach((cluster) => {
          if(deployment.relationships?.subject?.data?.id === cluster.id) {
              this.getVersionsByDeployment(deployment)
          }
        })
      })
    }
  }

  @Watch('project', {immediate: true})
  onProjectChange() {
    if(this.project) {
      this.getMyClusters()
      this.getVersionsByProject()
    }
  }

  @Watch('clusters', {immediate: true})
  onClustersChange() {
    if(this.clusters) {
      this.getMyClusters()
    }
  }

  @Watch('deploymentPlansDraft', {immediate: true})
  onDeploymentPlansDraftChange() {
    if(this.deploymentPlansDraft) {
      this.calculateVersionsToDeploy()
    }
  }

  getMyClusters() {
    const ret:APICluster[] = []

    this.project?.relationships?.kubernetes_clusters?.data?.forEach((data) => {
      const cluster = this.$store.getters['clusters/getClusterById'](data.id)
      if(cluster) {
        ret.push(cluster)
      }
    })
    this.myClusters = ret
    this.onClusterDeploymentsChange()
  }
  
  getVersionsByDeployment(deployment:APIDeployment) {
    API.deployment.getVersionsByDeployment(deployment.id)
    .then((result) => {
      if(result.deploymentVersions) {
        this.$store.dispatch('deployment/addDeploymentVersions', result.deploymentVersions)
        result.deploymentVersions.forEach((deploymentVersion:APIDeploymentVersion) => {
          if (deploymentVersion.attributes.status ===  this.$enums.DeploymentState.Running) {
            this.$store.dispatch('deployment/setModalIsOpen', true)
          }
          this.$store.dispatch('deployment/addDeploymentPlans', result.deploymentPlans)
          result.deploymentPlans.forEach((deploymentPlan:APIDeploymentPlan) => {
            this.planSelectedToDeploy[deploymentPlan.id] = true
          })

          if(result?.subjects?.length) {
            result.subjects.forEach((subject) => {
              this.$store.dispatch('deployment/addDeploymentSubject', subject)
            })
          }
        })
      }
      this.checkDraftViewText()
      this.calculateVersionsToDeploy()
    })
  }
  getVersionsByProject() {
    if(this.project) {
      API.deployment.getVersionsByProject(this.project?.id)
      .then((result) => {
        if(result.deploymentVersions) {
          this.$store.dispatch('deployment/addDeploymentVersions', result.deploymentVersions)
          result.deploymentVersions.forEach((deploymentVersion:APIDeploymentVersion) => {
            if (deploymentVersion.attributes.status ===  this.$enums.DeploymentState.Running) {
              this.$store.dispatch('deployment/setModalIsOpen', true)
            }
            this.$store.dispatch('deployment/addDeploymentPlans', result.deploymentPlans)
            result.deploymentPlans.forEach((deploymentPlan:APIDeploymentPlan) => {
              this.planSelectedToDeploy[deploymentPlan.id] = true
            })

            if(result?.subjects?.length) {
              result.subjects.forEach((subject) => {
                this.$store.dispatch('deployment/addDeploymentSubject', subject)
              })
            }
          })
        }
        this.checkDraftViewText()
        this.calculateVersionsToDeploy()
      })
    }
  }

  onClickPurchaseCredits() {
    this.showPurchaseCreditModal = true
  }

  onClickEnableAutoProvisioning() {
    this.showValidatePaymentMethodList = true
  }

  onSuccessValidatePaymentMethodList() {
    API.billing.editCustomer(this.customer?.id, {has_auto_provisioning_enabled : true})
    .finally(() => {
      this.showValidatePaymentMethodList = false
      this.showEditLimits = true
    })
  }

  getTimeToDisplay(time?:string):string | null {
    return time ? this.$helpers.dates.formatTime(time) : null
  }

  toggleReduce () {
    this.$store.dispatch('deployment/setModalIsReduced', !this.isReduced)
    this.$store.dispatch('deployment/setModalIsDraftView', false)
    this.$store.dispatch('deployment/setForceDeployCompletedState', false)
    this.$store.dispatch('deployment/setModalIsSelectClusterView', false)

    // this.$store.dispatch('deployment/setModalIsOpen', !this.open)
    // if(!this.isDeployFinished) {
    //   if(!this.isReduced && this.plans.length === 0) {
    //     this.$store.dispatch('deployment/setDeployingState', this.$enums.DeploymentState.NotStarted)
    //     this.$store.dispatch('deployment/setModalIsOpen', false)
    //   } else {
    //     this.$store.dispatch('deployment/setModalIsReduced', !this.isReduced)
    //   }
    // }
  }

  closeExpandPlan() {
    this.planExpanded = null
  }

  toggleExpandPlan (deploymentPlan:APIDeploymentPlan) {
    this.planExpanded = deploymentPlan
    this.loadPlanLogs(deploymentPlan.id)
  }

  loadPlanLogs(deploymentPlanId:string) {
    this.isLoadingLog = true
    API.deployment.getLogs(deploymentPlanId)
    .then((logs:APIDeploymentLog[]) => {
      this.$store.dispatch('deployment/setDeploymentLogs', {deploymentLogs: logs, planId: deploymentPlanId})
      this.isLoadingLog = false
      const plan = this.plans.find((p) => p.id === deploymentPlanId)
      const rollbackPlan = this.getRollingBackPlanFromPlan(plan)
      if(rollbackPlan) {
        this.isLoadingLog = true
        API.deployment.getLogs(rollbackPlan.id)
        .then((rollbackLogs:APIDeploymentLog[]) => {
          this.$store.dispatch('deployment/setDeploymentLogs', {deploymentLogs: rollbackLogs, planId: rollbackPlan.id})
          this.isLoadingLog = false
          this.scrollToBottomOfLogWindow()
        })
      }
      this.scrollToBottomOfLogWindow()
    })
  }

  scrollToBottomOfLogWindow() {
    setTimeout(() => {
      const htmlElem = document.getElementsByClassName("log-window")[0]
      htmlElem.scrollTo({
        top: htmlElem.scrollHeight,
        left: 0,
        behavior: "smooth",
      })
    })
  }

  getPlanLogs(deploymentPlanId:string):APIDeploymentLog[] {
    return this.$store.getters['deployment/getDeploymentLogsByPlanId'](deploymentPlanId)
  }


  getRollingBackPlanFromPlan(deploymentPlan?:APIDeploymentPlan):APIDeploymentPlan | null {
    if(!this.isRollingBack || !deploymentPlan) {
      return null
    }
    const ret = this.plansRollback.find((plan:APIDeploymentPlan) => plan.relationships.subject.data.id === deploymentPlan.relationships.subject.data.id && plan.relationships.version.data.id === deploymentPlan.relationships.version.data.id)
    return ret && ret.attributes.operation !== this.$enums.DeploymentPlanOperations.NOOP ? ret : null
  }

  deploymentPlanIcon(deploymentPlan:APIDeploymentPlan):string {
    const ret:{[key:string]: string} = {}

    ret[this.$enums.DeploymentRunState.PENDING] = 'clock'
    ret[this.$enums.DeploymentRunState.STARTING] = 'spinner'
    ret[this.$enums.DeploymentRunState.RUNNING] = 'spinner'
    ret[this.$enums.DeploymentRunState.WAITING_FOR_CALLBACK] = 'spinner'
    ret[this.$enums.DeploymentRunState.SUCCEEDING] = 'spinner'
    ret[this.$enums.DeploymentRunState.SUCCEEDED] = 'check-circle'
    ret[this.$enums.DeploymentRunState.RETRIED] = 'cancel-circle'
    ret[this.$enums.DeploymentRunState.FAILED] = 'cancel-circle'
    ret[this.$enums.DeploymentRunState.ROLLED_BACK] = 'cancel-circle'
    ret[this.$enums.DeploymentRunState.ABANDONED] = 'block'

    return ret[deploymentPlan?.attributes?.status]
  }

  deploymentPlanBadgeSeverity(deploymentPlan:APIDeploymentPlan):string {
    if(deploymentPlan.attributes.operation === this.$enums.DeploymentPlanOperations.NOOP) {
      return this.$enums.Severity.NEUTRAL
    }

    const ret:{[key:string]: string} = {}

    ret[this.$enums.DeploymentRunState.PENDING] = this.$enums.Severity.NEUTRAL
    ret[this.$enums.DeploymentRunState.STARTING] = this.$enums.Severity.NEUTRAL
    ret[this.$enums.DeploymentRunState.RUNNING] = this.$enums.Severity.NEUTRAL
    ret[this.$enums.DeploymentRunState.WAITING_FOR_CALLBACK] = this.$enums.Severity.NEUTRAL
    ret[this.$enums.DeploymentRunState.SUCCEEDING] = this.$enums.Severity.NEUTRAL
    ret[this.$enums.DeploymentRunState.SUCCEEDED] = this.$enums.Severity.SUCCESS
    ret[this.$enums.DeploymentRunState.RETRIED] = this.$enums.Severity.NEUTRAL
    ret[this.$enums.DeploymentRunState.FAILED] = this.$enums.Severity.DANGER
    ret[this.$enums.DeploymentRunState.ROLLED_BACK] = this.$enums.Severity.DANGER
    ret[this.$enums.DeploymentRunState.ABANDONED] = this.$enums.Severity.NEUTRAL

    return ret[deploymentPlan?.attributes?.status]
  }

  deploymentPlanBadgeLabel(deploymentPlan:APIDeploymentPlan):string {
    const labels:{[key:string]: string} = {}
  
    labels[this.$enums.DeploymentPlanOperations.NOOP] = 'No Changes'
    labels[this.$enums.DeploymentPlanOperations.CREATION] = 'New'
    labels[this.$enums.DeploymentPlanOperations.UPDATE] = 'Update'
    labels[this.$enums.DeploymentPlanOperations.DESTRUCTION] = 'Delete'

    return labels[deploymentPlan.attributes.operation]
  }


  textFromDeploymentPlan(deploymentPlan:APIDeploymentPlan) {
    switch(deploymentPlan?.relationships?.subject?.data.type) {
      case 'kubernetesClusters' :
        return 'Kubernetes cluster'
      case 'blocks' :
        return this.getAPIBlockNameFromID(deploymentPlan?.relationships?.subject?.data.id)
      default: 
        return ''
    }
  }

  getAPIBlockNameFromID(id:string): string {

    const apiBlock:APIBlock =  this.listSubjects.find((subject:APIDeploymentSubject) => {
      return subject.id === id
    }) as APIBlock
    if(!apiBlock?.attributes) {
      return ""
    }
    return apiBlock?.attributes?.name ? apiBlock.attributes.name : apiBlock?.attributes?.default_name
  }

  getLogoFromDeploymentPlan(deploymentPlan:APIDeploymentPlan): string {
    if(deploymentPlan?.relationships?.subject?.data.type === 'blocks') {
      const apiBlock:APIBlock = this.listSubjects.find((subject:APIDeploymentSubject) => {
        return subject.id === deploymentPlan.relationships.subject.data.id
      }) as APIBlock
      return apiBlock && this.$enums.EnginesLogos[apiBlock.attributes.default_name] ? this.$enums.EnginesLogos[apiBlock.attributes.default_name] : ''
    } else if (deploymentPlan?.relationships?.subject?.data.type === 'kubernetesClusters') {
      return this.$enums.EnginesLogos['kubernetes']
    }

    return ''
  }

  updateDeploymentPlanIncludeInVersion(deploymentPlan:APIDeploymentPlan, newState:boolean) {
    this.checkDraftViewText()
    this.calculateVersionsToDeploy()
  }

  openSelectClusterView() {
    this.$store.dispatch('deployment/setModalIsDraftView', false)
    this.$store.dispatch('deployment/setModalIsOpen', true)
    this.$store.dispatch('deployment/setForceDeployCompletedState', false)
    this.$store.dispatch('deployment/setModalIsSelectClusterView', true)

    this.$store.dispatch('errorsValidation/closeWarningPopup')
    this.$store.dispatch('errorsValidation/closeModal')

    if(this.currentCluster) {
      this.tempClusterSelected = this.currentCluster
    }

  }

  openDraftView() {
    this.$store.dispatch('deployment/setModalIsDraftView', true)
    this.$store.dispatch('deployment/setModalIsOpen', true)
    this.$store.dispatch('deployment/setForceDeployCompletedState', false)
    this.$store.dispatch('deployment/setModalIsSelectClusterView', false)

    this.$store.dispatch('errorsValidation/closeWarningPopup')
    this.$store.dispatch('errorsValidation/closeModal')
  }

  saveClusterSelect() {
    if(this.tempClusterSelected) {
      API.projects.edit(this.currentProject.id, {cluster_id: this.tempClusterSelected.id})
      .then((project:APIProject) => {
        this.$store.dispatch('projects/updateProject', project)
        this.$store.dispatch('deployment/setModalIsOpen', false)
        this.$store.dispatch('deployment/setModalIsSelectClusterView', false)
      })
    }
  }

  get currentProject ():APIProject {
    return this.$store.getters["projects/getCurrentProject"]
  }

  getDeploymentPlansByVersionId(versionId:string) {
    return this.$store.getters['deployment/getDeploymentPlansByVersionId'](versionId)
  }

  isKubernetesCluster(deploymentPlan:APIDeploymentPlan) {
    return deploymentPlan.relationships.subject.data.type === "kubernetesClusters"
  }

  get deploymentPlansDraft():APIDeploymentPlan[] {
    const ret:APIDeploymentPlan[] = []
    if(this.draftVersions.length) {
      this.draftVersions.forEach((draftVersion) => {
        const plans:APIDeploymentPlan[] = this.getDeploymentPlansByVersionId(draftVersion.id).filter((p:APIDeploymentPlan) => {
          if(p.relationships.subject.data.type === 'kubernetesClusters') {
            if(p.relationships.subject.data.id !== this.currentClusterId) {
              return false
            }
          }
          return true
        })
        ret.push(...plans)
      })
    }
    ret.sort((a, b) => {
      if(a.relationships.subject.data.type === "kubernetesClusters") {
        return -1
      }
      return 0
    })
    return ret
  }

  get draftVersions():APIDeploymentVersion[] {
    return this.$store.getters['deployment/getDraftVersions']
  }


  toggleClusterSubtext() {
    // this.hideClusterSubtext = !this.hideClusterSubtext
  }

  get subscriptionState ():string {
    return this.$store.getters['user/getSubscriptionState']
  }

  get customer ():APICustomer {
    return this.$store.getters['user/getCustomer']
  }

  get open ():boolean {
    return this.$store.getters['deployment/getModalIsOpen']
  }

  get isReduced ():boolean {
    return this.$store.getters['deployment/getModalIsReduced']
  }

  get plans ():APIDeploymentPlan[] {
    return this.$store.getters['deployment/getRunningOrCompletedDeploymentPlans']
  }
  get plansRollback ():APIDeploymentPlan[] {
    return this.$store.getters['deployment/getDeploymentPlansRollback']
  }

  get isRollingBack ():boolean {
    return !!this.plansRollback.length
  }

  get listSubjects ():APIDeploymentSubject[] {
    return this.$store.getters['deployment/getListSubjects']
  }

  get deployingState (): string {
    return this.$store.getters['deployment/getDeployingState']
  }

  get deploying (): boolean {
    return this.deployingState === this.$enums.DeploymentState.Running
  }

  get isDeployFinished (): boolean {
    return this.deployingState === this.$enums.DeploymentState.Completed
  }

  get titleText (): string {
    return this.isDeployFinished ? 'Deployment complete' : 'Deployment in progress'
  }

  get clusterSubtext (): string {
    return 'Deploying a cluster for the first time can take up to 10 minutes.<br/> You can close this panel.'
  }

  get isDraftView (): boolean {
    return this.$store.getters['deployment/getModalIsDraftView']
  }

  get isSelectClusterView (): boolean {
    return this.$store.getters['deployment/getModalIsSelectClusterView']
  }

  get blockDeployments (): APIDeployment[] {
    return this.$store.getters['blocksAPI/getDeployements']
  }

  get clusterDeployments (): APIDeployment[] {
    return this.$store.getters['clusters/getDeployments']
  }

  get project (): APIProject | null {
    return this.$store.getters['projects/getCurrentProject']
  }

  get clusters (): APICluster[] | null {
    return this.$store.getters['clusters/getClusters']
  }

  calculateVersionsToDeploy() {
    const ret:APIDeploymentVersion[] = []
    this.deploymentPlansDraft.forEach((plan:APIDeploymentPlan) => {
      if(this.planSelectedToDeploy[plan.id]) {
        const version = this.draftVersions.find((v) => v.id === plan.relationships.version.data.id)
        if(version) {
          ret.push(version)
        }
      }
    })
    this.versionsToDeploy = ret
  }



  get getShouldDisablePublish (): boolean {
    let ret = true

    if(this.draftVersions.length) {
      this.draftVersions.forEach((draftVersion) => {
        const plans = this.getDeploymentPlansByVersionId(draftVersion.id)
        plans?.forEach((plan:APIDeploymentPlan) => {
          if(this.planSelectedToDeploy[plan.id] === true) {
            ret = false
          }
        })
      }) 
    }
    
    return ret
  }

  checkDraftViewText () {
    let ret = "Deploy all changes"

    if(this.deploying) {
      ret = "Deployment in progress"
    } else if(this.draftVersions.length) {
      this.draftVersions.forEach((draftVersion) => {
        const plans = this.getDeploymentPlansByVersionId(draftVersion.id)
        plans?.forEach((plan:APIDeploymentPlan) => {
          if(!this.planSelectedToDeploy[plan.id]) {
            ret = "Deploy selection"
          }
        })
      })
    }
    
    this.draftViewText = ret
  }
  

  @Watch('isDeployFinished')
  onisDeployFinishedChange(val:boolean) {
    if(val && this.isDraftView) {
      setTimeout(() => {
        this.$store.dispatch('deployment/setForceDeployCompletedState', false)
      },3000)
    }
  }


  
}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

.deployment-modal {
  display: flex;
  height: 56px;
  transition: width 0.2s ease-in-out, height 0.2s ease-in-out, max-width 0.2s ease-in-out, min-width 0.2s ease-in-out;
  overflow-y: auto;
  background: white !important;
  overflow: hidden;
  width: fit-content;
  // max-width: 378px;
  min-width: 0px;

  .cluster-name {
    max-width: 100px;
  }

  .first-deployment-alert {
    max-width: calc(100% - 16px);
    background-color: $color-neutral-grey-8 !important;
    position: absolute;
    bottom: 0;
  }

  .select-cluster-view {
    overflow: auto !important;
    height: 100%;
    max-height: calc(100% - 126px);
  }

  .new-cluster-footer {
    width: 100%;
    max-width: calc(100%);
    padding:16px;
    padding-top:0px;
    position: absolute;
    bottom: 0px;
    background: white;
    z-index: 10;
    :deep(.divider) {
      margin-left: -16px;
      margin-right: -16px;
      width: 100vw;
    }
  }

  .nb-changes {
    color: $color-neutral-black !important;
  }

  .list-elem-draft {
    cursor: default !important;
  }
  .log-window {
    overflow-y: auto;
    max-height: calc(100vh - 89px);

    * {
      overflow-anchor: none;
    }
    .scroll-anchor {
      overflow-anchor: auto !important;
      height: 1px;
    }
  }

  &:not(.reduced){
    &:not(.open) {
      border : 0 !important;
    }
  }
  &.open {
    z-index: 100002 !important;
    height: calc(100vh - 32px);
    min-width: 524px;
    max-width: 650px;
    &.reduced {
      height: 56px;
      min-width: 0px;
    }
  }
  
  .deployment-modal-header {
    height: 56px;
    border-bottom: solid 1px $color-neutral-grey-12;
  }

  .deployement-rollback-message {
    background: $color-neutral-grey-8 !important;
    border-radius: 8px;
    border: 1px solid $color-neutral-grey-12 !important;
  }

  .deployment-plan-list-elem {
    height: calc(100vh - 96px);
    overflow-y: auto;
  }

  .deployment-plan-elem {
    transition: border 0.2s ease-in-out;

    .caret {
      transition: transform 0.2s ease-in-out;
      :deep(.icon) {
        background-color: $color-neutral-grey-30 !important;
      }
    }
    &.elem-expanded {
      border-bottom: 1px solid $color-neutral-grey-12;
      .caret {
        transform: rotate(-180deg);
      }
    }
  }
  .info-run {
    &.grey {
      color: $color-neutral-black !important;
      :deep(.icon) {
        background-color: $color-neutral-black !important;
      }
    }
    &.green {
      color: $text-success !important;
      :deep(.icon) {
        background-color: $text-success !important;
      }
    }
    &.red {
      color: $text-error !important;
      :deep(.icon) {
        background-color: $text-error !important;
      }
    }
    .timing {
      color: $color-neutral-grey-60 !important;
    }
  }

  
 
}
</style>