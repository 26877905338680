<template>
  <v-app>
    <v-main>
      <SocketsManager />
      <ToastrsManager />
      <KC/>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import SocketsManager from '@/components/Managers/SocketsManager.vue';
import ToastrsManager from '@/components/Managers/ToastrsManager.vue';
import KC from '@/components/KC/KC.vue';

@Options({
  components: {
    SocketsManager,
    ToastrsManager,
    KC
  },
})
export default class App extends Vue {
}

</script>
<style lang="scss">
@import "main";
</style>