import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/illustrations/cluster_offline.svg'
import _imports_1 from '@/assets/illustrations/cluster_shutdown_animated.gif'
import _imports_2 from '@/assets/illustrations/cluster_deploying_animated.gif'
import _imports_3 from '@/assets/illustrations/empty_metrics.svg'


const _hoisted_1 = { class: "clusterMonitoringView full-view" }
const _hoisted_2 = { class: "token-text-h4 token-text-color-primary mr-4" }
const _hoisted_3 = { class: "ml-0 mr-4 my-auto" }
const _hoisted_4 = { class: "ml-0 mr-4 my-auto" }
const _hoisted_5 = { class: "ml-0 mr-0 my-auto" }
const _hoisted_6 = { class: "ml-0 mr-0 my-auto" }
const _hoisted_7 = {
  key: 0,
  class: "ml-auto"
}
const _hoisted_8 = { class: "ml-auto" }
const _hoisted_9 = { class: "ml-n3" }
const _hoisted_10 = { class: "my-auto" }
const _hoisted_11 = { class: "my-auto" }
const _hoisted_12 = { class: "my-auto" }
const _hoisted_13 = { class: "my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalClusterConfig = _resolveComponent("ModalClusterConfig")!
  const _component_ModalClusterPutOffline = _resolveComponent("ModalClusterPutOffline")!
  const _component_ModalClusterDelete = _resolveComponent("ModalClusterDelete")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_Flag = _resolveComponent("Flag")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_SegmentedControl = _resolveComponent("SegmentedControl")!
  const _component_DropdownMenu = _resolveComponent("DropdownMenu")!
  const _component_CustomTextInput = _resolveComponent("CustomTextInput")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_WidgetMonitoringWrapper = _resolveComponent("WidgetMonitoringWrapper")!
  const _component_GridItem = _resolveComponent("GridItem")!
  const _component_GridLayout = _resolveComponent("GridLayout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ModalClusterConfig, {
      cluster: _ctx.cluster,
      creating: false
    }, null, 8, ["cluster"]),
    _createVNode(_component_ModalClusterPutOffline, {
      cluster: _ctx.cluster,
      visible: _ctx.showModalPutOffline,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showModalPutOffline) = $event)),
      projects: _ctx.cluster ? _ctx.projects[_ctx.cluster.id] : []
    }, null, 8, ["cluster", "visible", "projects"]),
    _createVNode(_component_ModalClusterDelete, {
      cluster: _ctx.cluster,
      visible: _ctx.showModalDelete,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showModalDelete) = $event))
    }, null, 8, ["cluster", "visible"]),
    _createVNode(_component_v_col, { class: "pa-0 pa-8 d-flex-col" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "ma-0 mb-6 no-flex-grow" }, {
          default: _withCtx(() => [
            _createVNode(_component_CustomButton, {
              level: _ctx.$enums.ButtonLevels.Secondary,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.go(-1))),
              icon: "arrow-left"
            }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode(" Back ")
              ])),
              _: 1
            }, 8, ["level"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mb-6 no-flex-grow" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.cluster?.attributes.name), 1),
            _createVNode(_component_Badge, {
              class: "my-auto",
              label: _ctx.$helpers.states.translateDeploymentStatusToLabel(_ctx.clusterStatus),
              severity: _ctx.$helpers.states.translateDeploymentStatusToSeverity(_ctx.clusterStatus),
              icon: _ctx.clusterStatus === _ctx.$enums.DeploymentStatus.DEPLOYING || _ctx.clusterStatus === _ctx.$enums.DeploymentStatus.SHUTTING_DOWN ? 'spinner' : '',
              iconColor: "rgba(0, 0, 58, 1)"
            }, null, 8, ["label", "severity", "icon"]),
            _createVNode(_component_Divider, {
              vertical: true,
              class: "mx-4"
            }),
            _createVNode(_component_Logo, {
              logo: _ctx.cluster?.attributes?.provider,
              class: "mr-2 ml-0"
            }, null, 8, ["logo"]),
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$helpers.providers.providerToLabel(_ctx.cluster?.attributes?.provider)), 1),
            (_ctx.currentLocation)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_Flag, {
                    code: _ctx.currentLocation.attributes.country,
                    class: "ml-0 mr-2"
                  }, null, 8, ["code"]),
                  _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.locationDisplayName), 1)
                ], 64))
              : _createCommentVNode("", true),
            _createVNode(_component_Icon, {
              class: "ml-0 mr-2",
              icon: "chip",
              color: "rgba(0, 0, 58, 0.6)"
            }),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.cluster?.attributes.requested_config.node_number_min), 1),
            _createVNode(_component_Icon, {
              class: "mx-1",
              icon: "arrow-right",
              color: "rgba(0, 0, 58, 0.6)"
            }),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.cluster?.attributes.requested_config.node_number_max) + "  nodes ", 1),
            _createVNode(_component_CustomButton, {
              level: _ctx.$enums.ButtonLevels.Tertiary,
              icon: "trash",
              class: "ml-auto mr-4 token-text-color-orange",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showModalDelete = true)),
              disabled: _ctx.clusterStatus !== _ctx.$enums.DeploymentStatus.OFFLINE
            }, {
              default: _withCtx(() => _cache[13] || (_cache[13] = [
                _createTextVNode(" Delete ")
              ])),
              _: 1
            }, 8, ["level", "disabled"]),
            _createVNode(_component_CustomButton, {
              level: _ctx.$enums.ButtonLevels.Tertiary,
              icon: "cloud-off",
              class: "ml-0 mr-4",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showModalPutOffline = true))
            }, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createTextVNode(" Undeploy ")
              ])),
              _: 1
            }, 8, ["level"]),
            _createVNode(_component_CustomButton, {
              level: _ctx.$enums.ButtonLevels.Tertiary,
              icon: "params",
              class: "ml-0",
              onClick: _ctx.editCluster
            }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createTextVNode(" Edit cluster ")
              ])),
              _: 1
            }, 8, ["level", "onClick"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mb-6 no-flex-grow" }, {
          default: _withCtx(() => [
            _createVNode(_component_SegmentedControl, {
              modelValue: _ctx.selectedTab,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedTab) = $event)),
              tabs: _ctx.listTabs,
              class: "tab-control fit-content"
            }, null, 8, ["modelValue", "tabs"]),
            (_ctx.selectedTab === 'history')
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_v_row, { class: "no-flex-grow my-auto" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_CustomButton, {
                          ref: "menuButton",
                          level: _ctx.$enums.ButtonLevels.Tertiary,
                          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.dropdownOpened = true)),
                          class: "px-1"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.userSelectedPeriod.text), 1),
                            _createVNode(_component_Icon, {
                              class: "ml-1 mr-n4",
                              color: "#3219B2",
                              icon: "caret-down"
                            })
                          ]),
                          _: 1
                        }, 8, ["level"]),
                        _createVNode(_component_DropdownMenu, {
                          items: _ctx.itemsDropdown,
                          show: _ctx.dropdownOpened,
                          "onUpdate:show": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.dropdownOpened) = $event)),
                          modelValue: _ctx.userSelectedPeriod,
                          maxContent: true,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.onUserSelecPeriod($event))),
                          target: _ctx.$refs.menuButton
                        }, null, 8, ["items", "show", "modelValue", "target"])
                      ])
                    ]),
                    _: 1
                  }),
                  (_ctx.userSelectedPeriod.id === 'custom')
                    ? (_openBlock(), _createBlock(_component_v_row, {
                        key: 0,
                        class: "ma-2 mb-4"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_CustomTextInput, {
                            modelValue: _ctx.startPicker,
                            "onUpdate:modelValue": [
                              _cache[9] || (_cache[9] = ($event: any) => ((_ctx.startPicker) = $event)),
                              _ctx.onDatePickerChange
                            ],
                            type: "datetime-local",
                            style: {"width":"200px"}
                          }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                          _createVNode(_component_Icon, {
                            icon: "arrow-right",
                            class: "mx-2"
                          }),
                          _createVNode(_component_CustomTextInput, {
                            modelValue: _ctx.endPicker,
                            "onUpdate:modelValue": [
                              _cache[10] || (_cache[10] = ($event: any) => ((_ctx.endPicker) = $event)),
                              _ctx.onDatePickerChange
                            ],
                            type: "datetime-local",
                            style: {"width":"200px"}
                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, {
          class: _normalizeClass(["ma-0", {'edit-layout-mode' : _ctx.editLayoutMode}])
        }, {
          default: _withCtx(() => [
            (_ctx.clusterStatus === _ctx.$enums.DeploymentStatus.OFFLINE )
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 0,
                  class: "pa-0 empty-state d-flex-col"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_v_row, { class: "ma-0" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_img, {
                            src: _imports_0,
                            height: "67px",
                            width: "86px",
                            class: "mx-auto mb-4"
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_row, { class: "ma-0" }, {
                        default: _withCtx(() => _cache[16] || (_cache[16] = [
                          _createElementVNode("div", { class: "mx-auto token-text-h5 token-text-color-primary" }, " Cluster offline ", -1)
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_row, { class: "ma-0" }, {
                        default: _withCtx(() => _cache[17] || (_cache[17] = [
                          _createElementVNode("div", { class: "token-text-regular token-text-color-secondary mx-auto" }, " Deploy cluster to start collecting metrics ", -1)
                        ])),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }))
              : (_ctx.clusterStatus === _ctx.$enums.DeploymentStatus.SHUTTING_DOWN)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 1,
                    class: "pa-0 empty-state d-flex-col"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_v_row, { class: "ma-0" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_img, {
                              src: _imports_1,
                              height: "67px",
                              width: "86px",
                              class: "mx-auto mb-4"
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_row, { class: "ma-0" }, {
                          default: _withCtx(() => _cache[18] || (_cache[18] = [
                            _createElementVNode("div", { class: "mx-auto token-text-h5 token-text-color-primary" }, " Cluster is shutting down ", -1)
                          ])),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  }))
                : (_ctx.clusterStatus === _ctx.$enums.DeploymentStatus.DEPLOYING)
                  ? (_openBlock(), _createBlock(_component_v_col, {
                      key: 2,
                      class: "pa-0 empty-state d-flex-col"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_12, [
                          _createVNode(_component_v_row, { class: "ma-0" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_img, {
                                src: _imports_2,
                                height: "67px",
                                width: "86px",
                                class: "mx-auto mb-4"
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_row, { class: "ma-0" }, {
                            default: _withCtx(() => _cache[19] || (_cache[19] = [
                              _createElementVNode("div", { class: "mx-auto token-text-h5 token-text-color-primary" }, " Cluster deployment in progress ", -1)
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_row, { class: "ma-0" }, {
                            default: _withCtx(() => _cache[20] || (_cache[20] = [
                              _createElementVNode("div", { class: "token-text-regular token-text-color-secondary mx-auto" }, " The data will be displayed once the cluster is online ", -1)
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 1
                    }))
                  : (_ctx.selectedTab === 'history' && this.nbProjectNotOffline === 0)
                    ? (_openBlock(), _createBlock(_component_v_col, {
                        key: 3,
                        class: "pa-0 empty-state d-flex-col"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_13, [
                            _createVNode(_component_v_row, { class: "ma-0" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_img, {
                                  src: _imports_3,
                                  height: "80px",
                                  width: "107px",
                                  class: "mx-auto mb-4"
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_row, { class: "ma-0" }, {
                              default: _withCtx(() => _cache[21] || (_cache[21] = [
                                _createElementVNode("div", { class: "mx-auto token-text-h5 token-text-color-primary" }, " No online projects in the cluster ", -1)
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_row, { class: "ma-0" }, {
                              default: _withCtx(() => _cache[22] || (_cache[22] = [
                                _createElementVNode("div", { class: "token-text-regular token-text-color-secondary mx-auto" }, " Those metrics are project specific, no point in showing you empty graphs, right ? ", -1)
                              ])),
                              _: 1
                            })
                          ])
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_GridLayout, {
                        key: 4,
                        class: "grid-layout",
                        layout: _ctx.layout,
                        "onUpdate:layout": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.layout) = $event)),
                        colNum: 24,
                        rowHeight: 8,
                        margin: [32,32],
                        isDraggable: _ctx.editLayoutMode,
                        isResizable: false,
                        verticalCompact: true,
                        useCssTransforms: true
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layout, (item, index) => {
                            return (_openBlock(), _createBlock(_component_GridItem, {
                              key: index,
                              static: item.static,
                              x: item.x,
                              y: item.y,
                              w: item.w,
                              h: item.h,
                              i: item.i
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_WidgetMonitoringWrapper, {
                                  widgetAPI: _ctx.widgetsAPI[index],
                                  class: _normalizeClass(["widget-wrapper", {'edit-mode' : _ctx.editLayoutMode}]),
                                  customStart: _ctx.start,
                                  customEnd: _ctx.end,
                                  period: _ctx.userSelectedPeriod
                                }, null, 8, ["widgetAPI", "class", "customStart", "customEnd", "period"])
                              ]),
                              _: 2
                            }, 1032, ["static", "x", "y", "w", "h", "i"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["layout", "isDraggable"]))
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _: 1
    })
  ]))
}