import enums from '@/enums'
import { APICluster, APIClusterLocation, APIClusterRequirement, APIClusterNodeTypologie, APIDeployment, APILoadBalancerTypologie, APIProject } from '@/typesAPI'
import helpers from '@/utils/helpers'
import store from '..'
import API from '@/api/wrapper'


const pullingState:{[key:string]:{[key:string]:any}} = {clusterNodeTypology: {}, loadBalancerTypology: {}, location: {}, clusterRequirement: {}}

type State = {
  clusterList: APICluster[] | null,
  clusterNodeTypologies:{[key:string]:{[key:string]:APIClusterNodeTypologie[]}}
  loadBalancerTypologies:{[key:string]:{[key:string]:APILoadBalancerTypologie[]}}
  deployments:APIDeployment[]
  locations:{[key:string]:APIClusterLocation[]}
  clusterRequirements: {[key:string]:{[key:string]:APIClusterRequirement[]}} // clusterRequirements[provider][locationId]
}

const state: State = {
  clusterList: null,
  clusterNodeTypologies: {},
  loadBalancerTypologies: {},
  clusterRequirements: {},
  locations: {},
  deployments: []
}

const getters = {
  getClusters: (state:State) => {
    if(state.clusterList === null && store.getters['user/getCustomer']?.id) {
      API.clusters.getClustersByCustomer(store.getters['user/getCustomer']?.id)
      .then((res) => {
        state.clusterList = res.clusters
        state.deployments = res.deployments
      })
      return []
    }
    return state.clusterList
  },
  getClusterRequirementsByProviderAndLocation: (state:State) => {
    return (provider:string, locationId:string) => {
      if(!state.clusterRequirements[provider] || !state.clusterRequirements[provider][locationId]) {
        if(pullingState.clusterRequirement[provider] && pullingState.clusterRequirement[provider][locationId] === true) {
          return []
        }
        if(!pullingState.clusterRequirement[provider]) {
          pullingState.clusterRequirement[provider] = {}
        }
        pullingState.clusterRequirement[provider][locationId] = true
        API.clusters.getClusterRequirementsByProviderAndLocationId(provider, locationId)
        .then((res) => {
          if(!state.clusterRequirements[provider]) {
            state.clusterRequirements[provider] = {}
          }
          state.clusterRequirements[provider][locationId] = res
        })
        .finally(() => {
          pullingState.clusterRequirement[provider][locationId] = false
        })
        return []
      }
      return state.clusterRequirements[provider][locationId]
    }
  },
  getClusterById: (state:State) => {
    return (id:string) => {
      const clusterList = store.getters['clusters/getClusters']
      return clusterList ? clusterList.find((obj:APICluster) => obj.id === id) : null
    }
  },
  getClusterNodeTypologiesByProviderAndLocation: (state:State) => {
    return (provider:string, location:string) => {
      if(!state.clusterNodeTypologies[provider] || !state.clusterNodeTypologies[provider][location]) {
        if(pullingState.clusterNodeTypology[provider] === true || !location) {
          return []
        }
        pullingState.clusterNodeTypology[provider] = true
        API.clusters.getClusterNodeTypologiesByProviderAndLocation(provider, location)
        .then((res) => {
          if(!state.clusterNodeTypologies[provider]) {
            state.clusterNodeTypologies[provider] = {}
          }
          state.clusterNodeTypologies[provider][location] = res
        })
        .finally(() => {
          pullingState.clusterNodeTypology[provider] = false
        })
        return []
      }
      return state.clusterNodeTypologies[provider][location]
    }
  },
  getLoadBalancerTypologiesByProviderAndLocation: (state:State) => {
    return (provider:string, location:string) => {
      if(!state.loadBalancerTypologies[provider] || !state.loadBalancerTypologies[provider][location]) {
        if(pullingState.loadBalancerTypology[provider] === true || !location) {
          return []
        }
        pullingState.loadBalancerTypology[provider] = true
        API.clusters.getLoadBalancerTypologiesByProviderAndLocation(provider, location)
        .then((res) => {
          if(!state.loadBalancerTypologies[provider]) {
            state.loadBalancerTypologies[provider] = {}
          }
          state.loadBalancerTypologies[provider][location] = res
        })
        .finally(() => {
          pullingState.loadBalancerTypology[provider] = false
        })
        return []
      }
      return state.loadBalancerTypologies[provider][location]
    }
  },
  getLocationsByProvider: (state:State) => {
    return (provider:string) => {
      if(!state.locations[provider]) {
        if(pullingState.location[provider] === true) {
          return []
        }
        pullingState.location[provider] = true
        API.clusters.getLocationsByProvider(provider)
        .then((res) => {
          state.locations[provider] = res
        })
        .finally(() => {
          pullingState.location[provider] = false
        })
        return []
      }
      return state.locations[provider]
    }
  },
  getDeployments: (state:State) => {
    return state.deployments
  },
  getCurrentClusterId: (state:State) => {
    const currentProject:APIProject = store.getters['projects/getCurrentProject']
    if(currentProject && currentProject?.relationships?.kubernetes_clusters?.data?.length) {
      return currentProject?.relationships?.kubernetes_clusters?.data[0].id
    }
    return null
  },
}

const mutations = {
  SET_CLUSTERS: (state:State, clusters:APICluster[]) => {
    state.clusterList = clusters
  },
  EDIT_CLUSTER: (state:State, cluster:APICluster) => {
    if(state.clusterList) {
      helpers.ressources.addMultipleRessourceToArray(state.clusterList, [cluster])
    } else {
      state.clusterList = [cluster]
    }
  },
  DELETE_CLUSTER: (state:State, cluster:APICluster) => {
    if(state.clusterList) {
      state.clusterList = state.clusterList.filter((c) => c.id !== cluster.id)
    }
  },
  EDIT_DEPLOYMENT: (state:State, deployment:APIDeployment) => {
    helpers.ressources.addMultipleRessourceToArray(state.deployments, [deployment])
  },
  DELETE_DEPLOYMENT: (state:State, deployment:APIDeployment) => {
    state.deployments = state.deployments.filter((d) => d.id !== deployment.id)
  },
  REFRESH_LOCATIONS: (state:State, provider:string) => {
    if(pullingState.location[provider] !== true) {
      pullingState.location[provider] = true
      API.clusters.getLocationsByProvider(provider)
      .then((res) => {
        state.locations[provider] = res
      })
      .finally(() => {
        pullingState.location[provider] = false
      })
    }
  },
}

const actions = {
  setClusters: (context:any,clusters:APICluster[]) => {
    context.commit('SET_CLUSTERS', clusters)
  },
  editCluster: (context:any,cluster:APICluster) => {
    context.commit('EDIT_CLUSTER', cluster)
  },
  refreshLocations: (context:any,provider:string) => {
    context.commit('REFRESH_LOCATIONS', provider)
  },
  deleteCluster: (context:any,cluster:APICluster) => {
    context.commit('DELETE_CLUSTER', cluster)
  },
  editDeployment: (context:any,deployment:APIDeployment) => {
    context.commit('EDIT_DEPLOYMENT', deployment)
  },
  deleteDeployment: (context:any,deployment:APIDeployment) => {
    context.commit('DELETE_DEPLOYMENT', deployment)
  },
}
export default {
  state,
  getters,
  mutations,
  actions
}